import { attr, Model } from 'redux-orm';
import PropTypes from 'prop-types';
class User extends Model {
  static modelName = 'User';

  static fields = {
    name: attr(),
    email: attr(),
    location: attr(),
    department: attr(),
    properties: attr(),
    pictureUrl_x3: attr()
  };

  static parse(data) {
    return this.upsert({
      id: data._id,
      name: data.name,
      email: data.email,
      location: data.location,
      department: data.department,
      properties: data.properties,
      pictureUrl_x3: data.picture_x3
    });
  }
}

export const UserProps = PropTypes.shape({
  name: PropTypes.string.isRequired,
  email: PropTypes.string,
  location: PropTypes.string,
  department: PropTypes.string,
  properties: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      property_type: PropTypes.string,
      text: PropTypes.string
    })
  ),
  pictureUrl_x3: PropTypes.string
});

export default User;
