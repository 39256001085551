const { REACT_APP_API_ENDPOINT } = process.env;

const endpointUrl = (path, extra) => {
  return `${REACT_APP_API_ENDPOINT || ''}${path}${extra ? `?${extra}` : ''}`;
};

export const endpointUrlWithPagination = (path, page) => {
  const paging = page ? `?page=${page}` : '';
  return `${endpointUrl(path)}${paging}`;
};

export const endpointUrlWithPaginationAndParams = (path, params, page) => {
  const paging = page ? `&page=${page}` : '';
  const query_params = `?${params.length === 0 ? params[0] : params.join('&')}`;
  return `${endpointUrl(path)}${query_params}${paging}`;
};

export default endpointUrl;
