import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Box } from '@rebass/grid';

const Title = styled.div`
  line-height: 1.2;
  font-size: ${({ size }) => (size ? size : '20px')};
  font-family: 'Barlow-SemiBold';
  text-decoration: none;
  color: #4f4f4f;
`;

const Subtitle = styled.span`
  font-size: 13px;
  color: ${(props) => props.theme.colors.dark};
`;

const PostTitle = ({ title, subtitle, ...props }) => (
  <Box>
    <Title {...props}>{title}</Title>
    <Subtitle>{subtitle}</Subtitle>
  </Box>
);

PostTitle.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string
};

export default PostTitle;
