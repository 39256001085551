import React from 'react';
import formatDistance from 'date-fns/formatDistance';
import styled from 'styled-components';

const SinceStyle = styled.span`
  font-size: 13px;
  color: #7a7a7a;
`;

export const since = (date) => {
  const distance = formatDistance(date, new Date());
  return `${distance} ago`;
};

const Since = ({ date }) => {
  return <SinceStyle>{since(date)}</SinceStyle>;
};

export default Since;
