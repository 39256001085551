import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { Title } from 'components/Title';

const Styling = styled.div`
  font-size: 20px;
  font-family: 'Barlow-Bold';
  color: #a2a2a2;
`;

const PageTop = ({ title }) => (
  <Styling>
    <Title>{title}</Title>
  </Styling>
);

PageTop.propTypes = {
  title: PropTypes.string.isRequired
};

export default PageTop;
