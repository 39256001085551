import {
  START_LOADING,
  END_LOADING_OK,
  END_LOADING_ERROR,
  CLEAR_LOADING
} from './constants';

export const startLoading = (name) => ({
  type: START_LOADING,
  name
});

export const stopLoadingFailure = (name, payload) => ({
  type: END_LOADING_ERROR,
  name,
  payload
});

export const stopLoadingSuccess = (name) => ({
  type: END_LOADING_OK,
  name
});

export const clearLoading = (name) => ({
  type: CLEAR_LOADING,
  name
});
