import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

const BoxWrapper = styled.article`
  padding: 0 0 30px 0;
  margin: 0 0 15px 0;
  background-color: ${(props) => props.theme.colors.light};
  transition:
    transform 0.2s,
    box-shadow 0.2s;
  min-height: ${({ minheight }) => (minheight ? minheight : '80px')};
  border-bottom: 1px solid ${(props) => props.theme.colors.dim_blue};

  ${(props) =>
    props.$active &&
    css`
      // border-bottom: 4px solid ${(props) => props.theme.colors.dim_blue};

      &::before {
        opacity: 1;
      }
    `};
`;

BoxWrapper.propTypes = {
  active: PropTypes.bool
};

export default BoxWrapper;
