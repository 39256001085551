import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Box, Flex } from '@rebass/grid';
import formatDistance from 'date-fns/formatDistance';
import styled from 'styled-components';
import { fetchPost } from './actions';
import { postByIdSelector } from '../../selectors';
import { loadingSelector } from 'modules/loading/selectors';
import FilesList from 'components/FilesList';
import Content from 'components/Content';
import SocialBlock from 'components/SocialBlock';
import Img from 'components/Img';
import { FeedProps } from 'models/Feed';
import { PostProps } from 'models/Post';
import ReactRouterPropTypes from 'react-router-prop-types';
import { Player, BigPlayButton } from 'video-react';
import 'video-react/dist/video-react.css';
import CopyLinkButton from 'components/CopyLinkButton';
import { logViewPost } from 'utils/piwikpro';

const SubPartTitle = styled.h2`
  margin: 15px 0 5px 15px;
  font-size: 14px;
  color: #a1a1a1;
`;
const BoxWrapper = styled.article`
  padding: 30px 0;
  background-color: ${(props) => props.theme.colors.light};
  transition:
    transform 0.2s,
    box-shadow 0.2s;
`;
const TitleContainer = styled.div`
  text-decoration: none;
  max-width: 800px;
  box-sizing: border-box;
  color: ${(props) => props.theme.colors.black};
  font-family: 'Barlow-SemiBold';
  font-size: ${(props) => props.theme.fonts.size.news_post_title};
  line-height: ${(props) => props.theme.fonts.line_height.news_post_title};
`;
const DateSpan = styled.span`
  color: ${(props) => props.theme.colors.gray_1};
  font-family: 'Barlow-Regular';
  font-size: ${(props) => props.theme.fonts.size.news_post_date};
`;
const SubtitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0 30px;
  box-sizing: border-box;
`;
const Tooltip = styled.span`
  position: absolute;
`;
const CopyButtonContainer = styled.div`
  box-sizing: border-box;
`;

const PostScreen = ({ feed, postId, fetchPost, post }) => {
  const [state, setState] = useState({
    showPost: false,
    files: [],
    since: ''
  });

  useEffect(() => {
    if (!post) setState({ showPost: false });
    else {
      setState({
        showPost: true,
        files: post && post.files.toModelArray(),
        since: formatDistance(post.created_at, new Date())
      });
    }
  }, [post]);

  useEffect(() => {
    if (postId) {
      fetchPost(feed.id, postId).then(() => {
        if (post) logViewPost(post.title, feed.name);
      });
    }
  }, [postId]);

  return (
    <>
      {state.showPost && (
        <>
          <BoxWrapper>
            <TitleContainer>
              <h1>{post.title}</h1>
            </TitleContainer>
            <SubtitleContainer mb="30px">
              <Box>
                <DateSpan>{`${state.since} ago`}</DateSpan>
              </Box>
              <CopyButtonContainer style={{ gridArea: 'copy' }}>
                <CopyLinkButton
                  copyUrl={`${feed.path}/${post.id}`}
                  postId={post.id}
                  postTitle={post.title}
                />
                <Tooltip
                  style={{ display: 'none' }}
                  id={`tooltip-copy-${post.id}`}
                >
                  Copied
                </Tooltip>
              </CopyButtonContainer>
            </SubtitleContainer>

            {post.content.video ? (
              <Player src={post.content.video}>
                <BigPlayButton position="center" />
              </Player>
            ) : (
              <Img
                src={post.content.image_tablet_x3}
                style={{
                  width: '100%',
                  height: '600px'
                }}
              />
            )}
            <Box mt="20px" mb="30px">
              <Content content={post.content.body} isPostPage />
            </Box>
            <Flex>
              {post.showCommentsAndLikes && (
                <SocialBlock feed={feed} post={post} />
              )}
            </Flex>
          </BoxWrapper>
          {state.files && state.files.length > 0 && (
            <React.Fragment>
              <SubPartTitle>Files</SubPartTitle>
              <BoxWrapper minHeight="15px">
                <FilesList files={state.files} />
              </BoxWrapper>
            </React.Fragment>
          )}
        </>
      )}
    </>
  );
};

PostScreen.propTypes = {
  post: PostProps,
  match: ReactRouterPropTypes.match,
  feed: FeedProps,
  postId: PropTypes.number,
  isMobile: PropTypes.bool,
  fetchPost: PropTypes.func.isRequired
};

const mapStateToProps = (state, ownProps) => ({
  post: postByIdSelector(state)(ownProps.postId),
  loading: loadingSelector('POST')(state)
});

const mapDispatchToProps = (dispatch) => ({
  fetchPost: (feedId, postId) => dispatch(fetchPost(feedId, postId))
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withRouter, withConnect)(PostScreen);
