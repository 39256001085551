import React, { Component } from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { Box, Flex } from '@rebass/grid';

import Content from 'components/Content';
import CreatorTop from './CreatorTop';
import ListItem from 'components/ListItem';
import ColumnWrapper from 'components/Wrappers/ColumnWrapper';
import SocialBlock from 'components/SocialBlock';

import { PostProps } from 'models/Post';
import { FeedProps } from 'models/Feed';
import ReactRouterPropTypes from 'react-router-prop-types';

class CommunityListItem extends Component {
  render() {
    const { post, feed, match } = this.props;
    const postUrl = `${match.url}/${post.id}`;
    return (
      <ListItem url={postUrl} key={post.id} noPadding noGrid>
        <ColumnWrapper>
          <CreatorTop post={post} />
          <Box p="15px" css={{ background: '#FAFAFA' }}>
            <Link to={postUrl}>
              <Content content={post.content.body} />
              <Box style={{ textAlign: 'center' }}>
                {post.content.image_tablet_x3 && (
                  <img
                    src={post.content.image_tablet_x3}
                    alt="user uploaded"
                    style={{ maxWidth: '100%' }}
                  />
                )}
              </Box>
            </Link>
            <Box pt="15px">
              <Flex>
                <SocialBlock
                  feed={feed}
                  post={post}
                  commentsUrl={`${postUrl}`}
                />
              </Flex>
            </Box>
          </Box>
        </ColumnWrapper>
      </ListItem>
    );
  }
}

CommunityListItem.propTypes = {
  post: PostProps.isRequired,
  feed: FeedProps.isRequired,
  match: ReactRouterPropTypes.match.isRequired
};

export default compose(withRouter)(CommunityListItem);
