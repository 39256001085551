import styled from 'styled-components';
import React from 'react';
import { phoneMax } from 'utils/media';

const EmptyPage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-top: ${({ topAjustment }) => topAjustment && topAjustment};
  ${({ isProfileScreen }) =>
    isProfileScreen &&
    `
    padding: 30px 0px 0px 47px;
  `}
  ${phoneMax`
    padding-top: 50%;
  `};
`;
const Image = styled.img`
  background: transparent;
  width: 100%;
  height: auto;
  object-fit: cover;
`;
const ImageContainer = styled.div`
  width: ${({ width }) => width};
`;
const Title = styled.h1`
  font-family: 'Barlow-SemiBold';
  color: ${(props) => props.theme.colors.black};
  font-size: ${(props) => props.theme.fonts.size.empty_page_title};
  line-height: ${(props) => props.theme.fonts.line_height.empty_page_title};
  margin-bottom: 50px;
  text-align: center;
`;
const SubtitleContainer = styled.div`
  margin-bottom: 90px;
`;
const Subtitle = styled.h3`
  font-family: 'Barlow-Regular';
  color: ${(props) => props.theme.colors.black};
  font-size: ${(props) => props.theme.fonts.size.empty_page_subtitle};
  line-height: ${(props) => props.theme.fonts.line_height.empty_page_subtitle};
  text-align: center;
`;

export const PostEmptyPage = (props) => (
  <EmptyPage {...props}>
    <svg
      height="200"
      viewBox="0 0 200 200"
      width="200"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd" stroke="#e2e2e2" strokeWidth="4">
        <g strokeLinecap="round" strokeLinejoin="round">
          <path d="m22 78h44" transform="translate(42 34)" />
          <path d="m22 94h72" transform="translate(42 34)" />
          <path d="m22 110h72" transform="translate(42 34)" />
        </g>
        <path
          d="m6.33600001 2c-2.39470668 0-4.33600001 1.94129333-4.33600001 4.33600001v119.32799999c0 2.394707 1.94129333 4.336 4.33600001 4.336h103.32799999c2.394707 0 4.336-1.941293 4.336-4.336v-119.32799999c0-2.39470668-1.941293-4.33600001-4.336-4.33600001z"
          transform="translate(42 34)"
        />
        <path
          d="m26.336 22c-2.3947067 0-4.336 1.9412933-4.336 4.336v29.328c0 2.3947067 1.9412933 4.336 4.336 4.336h63.328c2.3947067 0 4.336-1.9412933 4.336-4.336v-29.328c0-2.3947067-1.9412933-4.336-4.336-4.336z"
          transform="translate(42 34)"
        />
      </g>
    </svg>
  </EmptyPage>
);

export const FeedEmptyPage = (props) => (
  <EmptyPage {...props}>
    <svg
      height="200"
      viewBox="0 0 200 200"
      width="200"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        fill="none"
        fillRule="evenodd"
        stroke="#e2e2e2"
        strokeWidth="4"
        transform="translate(31 40)"
      >
        <path d="m20.9257067 118h92.0798023c12.150264 0 22-9.849736 22-22v-72c0-12.1502645-9.849736-22-22-22h-63.005509c-12.1502645 0-22 9.8497355-22 22v78.029606c0 3.957473-2.372824 9.223342-7.0742933 15.970394z" />
        <path d="m28 32h-4c-12.1502645 0-22 9.8497355-22 22v51c0 7.179702 5.82029825 13 13 13 7.1797017 0 13-5.820298 13-13z" />
        <rect height="26" rx="6" width="56" x="53.5" y="26" />
        <g strokeLinecap="round" strokeLinejoin="round">
          <path d="m57.5 90h48" />
          <path d="m57.5 74h48" />
        </g>
      </g>
    </svg>
  </EmptyPage>
);

export const ProfileEmptyPage = (props) => (
  <EmptyPage isProfileScreen {...props}>
    <Title>Find colleagues from all over the world</Title>
    <SubtitleContainer>
      <Subtitle>
        Look through the ImCORE members in alphabetic order on the left, and
        select a colleague to see their profile information -
      </Subtitle>
      <Subtitle>
        Or use the search tool to find a colleague by their first or last name.
      </Subtitle>
    </SubtitleContainer>
    <ImageContainer width="216px">
      <Image src={'/earth-globe-icon.svg'} />
    </ImageContainer>
  </EmptyPage>
);

export const CommentEmptyPage = (props) => (
  <EmptyPage {...props}>
    <svg
      height="200"
      viewBox="0 0 200 200"
      width="200"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m77.3200043 47c-21.163554 0-38.3200043 17.1564503-38.3200043 38.3200043v8.8943055c0 18.9642952 15.3735989 34.3378942 34.3378943 34.3378942h2v21.409671c0 .711119.2492954 1.399735.7045171 1.946052 1.0747751 1.289852 2.9916841 1.464207 4.281536.389431l28.4968756-23.745154h13.859173c21.163554 0 38.320004-17.15645 38.320004-38.3200042v-4.9121955c0-21.163554-17.15645-38.3200043-38.320004-38.3200043z"
        fill="none"
        stroke="#e2e2e2"
        strokeWidth="4"
      />
    </svg>
  </EmptyPage>
);
