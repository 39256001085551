import React from 'react';
import styled from 'styled-components';
import { space } from 'styled-system';
import { withRouter } from 'react-router-dom';
import { logShareItem } from 'utils/piwikpro';
// prettier-ignore
const StyledComments = styled.div`
  ${space}
`

const Button = styled.div`
  width: 24px;
  height: 23px;
  border: none;
  outline: none;
  cursor: pointer;
  background: url('/copy-icon.svg') no-repeat;
`;

function fadeOutEffect(fadeTarget) {
  var fadeEffect = setInterval(function () {
    if (!fadeTarget.style.opacity) {
      fadeTarget.style.opacity = 1;
    }
    if (fadeTarget.style.opacity > 0) {
      fadeTarget.style.opacity -= 0.35;
    } else {
      clearInterval(fadeEffect);
      fadeTarget.style.display = 'none';
      fadeTarget.style.opacity = 1;
    }
  }, 200);
}

const CopyLinkButton = ({ copyUrl, postId, postTitle, ...props }) => {
  const handleOnClick = async (e) => {
    e.preventDefault();
    // Send event to Analytics
    logShareItem(postTitle);

    const target = e.target;
    try {
      await navigator.clipboard
        .writeText(window.location.origin.toString().concat(copyUrl))
        .then(() => {
          const tooltipId = `#tooltip-copy-${postId}`;
          const tooltipEl =
            target.parentNode.parentNode.querySelector(tooltipId);

          tooltipEl.style.display = 'block';
          fadeOutEffect(tooltipEl);
        });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <StyledComments {...props}>
      <Button title="Copy link" onClick={(e) => handleOnClick(e)} />
    </StyledComments>
  );
};

CopyLinkButton.propTypes = {
  ...space.propTypes
};

export default withRouter(CopyLinkButton);
