import React from 'react';
import styled from 'styled-components';
import { Flex, Box } from '@rebass/grid';

import background from './background.png';
import imcore_newsfeed from './imcore_newsfeed.png';

import googlestore_1 from './googlestore.png';
import googlestore_2 from './googlestore@2x.png';
import googlestore_3 from './googlestore@3x.png';

import appstore_1 from './appstore.png';
import appstore_2 from './appstore@2x.png';
import appstore_3 from './appstore@3x.png';

const Styling = styled.div`
  display: flex;
  flex: 1 0;
  flex-shrink: 2;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-image: url(${background});
  text-align: center;
`;

const ImgBox = styled.div`
  padding: 0 30%;
  img {
    max-width: 100%;
  }
`;

const Header = styled.h1`
  font-family: 'Barlow-Bold';
  font-size: 25px;
  color: #4f4f4f;
  margin-bottom: 16px;
`;

const SubText = styled.div`
  font-family: 'Barlow-Regular';
  width: 450px;
  line-height: 1.4;
`;

const PromotionSection = () => (
  <Styling>
    <ImgBox>
      <img src={imcore_newsfeed} alt="phone" style={{ objectFit: 'contain' }} />
    </ImgBox>
    <Box mt="34px">
      <Header>Follow directly from the imCORE app</Header>
    </Box>
    <Box mb="20px">
      <SubText>
        Keep up to date by following the latest news, connect with members and
        submit a project idea through the imCORE app
      </SubText>
    </Box>
    <Flex flexDirection="row">
      <a
        href="https://itunes.apple.com/app/imcore-network/id1289611389"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={appstore_1}
          alt="lighthouse"
          srcSet={`
            ${appstore_1} 1x,
            ${appstore_2} 2x,
            ${appstore_3} 3x
          `}
        />
      </a>
      <a
        href="https://play.google.com/store/apps/details?id=dk.roche.imcore"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={googlestore_1}
          alt="lighthouse"
          srcSet={`
            ${googlestore_1} 1x,
            ${googlestore_2} 2x,
            ${googlestore_3} 3x
          `}
        />
      </a>
    </Flex>
  </Styling>
);

export default PromotionSection;
