import { handleActions } from 'redux-actions';
import {
  TOKEN_SIGN_IN_REQUEST,
  TOKEN_SIGN_IN_SUCCESS,
  TOKEN_SIGN_IN_FAILURE,
  TOKEN_REQUEST_INITIATED,
  TOKEN_REQUEST_COMPLETE,
  SIGN_IN_SUCCESS,
  SIGN_IN_FAILURE,
  SIGN_OUT,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  NEW_PASSWORD_REQUEST,
  NEW_PASSWORD_SUCCESS,
  NEW_PASSWORD_FAILURE
} from './constants';

const initialState = {
  token: null
};

const authenticationReducer = handleActions(
  {
    [TOKEN_REQUEST_INITIATED]: (state) => state,
    [TOKEN_REQUEST_COMPLETE]: (state) => state,
    [TOKEN_SIGN_IN_REQUEST]: (state) => state,
    [TOKEN_SIGN_IN_SUCCESS]: (state, action) => ({
      token: action.payload.data.token
    }),
    [TOKEN_SIGN_IN_FAILURE]: (state) => state,
    [SIGN_IN_SUCCESS]: (state, action) => ({
      token: action.payload.data.token
    }),
    [SIGN_IN_FAILURE]: () => initialState,
    [SIGN_OUT]: () => ({
      token: null
    }),
    [RESET_PASSWORD_REQUEST]: (state) => state,
    [RESET_PASSWORD_SUCCESS]: (state) => state,
    [RESET_PASSWORD_FAILURE]: (state) => state,
    [NEW_PASSWORD_REQUEST]: (state) => state,
    [NEW_PASSWORD_SUCCESS]: (state) => state,
    [NEW_PASSWORD_FAILURE]: (state) => state
  },
  initialState
);

export default authenticationReducer;
