import { RSAA } from 'redux-api-middleware';
import {
  FETCH_POST_REQUEST,
  FETCH_POST_SUCCESS,
  FETCH_POST_FAILURE
} from './constants';
import endpointUrl from 'utils/url';
import { getHeadersWithToken } from 'utils/headers';

export const fetchPost = (feedId, postId) => ({
  [RSAA]: {
    endpoint: () => endpointUrl(`/api/v1/feeds/${feedId}/posts/${postId}`),
    method: 'GET',
    types: [
      FETCH_POST_REQUEST,
      {
        type: FETCH_POST_SUCCESS,
        meta: { feedId, postId }
      },
      FETCH_POST_FAILURE
    ],
    headers: getHeadersWithToken()
  }
});
