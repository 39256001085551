import { RSAA } from 'redux-api-middleware';
import {
  FETCH_CONTACT_REQUEST,
  FETCH_CONTACT_SUCCESS,
  FETCH_CONTACT_FAILURE
} from './constants.js';

import endpointUrl from '../../utils/url';
import { getHeadersWithToken } from 'utils/headers.js';

export const fetchSingleContact = (feedId, userId) => ({
  [RSAA]: {
    endpoint: () => endpointUrl(`/api/v1/users/${userId}`),
    method: 'GET',
    types: [
      FETCH_CONTACT_REQUEST,
      {
        type: FETCH_CONTACT_SUCCESS,
        meta: { feedId, userId }
      },
      FETCH_CONTACT_FAILURE
    ],
    headers: getHeadersWithToken()
  }
});
