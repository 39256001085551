import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { feedsSelector } from 'modules/feed/selectors';
import { connect } from 'react-redux';

const StyledContent = styled.div`
  grid-area: content;
  font-size: ${(props) => (props.$isPostPage ? '18px' : '15px')};
  line-height: ${(props) =>
    props.$isPostPage
      ? props.theme.fonts.line_height.news_post_description
      : '1.4'};
  color: ${(props) =>
    props.$isPostPage ? props.theme.colors.black : '#505050'};

  a {
    color: #087cbf;
  }

  p {
    margin: 0.5em 0;
  }

  strong {
    font-family: 'Barlow-SemiBold';
  }

  ol,
  ul {
    padding-left: 40px;
    margin: 1em 0;
  }

  ol {
    list-style: decimal;
  }

  ul {
    list-style: disc;
  }
`;

function matchAll(str, regex) {
  var res = [];
  var m;
  if (regex.global) {
    while ((m = regex.exec(str))) {
      res.push(m);
    }
  } else {
    if ((m = regex.exec(str))) {
      res.push(m);
    }
  }
  return res;
}

/* eslint-disable no-useless-escape */
const processedContent = (content, props) => {
  let c = content;
  // Getting all imcore urls in content
  const urls = matchAll(
    content,
    /<a\s+(?:[^>]*?\s+)?href=["'](imcore:\/\/.*?)['"]>(.*?)<\/a>/g
  );
  urls.forEach((url) => {
    let shouldReplace = false;
    let linkTag = url[0];
    let linkUrl = url[1];
    const userMatch = linkUrl.match(/users\/([(0-9)]+)/);
    let webUrl = '';
    if (userMatch) {
      const id = userMatch[1];
      webUrl = `/member_directory/${id}`;
      shouldReplace = true;
    } else {
      const searchPattern = new RegExp(
        /imcore:\/\/feeds\/(\d*)\/search\/([^"]+)\/?$/
      );
      const singleFeedPatter = new RegExp(/imcore:\/\/feeds\/(\d*)\/?$/);
      const postsPattern = new RegExp(
        /imcore:\/\/feeds\/(\d*)\/posts\/(\d*)\/?$/
      );

      const searchMatch = linkUrl.match(searchPattern);
      const postsMatch = linkUrl.match(postsPattern);
      const singleFeedMatch = linkUrl.match(singleFeedPatter);

      if (searchMatch) {
        const feedId = searchMatch[1];
        const feed = props.feeds.find((feed) => feed.id === Number(feedId));
        if (!feed) webUrl = '/';
        else {
          const term = searchMatch[2];
          webUrl = `${feed.path}?search=${term}`;
        }
        shouldReplace = true;
      } else if (postsMatch) {
        const feedId = postsMatch[1];
        const feed = props.feeds.find((feed) => feed.id === Number(feedId));
        if (!feed) webUrl = '/';
        else {
          const postId = postsMatch[2];
          webUrl = `${feed.path}/${postId}`;
        }
        shouldReplace = true;
      } else if (singleFeedMatch) {
        const feedId = singleFeedMatch[1];
        const feed = props.feeds.find((feed) => feed.id === Number(feedId));
        if (!feed) webUrl = '/';
        else {
          webUrl = `${feed.path}`;
        }
        shouldReplace = true;
      }
    }
    if (shouldReplace) {
      const regex = new RegExp(linkUrl, 'g');
      const newLink = linkTag.replace(regex, webUrl);
      const linkRegex = new RegExp(linkTag, 'g');
      c = c.replace(linkRegex, newLink);
    }
  });

  return c;
};
/* eslint-enable no-useless-escape */
const Content = ({ content, ...props }) => (
  <StyledContent
    $isPostPage={props.isPostPage}
    {...props}
    dangerouslySetInnerHTML={{ __html: processedContent(content, props) }}
  />
);

Content.propTypes = {
  children: PropTypes.node,
  isPostPage: PropTypes.bool
};

const mapStateToProps = createStructuredSelector({
  feeds: feedsSelector
});

const withConnect = connect(mapStateToProps);

export default withConnect(Content);
