import React from 'react';
import Lottie from 'lottie-react';
import checkmark from '../../../assets/checkmark.json';
import { TextField } from '../../../components/Input';
import Button from '../../../components/Button';
import LoadingProps from '../../../modules/loading/props';
import Flash from '../../../components/Flash';
import { createStructuredSelector } from 'reselect';
import { loadingSelector } from '../../../modules/loading/selectors';
import {
  requestToken,
  signInToken
} from '../../../modules/authentication/actions';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import FormBox from '../../../components/FormBox';
import { Box } from '@rebass/grid';

class TokenForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { token: '', isAnimationStopped: true };
    this.onSubmit = this.onSubmit.bind(this);

    // If the token is given, skip rendering and send the authentication request
    const { token } = this.props;
    if (token) {
      this.props.signIn(token);
    }
  }

  componentDidMount() {
    const { email } = this.props;
    if (email) this.props.requestToken(email);
  }

  onSubmit(e) {
    e.preventDefault();

    const { email } = this.props;
    const { token } = this.state;

    if (!email || !token) return;
    this.props.signIn(token);
  }

  resendToken(email) {
    this.props.requestToken(email);
    this.setState({ isAnimationStopped: false });
  }

  render() {
    const {
      email,
      isMobile,
      loading: { rejected, reject_reason, pending, fulfilled }
    } = this.props;
    const { token } = this.state;

    return (
      <React.Fragment>
        <FormBox
          isMobile={isMobile}
          isSmall={false}
          icon={
            <svg width="120px" height="120px" viewBox="0 0 120 120">
              <title>icon_magiclink_confirmation_letter</title>
              <g
                id="icon_magiclink_confirmation_letter"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
              >
                <g
                  id="Group"
                  transform="translate(0.000000, 18.000000)"
                  stroke="#0E7EBE"
                  strokeWidth="2"
                >
                  <path
                    d="M29.7859193,26.899645 L63.7873129,0.926358192 C64.5034437,0.379313838 65.4969762,0.378763453 66.2137127,0.925014044 L100.324097,26.9217632 C100.820456,27.3000566 101.111778,27.8883671 101.111778,28.5124494 L101.111778,29.9824822 C101.111778,30.6218338 100.806106,31.2226528 100.289317,31.5990837 L66.2874939,56.3661594 C65.5865675,56.8767173 64.6364785,56.8774178 63.9348,56.3678941 L29.8248456,31.5989614 C29.3066614,31.2226821 29,30.6210165 29,29.9806252 L29,28.488987 C29,27.8656837 29.2905983,27.2780152 29.7859193,26.899645 Z"
                    id="Rectangle"
                    fill="#FFFFFF"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M40.5,10.5 L89.5,10.5 C90.6045695,10.5 91.5,11.3954305 91.5,12.5 L91.5,70.5 C91.5,71.6045695 90.6045695,72.5 89.5,72.5 L40.5,72.5 C39.3954305,72.5 38.5,71.6045695 38.5,70.5 L38.5,12.5 C38.5,11.3954305 39.3954305,10.5 40.5,10.5 Z"
                    id="Rectangle-Copy-3"
                    fill="#FFFFFF"
                    strokeLinejoin="round"
                    transform="translate(65.000000, 41.500000) rotate(-270.000000) translate(-65.000000, -41.500000) "
                  />
                  <g
                    id="Group-3"
                    transform="translate(53.000000, 27.000000)"
                    strokeLinecap="round"
                  >
                    <path
                      d="M8.98073539,0 L11,0 C13.209139,-2.6262583e-15 15,1.790861 15,4 L15,4 L15,4 C15,6.209139 13.209139,8 11,8 L4,8 C1.790861,8 2.705415e-16,6.209139 0,4 L0,4 L0,4"
                      id="Rectangle"
                      transform="translate(7.500000, 4.000000) scale(-1, 1) translate(-7.500000, -4.000000) "
                    />
                    <path
                      d="M17.9807354,0 L20,0 C22.209139,-2.6262583e-15 24,1.790861 24,4 L24,4 L24,4 C24,6.209139 22.209139,8 20,8 L13,8 C10.790861,8 9,6.209139 9,4 L9,4 L9,4"
                      id="Rectangle"
                      transform="translate(16.500000, 4.000000) scale(1, -1) translate(-16.500000, -4.000000) "
                    />
                  </g>
                  <g
                    id="Group-11"
                    transform="translate(28.111778, 28.000000)"
                    fill="#E5EFFA"
                    strokeLinejoin="round"
                  >
                    <path
                      d="M0.888222268,0 L37,25.6125967 L72.8882223,0 L72.8882223,49 C72.8882223,50.1045695 71.9927918,51 70.8882223,51 L2.88822227,51 C1.78365277,51 0.888222268,50.1045695 0.888222268,49 L0.888222268,0 L0.888222268,0 Z"
                      id="Rectangle-Copy-4"
                    />
                    <path
                      d="M72.8882223,50 L38.160704,22.0250547 C37.4202364,21.4285669 36.3621737,21.4358688 35.6300092,22.0425194 L1.88822227,50 L1.88822227,50"
                      id="Line-4"
                      strokeLinecap="round"
                    />
                  </g>
                  <line
                    x1="24.097782"
                    y1="60"
                    x2="4.91086388"
                    y2="60"
                    id="Line-7"
                    strokeLinecap="round"
                  />
                  <line
                    x1="19.1712253"
                    y1="65"
                    x2="0.809969248"
                    y2="65"
                    id="Line-7-Copy"
                    strokeLinecap="round"
                  />
                </g>
              </g>
            </svg>
          }
          title="Check your email"
          subtitleLine1={'We emailed a magic link to ' + email + '.'}
          subtitleLine2={
            'It might be a minute before it appears in your inbox.'
          }
          subtitleLine3={'Click the link to complete the login.'}
        >
          <form
            onSubmit={this.onSubmit}
            style={{
              width: '300px',
              display: 'flex',
              flexDirection: 'column',
              alignSelf: 'center'
            }}
          >
            {rejected && (reject_reason || {}).status === 401 && (
              <Flash type="error">Invalid email or password.</Flash>
            )}
            <TextField type="hidden" name="email" required value={email} />
            <TextField
              type="text"
              name="token"
              placeholder="Enter code"
              value={token}
              onChange={(e) => this.setState({ token: e.target.value })}
              required
              style={{
                textAlign: 'center',
                borderRadius: '5px',
                border: 'solid 1px #e9e9e9',
                backgroundColor: '#f7f7f7',
                fontFamily: 'Barlow-Regular',
                alignSelf: 'stretch'
              }}
            />
          </form>
          <Box
            mt="26px"
            style={{ width: '30px', display: 'flex', alignSelf: 'center' }}
          >
            <Lottie
              onLoopComplete={this.state.isAnimationStopped}
              animationData={checkmark}
              autoplay={false}
              interactivity={[
                {
                  eventName: 'loopComplete',
                  callback: () => {
                    this.setState({ isAnimationStopped: true });
                  }
                }
              ]}
            />
          </Box>
          <Button
            loading={pending || fulfilled}
            onClick={() => this.resendToken(email)}
            isPrimary={false}
            style={{
              width: '166px',
              alignSelf: 'center'
            }}
          >
            Resend email
          </Button>
        </FormBox>
      </React.Fragment>
    );
  }
}

TokenForm.propTypes = {
  email: PropTypes.string,
  token: PropTypes.string,
  loading: LoadingProps
};

const mapStateToProps = createStructuredSelector({
  loading: loadingSelector('SIGN_IN')
});

const mapDispatchToProps = (dispatch) => ({
  requestToken: (email) => dispatch(requestToken(email)),
  signIn: (token) => dispatch(signInToken(token))
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(TokenForm);
