import React from 'react';
import styled from 'styled-components';
// prettier-ignore
const Crosshair = styled.div`
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #036ab6;
  text-align: left;
  > img {
    vertical-align: middle;
    margin-right: 10px;
    width: 22px;
    height: auto;
  }
  > span {
    vertical-align: middle;
    font-family: 'Barlow-Medium';
    font-size: ${props => props.theme.fonts.size.location};
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #036ab6;
  }
`

const Pin = styled.div`
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #036ab6;
  text-align: left;
  > img {
    vertical-align: middle;
    margin-right: 10px;
    width: 22px;
    height: auto;
    &.profile {
      width: 15px;
    }
  }
  > span {
    vertical-align: middle;
    font-family: 'Barlow-Medium';
    font-size: ${(props) => props.theme.fonts.size.location};
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4f4f4f;
    &.profile {
      color: ${(props) => props.theme.colors.contrast};
    }
  }
`;

const SingleLocation = ({ text, pin, smaller }) => (
  <div>
    {pin ? (
      <Pin>
        <img
          alt=""
          className={smaller ? 'profile' : ''}
          src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIxMHB4IiBoZWlnaHQ9IjEycHgiIHZpZXdCb3g9IjAgMCAxMCAxMiIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4gICAgICAgIDx0aXRsZT5pY29uX2xvY2F0aW9uX3Bpbl9zbWFsbDwvdGl0bGU+ICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPiAgICA8ZyBpZD0iaWNvbl9sb2NhdGlvbl9waW5fc21hbGwiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPiAgICAgICAgPHBhdGggZD0iTTQuNjc3NTg4NzMsMTEuODkyMTcyNyBDMS41NTkxOTYyNCw5LjU1OTY5NzA2IDAsNy4yNDk2NDY4IDAsNC45NjIwMjE5NCBDMCwxLjQxNDI5NjExIDIuNjMxNTc4OTUsMCA1LDAgQzcuMzY4NDIxMDUsMCAxMCwxLjM5MDMyNDk5IDEwLDQuOTYyMDIxOTQgQzEwLDcuMjY0OTA2MDkgOC40NDEwNzEzNSw5LjU3NTI2NDA4IDUuMzIzMjE0MDQsMTEuODkzMDk1OSBMNS4zMjMyMzIzLDExLjg5MzEyMDUgQzUuMTMxNTAwOTksMTIuMDM1NjU0NiA0Ljg2ODkwMDc1LDEyLjAzNTI2OTEgNC42Nzc1ODg3MywxMS44OTIxNzI3IFogTTUsNi44OTE2OTcxNCBDNi4wNzM4ODcwMSw2Ljg5MTY5NzE0IDYuOTQ0NDQ0NDQsNi4wMjc3NTIxMyA2Ljk0NDQ0NDQ0LDQuOTYyMDIxOTQgQzYuOTQ0NDQ0NDQsMy44OTYyOTE3NiA2LjA3Mzg4NzAxLDMuMDMyMzQ2NzQgNSwzLjAzMjM0Njc0IEMzLjkyNjExMjk5LDMuMDMyMzQ2NzQgMy4wNTU1NTU1NiwzLjg5NjI5MTc2IDMuMDU1NTU1NTYsNC45NjIwMjE5NCBDMy4wNTU1NTU1Niw2LjAyNzc1MjEzIDMuOTI2MTEyOTksNi44OTE2OTcxNCA1LDYuODkxNjk3MTQgWiIgaWQ9IkxvY2F0aW9uLUNvcHktMyIgZmlsbD0iIzAxNjVDQyI+PC9wYXRoPiAgICA8L2c+PC9zdmc+"
        />
        <span className={smaller ? 'profile' : ''}>{text}</span>
      </Pin>
    ) : (
      <Crosshair>
        <img
          alt=""
          src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIyMXB4IiBoZWlnaHQ9IjIxcHgiIHZpZXdCb3g9IjAgMCAyMSAyMSIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4gICAgICAgIDx0aXRsZT5pY29uX2N1cnJlbnRfbG9jYXRpb248L3RpdGxlPiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4gICAgPGcgaWQ9Imljb25fY3VycmVudF9sb2NhdGlvbiIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+ICAgICAgICA8ZyBpZD0iR3JvdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEuMDAwMDAwLCAxLjAwMDAwMCkiIHN0cm9rZT0iIzAxNjVDQyI+ICAgICAgICAgICAgPGNpcmNsZSBpZD0iT3ZhbCIgZmlsbD0iIzAxNjVDQyIgY3g9IjkuNSIgY3k9IjkuNSIgcj0iMi41Ij48L2NpcmNsZT4gICAgICAgICAgICA8Y2lyY2xlIGlkPSJPdmFsIiBzdHJva2Utd2lkdGg9IjEuNSIgY3g9IjkuNSIgY3k9IjkuNSIgcj0iNi41Ij48L2NpcmNsZT4gICAgICAgICAgICA8cGF0aCBkPSJNOS41LDMgTDkuNSwwIiBpZD0iUGF0aCIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCI+PC9wYXRoPiAgICAgICAgICAgIDxwYXRoIGQ9Ik05LjUsMTkgTDkuNSwxNiIgaWQ9IlBhdGgiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiPjwvcGF0aD4gICAgICAgICAgICA8cGF0aCBkPSJNMTcuNSwxMSBMMTcuNSw4IiBpZD0iUGF0aCIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTcuNTAwMDAwLCA5LjUwMDAwMCkgcm90YXRlKC05MC4wMDAwMDApIHRyYW5zbGF0ZSgtMTcuNTAwMDAwLCAtOS41MDAwMDApICI+PC9wYXRoPiAgICAgICAgICAgIDxwYXRoIGQ9Ik0xLjUsMTEgTDEuNSw4IiBpZD0iUGF0aCIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMS41MDAwMDAsIDkuNTAwMDAwKSByb3RhdGUoLTkwLjAwMDAwMCkgdHJhbnNsYXRlKC0xLjUwMDAwMCwgLTkuNTAwMDAwKSAiPjwvcGF0aD4gICAgICAgIDwvZz4gICAgPC9nPjwvc3ZnPg=="
        />
        <span>{text}</span>
      </Crosshair>
    )}
  </div>
);

SingleLocation.propTypes = {};

export default SingleLocation;
