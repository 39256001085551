import React from 'react';
import { connect } from 'react-redux';
import { userByIdSelector } from './selectors';
import PropertiesCard from 'components/PropertiesCard';
import Page from 'components/Page';
import { UserProps } from 'models/User';

const ProfileScreen = ({ user }) => {
  if (!user) {
    return null;
  }

  return (
    <Page title="PROFILE" secondaryPage>
      <PropertiesCard
        title={user.name}
        subtitle={user.department}
        properties={user.properties}
        imageUrl={user.pictureUrl_x3}
      />
    </Page>
  );
};

ProfileScreen.propTypes = {
  user: UserProps.isRequired
};

const mapStateToProps = (state, ownProps) => ({
  user: userByIdSelector(state)(ownProps.userId)
});

const withConnect = connect(mapStateToProps);

export default withConnect(ProfileScreen);
