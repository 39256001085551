import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import styled from 'styled-components';

import { fetchContacts } from './actions';
import { contactsSelector } from './selectors';
import { feedByIdSelector } from '../../selectors';
import { loadingSelector } from 'modules/loading/selectors';
import LoadingProps from 'modules/loading/props';
import { ProfileEmptyPage } from 'pages/EmptyPage';
import FeedListSplitScreen from 'components/FeedListSplitScreen';
import UserListItem from './UserListItem';
import { DefaultPlaceholder } from 'components/CardPlaceholder';
import { clearSearch, searchContacts } from '../../modules/search/actions';
import { logUserSearchedMembers } from 'utils/piwikpro';

const Section = styled.div`
  font-family: 'Barlow-Bold';
  font-size: ${(props) => props.theme.fonts.size.user_feed_section_letter};
  line-height: ${(props) =>
    props.theme.fonts.line_height.user_feed_section_letter};
  color: ${(props) => props.theme.colors.contrast};
  margin: 50px 0 30px;
`;

let latestLetter = null;

class ContactsFeedPage extends React.Component {
  static propTypes = {
    fetchContacts: PropTypes.func.isRequired,
    searchContacts: PropTypes.func.isRequired,
    feedId: PropTypes.number.isRequired,
    contacts: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired
      }).isRequired
    ).isRequired,
    feed: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      type: PropTypes.string
    }).isRequired,
    loading: LoadingProps.isRequired
  };

  constructor() {
    super();
    this.state = { source: '' };
  }

  componentDidMount() {
    const { feedId, fetchContacts } = this.props;
    fetchContacts(feedId, 0);
  }

  latestLetter(letter) {
    const l = (letter || '').toUpperCase();
    if (l === null || l === latestLetter) {
      return;
    }
    latestLetter = l;
    return l;
  }

  getResultsFromSource = (source) => {
    this.setState({ source: source });
  };

  render() {
    latestLetter = null;
    const { feed, loading } = this.props;
    const resources =
      this.state.source === 'search'
        ? feed.search_contacts.toModelArray()
        : feed.contacts.toModelArray();

    const { fetchContacts, searchContacts } = this.props;
    return (
      <FeedListSplitScreen
        feedLoading={loading}
        searchable
        searchCallback={(term) => {
          // Send event to Analytics
          logUserSearchedMembers(term);

          this.getResultsFromSource('search');
          searchContacts(term, feed.id);
        }}
        currentSource={this.state.source}
        changeSourceCallback={this.getResultsFromSource}
        feed={feed}
        resources={resources}
        renderListItem={(user, { match }, searchTerm) => {
          const letter = this.latestLetter(user.first_name[0]);
          return (
            <React.Fragment>
              {!searchTerm && letter && <Section>{letter}</Section>}
              <UserListItem
                user={user}
                match={match}
                key={user.id}
                searchTerm={searchTerm}
                feedType={feed.type}
              />
            </React.Fragment>
          );
        }}
        renderPlaceholderListItem={() => (
          <DefaultPlaceholder minHeight="39px" />
        )}
        emptyPageComponent={ProfileEmptyPage}
        fetchPaginationCallback={(page) => {
          fetchContacts(feed.id, page);
        }}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  contacts: contactsSelector(state)(ownProps.feedId),
  feed: feedByIdSelector(state)(ownProps.feedId),
  loading: loadingSelector('CONTACTS')(state)
});

const mapDispatchToProps = (dispatch) => ({
  fetchContacts: (feedId, paginationPage) =>
    dispatch(fetchContacts(feedId, paginationPage)),
  searchContacts: (query, feedId) =>
    dispatch(searchContacts(query, feedId)).then(() => {
      dispatch(clearSearch());
    })
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withRouter, withConnect)(ContactsFeedPage);
