import React from 'react';
import ListCard from 'components/ListCard';
import { Link } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';
import { UserProps } from 'models/User';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const UserListItemContainer = styled(Link)`
  &,
  &:hover {
    text-decoration: none;
  }
`;

const UserListItem = ({ user, match, searchTerm, feedType }) => {
  const cardUrl = `${match.url}/${user.id}?search=${searchTerm || ''}`;
  return (
    <div key={user.id}>
      <UserListItemContainer to={cardUrl}>
        <ListCard
          mb="10px"
          imageUrl={user.pictureUrl_x3}
          title={user.name}
          subtitle={user.department}
          url={cardUrl}
          location={user.location}
          feedType={feedType}
        />
      </UserListItemContainer>
    </div>
  );
};

UserListItem.propTypes = {
  user: UserProps,
  match: ReactRouterPropTypes.match,
  feedType: PropTypes.string
};

export default UserListItem;
