import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { PostProps } from 'models/Post';

import Img from 'components/Img';
import { Flex } from '@rebass/grid';
import Since from 'components/Since';

export const Wrapper = styled.div`
  background: #fff;
  border-radius: 2.5px 2.5px 0 0;
  padding: 15px;
`;

const Username = styled.span`
  font-size: 17px;
  color: #4f4f4f;
`;

const CreatorTop = ({ style, post, post: { user } }) => {
  return (
    <Wrapper style={style}>
      <Flex alignitems="center">
        <Img src={user.pictureUrl_x3} rounded />
        <Flex ml="10px" flexDirection="column">
          <Username>{user.name}</Username>
          <Since date={post.created_at} />
        </Flex>
      </Flex>
    </Wrapper>
  );
};

CreatorTop.propTypes = {
  style: PropTypes.object,
  post: PostProps
};

export default CreatorTop;
