import { RSAA } from 'redux-api-middleware';
import {
  FETCH_NEWS_FEED_POSTS_REQUEST,
  FETCH_NEWS_FEED_POSTS_SUCCESS,
  FETCH_NEWS_FEED_POSTS_FAILURE
} from './constants';

import { endpointUrlWithPagination } from 'utils/url';
import { getHeadersWithToken } from 'utils/headers';

export const fetchPosts = (feedId, page) => ({
  [RSAA]: {
    endpoint: () =>
      endpointUrlWithPagination(`/api/v1/feeds/${feedId}/posts`, page),
    method: 'GET',
    types: [
      FETCH_NEWS_FEED_POSTS_REQUEST,
      {
        type: FETCH_NEWS_FEED_POSTS_SUCCESS,
        meta: { feedId }
      },
      FETCH_NEWS_FEED_POSTS_FAILURE
    ],
    headers: getHeadersWithToken()
  }
});
