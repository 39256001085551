import React from 'react';
import styled from 'styled-components';
import Item from './Item';
import PropTypes from 'prop-types';
import { DIRECTORY_FEED, USER_FEED } from 'routes/FeedRouter';

// prettier-ignore
const StyledProperties = styled.ul`
  grid-area: properties;
  ${({ $feedType }) => [USER_FEED, DIRECTORY_FEED].includes($feedType) ? `
    margin: 50px 0;
  ` : `
    margin-top: 10px;
  `}

`

const Properties = ({ properties, feedType }) => (
  <StyledProperties $feedType={feedType}>
    {properties.map((property) => {
      property.analyticsPayload = properties.analyticsPayload;
      return <Item key={property.name} property={property} />;
    })}
  </StyledProperties>
);

Properties.propTypes = {
  properties: PropTypes.array
};

export default Properties;
