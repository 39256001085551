import React from 'react';
import PropTypes from 'prop-types';
import Img from 'components/Img';
import Caption from './Caption';
import Wrapper from './Wrapper';
import Properties from 'components/Properties';
import SingleLocation from '../Profile/location/SingleLocation';
import Content from '../Content';
import { FeedProps } from 'models/Feed';
import styled from 'styled-components';
import { USER_FEED } from 'routes/FeedRouter';

const Subtitle = styled.span`
  font-size: ${(props) => props.theme.fonts.size.directory_post_subtitle};
  line-height: ${(props) =>
    props.theme.fonts.line_height.directory_post_subtitle};
  font-family: 'Barlow-Medium';
  color: ${(props) => props.theme.colors.gray_1};
`;
const HeaderContainer = styled.div`
  margin-bottom: 30px;
`;

const PropertiesCard = ({
  imageUrl,
  title,
  title2,
  subtitle,
  properties,
  location,
  body,
  feed
}) => {
  const feedType = feed ? feed.type : null;

  return (
    <Wrapper feedType={feedType}>
      <HeaderContainer style={{ gridArea: 'caption' }}>
        <Caption $feedType={feedType}>
          {`${title} ${title2}`}
          {location && (
            <React.Fragment>
              <br />
              <SingleLocation pin smaller text={location} />
            </React.Fragment>
          )}
        </Caption>
        <Subtitle>{subtitle}</Subtitle>
      </HeaderContainer>
      <Img
        src={imageUrl}
        rounded
        width={feedType === USER_FEED ? '200px' : '100px'}
        height={feedType === USER_FEED ? '200px' : '100px'}
      />

      {properties && <Properties properties={properties} feedType={feedType} />}

      <div style={{ gridArea: 'body' }}>
        <Content content={body} />
      </div>
    </Wrapper>
  );
};

PropertiesCard.propTypes = {
  imageUrl: PropTypes.string,
  title: PropTypes.string,
  title2: PropTypes.string,
  subtitle: PropTypes.string,
  location: PropTypes.string,
  properties: PropTypes.array,
  feed: FeedProps
};
PropertiesCard.defaultProps = {
  title2: ''
};

export default PropertiesCard;
