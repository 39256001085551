import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';
import feedListRoutes from '../routes/FeedRouter';
import withSizes from 'utils/sizes';
import WaitingScreen from 'screens/WaitingScreen';

const ProfileScreen = React.lazy(() => import('screens/ProfileScreen'));
const ProfileChangePasswordScreen = React.lazy(
  () => import('screens/ProfileChangePasswordScreen')
);

const AppRouter = ({ feeds, isMobile }) => {
  const firstFeed = feeds[0];

  return (
    <Suspense fallback={<WaitingScreen />}>
      <Switch>
        {feedListRoutes(feeds, isMobile)}
        <Route path="/profile" render={() => <ProfileScreen />} />
        <Route
          path="/change_password"
          render={() => <ProfileChangePasswordScreen />}
        />
        {firstFeed && <Redirect from="/" to={firstFeed.path} />}
        <Route path="/" component={WaitingScreen} />
      </Switch>
    </Suspense>
  );
};

AppRouter.propTypes = {
  feeds: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      path: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired
    })
  ).isRequired,
  isMobile: PropTypes.bool.isRequired
};

AppRouter.defaultProps = {
  feeds: []
};

export default withSizes(AppRouter);
