import React from 'react';
import Navbar from './Navbar';
import styled from 'styled-components';
import { phoneMin, tabletMax, tabletMin } from 'utils/media';

const SplitLayoutWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;

  ${phoneMin`
    display: flex;
    flex-direction: column;
    height: 100vh;
  `}

  ${tabletMin`
    display: grid;
    height: 100vh;

    grid:
      [row1-start] "header" 164px [row1-end]
      [row2-start] "children" auto [row2-end]
      / auto;
  `}
`;
const StyledContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 0 250px;

  ${tabletMax`
    padding: 0 50px;
  `}
  ${tabletMin`
    display: grid;
    height: 100vh;

    grid:
      [row1-start] "primary secondary" auto [row1-end]
      / 1fr 1fr;
  `}
`;

export default function SplitLayout({ children }) {
  return (
    <SplitLayoutWrapper>
      <Navbar />
      <StyledContainer>{children}</StyledContainer>
    </SplitLayoutWrapper>
  );
}
