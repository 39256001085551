export default (feed) => {
  const meta = feed.meta || {};
  const {
    current_page,
    total_page_count,
    page_record_count,
    total_record_count
  } = meta;
  return {
    hasPagination: !!current_page || false,
    hasMore: current_page && total_page_count - current_page > 0,
    currentPage: current_page,
    page_record_count: page_record_count,
    total_record_count: total_record_count
  };
};
