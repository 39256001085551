import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, matchPath } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';
import styled from 'styled-components';
import BoxWrapper from './BoxWrapper';

// prettier-ignore
const InnerWrapper = styled.div`
  display: grid;
  grid-template-columns: 6fr 1fr; 
  grid-template-rows: ${props => props.$isFirstItem ? '500px' : '300px'} 25px auto;
  grid-template-areas: 
    "image image"
    "date likes"
    "title title";
  gap: 10px; 
  align-items: center;
  width: 100%;
`

const isActive = (location, url) => {
  const urlPath = url && url.split('?')[0];
  return Boolean(matchPath(location.pathname, { path: urlPath }));
};

const ListNewsItem = ({
  children,
  location,
  url,
  featured,
  minHeight,
  noGrid,
  isFirstItem
}) => {
  return (
    <BoxWrapper minheight={minHeight} $active={isActive(location, url)}>
      {noGrid ? (
        children
      ) : (
        <InnerWrapper featured={featured} $isFirstItem={isFirstItem}>
          {children}
        </InnerWrapper>
      )}
    </BoxWrapper>
  );
};

ListNewsItem.propTypes = {
  children: PropTypes.node.isRequired,
  location: ReactRouterPropTypes.location.isRequired,
  url: PropTypes.string,
  featured: PropTypes.bool,
  minHeight: PropTypes.string,
  noGrid: PropTypes.bool,
  isFirstItem: PropTypes.bool
};
ListNewsItem.defaultProps = {
  isFirstItem: false
};

export default withRouter(ListNewsItem);
