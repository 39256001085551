import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';

import { likesByUsersByPostIdSelector } from '../../selectors';

import Profile from 'components/Profile';
import Page from 'components/Page';

const LikesScreen = ({ users, match }) => {
  if (!users) {
    return null;
  }

  return (
    <Page title="LIKES" secondaryPage>
      <Profile.List users={users} basePath={match.url} />
    </Page>
  );
};

LikesScreen.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      department: PropTypes.string.isRequired
    })
  ),
  match: PropTypes.shape({
    url: PropTypes.string
  })
};

const mapStateToProps = (state, ownProps) => ({
  users: likesByUsersByPostIdSelector(state)(ownProps.postId)
});

const withConnect = connect(mapStateToProps);

export default compose(withRouter, withConnect)(LikesScreen);
