import React from 'react';
import styled from 'styled-components';
import { space } from 'styled-system';
import LoadingIndicator from '../LoadingIndicator';
import theme from '../../styles/theme';

const PrimaryButtonStyle = styled.button`
  ${space}
  outline: none;
  padding: 15px 20px;
  border-radius: 5px;
  box-shadow: ${theme.shadows.normal};
  font-size: ${theme.fonts.size.button};

  cursor: pointer;
  font-family: 'Barlow-SemiBold';
  border: none;
  color: rgb(128, 186, 220);
  background-color: ${theme.colors.contrast};

  ${({ loading, disabled }) =>
    !loading &&
    !disabled &&
    `
    color: #ffffff;
    &:hover {
      box-shadow: ${theme.shadows.active};
      background-color: ${theme.colors.button.primary_highlighted};
   	}
  `}
`;

// prettier-ignore
const SecondaryButtonStyle = styled.button`
  ${space}
  color: ${ theme.colors.contrast };
  background-color: ${ theme.colors.button.secondary };
  border: solid;
  border-width: 1px;
  border-color: ${ theme.colors.contrast };
  border-radius: 5px;
  padding: 15px 20px;
  font-family: 'Barlow-Regular';
  font-size: 16px;
  outline: none;
  box-shadow: ${ theme.shadows.normal };
  cursor: pointer;

  ${({ loading, disabled}) => !loading && !disabled && `
    &:hover {
      box-shadow: ${ theme.shadows.active };
   	}
  `}
`

const Button = ({
  loading,
  children,
  disabled = false,
  isPrimary = true,
  ...props
}) =>
  isPrimary ? (
    <PrimaryButtonStyle {...props} disabled={loading || disabled}>
      {loading ? <LoadingIndicator /> : children}
    </PrimaryButtonStyle>
  ) : (
    <SecondaryButtonStyle {...props} disabled={loading || disabled}>
      {loading ? <LoadingIndicator /> : children}
    </SecondaryButtonStyle>
  );
export default Button;
