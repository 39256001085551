import { RSAA } from 'redux-api-middleware';
import {
  LIKE_REQUEST,
  LIKE_SUCCESS,
  LIKE_FAILURE,
  UNLIKE_REQUEST,
  UNLIKE_SUCCESS,
  UNLIKE_FAILURE
} from './constants';
import endpointUrl from 'utils/url';
import { getHeadersWithToken } from 'utils/headers';

export const likePost = (likeActionPath) => ({
  [RSAA]: {
    endpoint: () => endpointUrl(likeActionPath),
    method: 'POST',
    types: [LIKE_REQUEST, LIKE_SUCCESS, LIKE_FAILURE],
    headers: getHeadersWithToken()
  }
});

export const unlikePost = (likeActionPath) => ({
  [RSAA]: {
    endpoint: () => endpointUrl(likeActionPath),
    method: 'DELETE',
    types: [UNLIKE_REQUEST, UNLIKE_SUCCESS, UNLIKE_FAILURE],
    headers: getHeadersWithToken()
  }
});
