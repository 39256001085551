import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { routerSelector } from 'modules/router/selectors';
import ReactRouterPropTypes from 'react-router-prop-types';

import { fetchNavigationItems } from './actions';
import { feedsSelector } from 'modules/feed/selectors';
import AppRouter from '../../routes/AppRouter';
import { token } from 'utils/cookieStorage';

import { fetchUser } from 'modules/profile/actions';
import { profileSelector } from 'modules/profile/selectors';
import { UserProps } from 'models/User';
import { FeedProps } from 'models/Feed';

import withSizes from 'utils/sizes';
import { compose } from 'redux';
import Disclaimer from 'components/Disclaimer';
import { consentLatestDisclaimer } from 'components/Disclaimer/actions';

const App = (props) => {
  const { fetchNavigationItems, fetchUser, feeds, user } = props;
  const [modalIsOpen, setModalIsOpen] = useState(false);

  useEffect(() => {
    if (token()) {
      fetchNavigationItems();
      fetchUser();
    } else {
      console.error('no token defined');
    }
  }, []);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    document.body.style.overflow = 'auto';
  };

  const handleAcceptDisclaimer = (e) => {
    e.preventDefault();
    consentLatestDisclaimer()
      .then(() => closeModal())
      .catch((e) => console.error(e.message));
  };

  useEffect(() => {
    if (user && !user.has_consented_to_latest_disclaimer) openModal();
  }, [user]);

  return (
    <>
      <AppRouter feeds={feeds} />
      {modalIsOpen && (
        <Disclaimer
          modalIsOpen={modalIsOpen}
          openModal={openModal}
          closeModal={closeModal}
          handleAcceptDisclaimer={handleAcceptDisclaimer}
        />
      )}
    </>
  );
};

App.propTypes = {
  fetchNavigationItems: PropTypes.func.isRequired,
  fetchUser: PropTypes.func.isRequired,
  user: UserProps,
  feeds: PropTypes.arrayOf(FeedProps),
  router: PropTypes.shape({
    location: ReactRouterPropTypes.location
  })
};

const mapStateToProps = createStructuredSelector({
  user: profileSelector,
  feeds: feedsSelector,
  router: routerSelector
});

const mapDispatchToProps = (dispatch) => ({
  fetchNavigationItems: () => dispatch(fetchNavigationItems()),
  fetchUser: () => dispatch(fetchUser())
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withSizes, withConnect)(App);
