import { attr, Model } from 'redux-orm';
import PropTypes from 'prop-types';

class File extends Model {
  static modelName = 'File';

  static fields = {
    filename: attr(),
    label: attr(),
    mime_type: attr(),
    uri: attr()
  };

  static parse(data) {
    return this.upsert({
      id: data.uri,
      filename: data.filename,
      label: data.label,
      mime_type: data.mime_type,
      uri: data.uri
    });
  }
}

export default File;

export const FileProps = PropTypes.shape({
  filename: PropTypes.string,
  label: PropTypes.string,
  mime_type: PropTypes.string,
  uri: PropTypes.string
});
