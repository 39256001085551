import { handleActions } from 'redux-actions';
import { FETCH_USER_SUCCESS, FETCH_USER_FAILURE } from './constants';
import {
  SIGN_IN_SUCCESS,
  SIGN_IN_FAILURE
} from 'modules/authentication/constants';
import {
  POST_SHARELOCATION_SUCCESS,
  POST_CHECKOUT_SUCCESS
} from '../../components/Profile/location/constants';

export const initialState = null;

const transformUser = (data) => ({
  id: data._id,
  name: data.first_name,
  email: data.email,
  middle_name: data.middle_name,
  last_name: data.last_name,
  location: data.location,
  pictureUrl_x3: data.picture_x3,
  properties: data.properties,
  has_consented_to_latest_disclaimer: data.has_consented_to_latest_disclaimer
});

const handleFetchUserSuccess = (state, action) => {
  return transformUser(action.payload.data);
};
// eslint-disable-next-line no-unused-vars
const handleFetchUserFailure = (state, action) => initialState;
const handleShareLocation = (state, action) => {
  return {
    ...state,
    closeModal: 'force',
    location: action.payload.data.location,
    checkout: true
  };
};
// eslint-disable-next-line no-unused-vars
const handleCheckoutLocation = (state, action) => {
  return { ...state, closeModal: 'force', location: null };
};

const profileReducer = handleActions(
  {
    [POST_SHARELOCATION_SUCCESS]: handleShareLocation,
    [POST_CHECKOUT_SUCCESS]: handleCheckoutLocation,
    [SIGN_IN_SUCCESS]: handleFetchUserSuccess,
    [SIGN_IN_FAILURE]: handleFetchUserFailure,
    [FETCH_USER_SUCCESS]: handleFetchUserSuccess,
    [FETCH_USER_FAILURE]: handleFetchUserFailure
  },
  initialState
);

export default profileReducer;
