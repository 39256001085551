const theme = {
  colors: {
    contrast: '#036AB6',
    brand_blue: '#1F68B1',
    dim_blue: '#C8DFF0',
    black: '#000000',
    light: '#ffffff',
    background: '#FFF',
    gray_1: '#767676',
    gray_2: '#EFEFF0',
    gray_3: '#848488',

    primary: '#f7f7f7',
    secondary: '#efefef',
    tertiary: '#fafafa',
    dark: '#7a7a7a',
    button: {
      primary: '#087cbf',
      primary_highlighted: '#065d8f',
      secondary: 'rgba(0, 0, 0, 0)',
      secondary_highlighted: '#065d8f'
    }
  },
  shadows: {
    normal: '0 2px 7px 0 rgba(0, 0, 0, 0.07)',
    active: '0 2px 10px 0 rgba(0, 0, 0, 0.1)',
    news_active: '0 0 10px 0 rgba(0, 0, 0, 0.1)'
  },
  transforms: {
    raised: 'translate(-1px, -1px)'
  },
  fonts: {
    size: {
      navbar_item: '1.25rem',
      navbar_item_mobile: '1.125rem',
      profile: '1.125rem',
      back_button: '1rem',
      news_post_title: '2.5rem',
      news_post_date: '1rem',
      news_title: '2.5rem',
      search_input: '1.125rem',
      news_item_title: '1.875rem',
      news_item_likes: '1rem',
      directory_post_title: '1.25rem',
      directory_post_subtitle: '1.125rem',
      directory_project_item_title: '2.5rem',
      properties_item_name: '1.125rem',
      properties_item_value: '1.25rem',
      user_feed_section_letter: '1.25rem',
      user_feed_card_title: '1.25rem',
      user_feed_card_subtitle: '1.125rem',
      location: '1rem',
      empty_page_title: '2.5rem',
      empty_page_subtitle: '1.25rem',
      button: '1rem',
      profile_selected_input: '1.125rem',
      news_search_result_text: '1.563rem',
      disclaimer_popup_title: '1.438rem',
      disclaimer_popup_button: '1rem'
    },
    line_height: {
      navbar_item: '1.375rem',
      back_button: '2.188rem',
      news_post_title: '3.125rem',
      news_post_description: '1.625rem',
      news_title: '2.813rem',
      search_input: '1.375rem',
      news_item_title: '2.188rem',
      directory_post_title: '1.563rem',
      directory_post_subtitle: '1.375rem',
      directory_project_item_title: '2.813rem',
      properties_item_name: '1.375rem',
      properties_item_value: '1.563rem',
      user_feed_section_letter: '1.563rem',
      user_feed_card_title: '1.625rem',
      user_feed_card_subtitle: '1.375rem',
      empty_page_title: '2.813rem',
      empty_page_subtitle: '1.875rem',
      news_search_result_text: '1.875rem',
      disclaimer_popup_title: '1.875rem'
    }
  }
};

export default theme;
