import { phoneMax, tabletMax } from 'utils/media';
import { FeedProps } from 'models/Feed';
import Navigation from './Navigation';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import { UserProps } from 'models/User';
import { connect } from 'react-redux';
import { feedsSelector } from 'modules/feed/selectors';
import { profileSelector } from 'modules/profile/selectors';
import { routerSelector } from 'modules/router/selectors';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Logo from 'components/Logo';
import ProfileDropdown from 'components/ProfileDropdown';
import { createStructuredSelector } from 'reselect';
import { NEWS_FEED } from 'routes/FeedRouter';
import ContainerDimensions from 'react-container-dimensions';

const MIN_DESKTOP_WIDTH = 1400;
const MIN_TABLET_WIDTH = 1100;

const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const StyledNavbar = styled.header`
  position: sticky;
  width: 100%;
  box-sizing: border-box;
  top: 0;
  grid-area: header;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 50px;
  background-color: ${(props) => props.theme.colors.background};
  gap: 60px;
  border-bottom: 1px solid ${(props) => props.theme.colors.dim_blue};
  z-index: 100000;
  &.is-sticky {
    position: fixed;
  }
  a {
    text-decoration: none;
  }

  ${tabletMax`
    padding: 50px 35px;
  `}
  ${phoneMax`
    flex: 0 0 50px;
    padding: 18px 13px;
    position: sticky;
    box-sizing: border-box;
    top: 0;
    left: 0;
    gap: 40px;
  `};
`;
const LogoLink = styled(Link)`
  &,
  &:hover {
    text-decoration: none;
  }
`;

const Navbar = (props) => {
  const { user, feeds, feed } = props;
  const [state, setState] = useState({
    isScroll: false,
    isNewsFeed: false
  });

  useEffect(() => {
    if (feed) setState({ ...state, isNewsFeed: feed.type === NEWS_FEED });
  }, [feed]);

  const mainNavigation = (itemsToShow) => feeds.slice(0, itemsToShow);

  const moreNavigation = (itemsToShow) => feeds.slice(itemsToShow);

  const handleScroll = () => {
    const scrollPosition =
      window.pageYOffset ||
      window.scrollY ||
      document.body.scrollTop ||
      document.documentElement.scrollTop;
    if (scrollPosition > 50) setState({ ...state, isScroll: true });
    else setState({ ...state, isScroll: false });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });

  const getNavbarCount = (width) =>
    width >= MIN_DESKTOP_WIDTH ? 3 : width <= MIN_TABLET_WIDTH ? 1 : 2;

  return (
    <ContainerDimensions>
      {({ width }) => {
        const itemsToShow = getNavbarCount(width);

        return (
          <StyledNavbar
            className={state.isScroll && state.isNewsFeed && 'is-sticky'}
          >
            <ItemWrapper>
              <LogoLink to="/">
                <Logo small />
              </LogoLink>
            </ItemWrapper>
            <ItemWrapper>
              <Navigation
                mainNavigation={mainNavigation(itemsToShow)}
                moreNavigation={moreNavigation(itemsToShow)}
              />
            </ItemWrapper>
            <ItemWrapper>{user && <ProfileDropdown user={user} />}</ItemWrapper>
          </StyledNavbar>
        );
      }}
    </ContainerDimensions>
  );
};

Navbar.propTypes = {
  user: UserProps,
  feeds: PropTypes.arrayOf(FeedProps),
  router: PropTypes.shape({
    location: ReactRouterPropTypes.location
  }),
  burgerOnly: PropTypes.bool
};

Navbar.defaultProps = {
  feed: null
};

const mapStateToProps = createStructuredSelector({
  user: profileSelector,
  feeds: feedsSelector,
  router: routerSelector
});

const withConnect = connect(mapStateToProps);

export default withConnect(Navbar);
