import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const styles = {
  success: css`
    background: #e3f3dd;
    border: 1px #dfefd5 solid;
    color: #5f995f;
  `,
  error: css`
    background: #ffeff0;
    border: 1px #ffd5d6 solid;
    color: #d2827e;
  `
};

// prettier-ignore
const Flash = styled.div`
  font-weight: 500;
  top: 10px;
  ${({ type }) => styles[type]};
  padding: 10px 10px 8px;
  margin-bottom: 20px;
  border-radius: 2px;
  height: 20px;
`

Flash.propTypes = {
  type: PropTypes.oneOf(['success', 'error'])
};

export default Flash;
