import { RSAA } from 'redux-api-middleware';
import {
  SEARCH_FEEDS_REQUEST,
  SEARCH_FEEDS_SUCCESS,
  SEARCH_FEEDS_FAILURE,
  SEARCH_CONTACTS_REQUEST,
  SEARCH_CONTACTS_SUCCESS,
  SEARCH_CONTACTS_FAILURE,
  CLEAR_SEARCH
} from './constants';

import { endpointUrlWithPaginationAndParams } from 'utils/url';
import { getHeadersWithToken } from 'utils/headers';

export const searchFeed = (feedId, query) => ({
  [RSAA]: {
    endpoint: () =>
      endpointUrlWithPaginationAndParams(`/api/v1/search/by_feed/${feedId}`, [
        `query=${query}`
      ]),
    method: 'GET',

    types: [
      SEARCH_FEEDS_REQUEST,
      {
        type: SEARCH_FEEDS_SUCCESS,
        meta: { feedId }
      },
      SEARCH_FEEDS_FAILURE
    ],
    headers: getHeadersWithToken()
  }
});

export const searchContacts = (query, feedId) => ({
  [RSAA]: {
    endpoint: () =>
      endpointUrlWithPaginationAndParams('/api/v1/search/contacts', [
        `query=${query}`,
        `feed_id=${feedId}`
      ]),
    method: 'GET',
    types: [
      SEARCH_CONTACTS_REQUEST,
      {
        type: SEARCH_CONTACTS_SUCCESS,
        meta: { feedId }
      },
      SEARCH_CONTACTS_FAILURE
    ],
    headers: getHeadersWithToken()
  }
});

export const clearSearch = () => ({
  type: CLEAR_SEARCH
});
