import React from 'react';
import PropTypes from 'prop-types';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { fetchPost } from 'pages/PostPage/actions';
import { postByIdSelector } from '../../selectors';

import { since } from 'components/Since';

import Page from 'components/Page';
import BoxWrapper from 'components/Wrappers/BoxWrapper';
import Content from 'components/Content';
import { Box } from '@rebass/grid';

import { Player, BigPlayButton } from 'video-react';
import 'video-react/dist/video-react.css';
import Title from 'components/PostListItem/PostTitle';

class VideoPage extends React.Component {
  static defaultProps = {
    post: {}
  };

  componentDidMount() {
    const { feedId, postId, fetchPost } = this.props;
    fetchPost(feedId, postId);
  }

  render() {
    const { post } = this.props;

    if (!post) return null;

    return (
      <Page title="VIDEO DETAILS" secondaryPage>
        <BoxWrapper>
          <Player src={post.content.video}>
            <BigPlayButton position="center" />
          </Player>
          <Box mt="15px" mb="10px">
            <Title title={post.title} subtitle={since(post.created_at)} />
          </Box>
          <Content content={post.content.body} />
        </BoxWrapper>
      </Page>
    );
  }
}

VideoPage.propTypes = {
  post: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    content: PropTypes.shape({
      body: PropTypes.string.isRequired
    })
  }),
  match: PropTypes.shape({
    url: PropTypes.string
  }),
  feedId: PropTypes.number,
  postId: PropTypes.number,
  fetchPost: PropTypes.func.isRequired
};

const mapStateToProps = (state, ownProps) => ({
  post: postByIdSelector(state)(ownProps.postId)
});

const mapDispatchToProps = (dispatch) => ({
  fetchPost: (feedId, postId) => dispatch(fetchPost(feedId, postId))
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withRouter, withConnect)(VideoPage);
