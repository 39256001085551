import React, { useEffect } from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import getInjectors from './reducerInjectors';
import { useStore } from 'react-redux';

/**
 * Dynamically injects a reducer
 *
 * @param {string} key A key of the reducer
 * @param {function} reducer A reducer that will be injected
 */
const withReducer =
  ({ key, reducer }) =>
  (WrappedComponent) => {
    const ReducerInjector = (props) => {
      const store = useStore();

      const { injectReducer } = getInjectors(store);

      useEffect(() => {
        injectReducer(key, reducer);
      }, [key, reducer, injectReducer]);

      return <WrappedComponent {...props} />;
    };

    ReducerInjector.WrappedComponent = WrappedComponent;
    ReducerInjector.displayName = `withReducer(${
      WrappedComponent.displayName || WrappedComponent.name || 'Component'
    })`;

    return hoistNonReactStatics(ReducerInjector, WrappedComponent);
  };

export default withReducer;
