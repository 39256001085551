import { createSelector as createOrmSelector } from 'redux-orm';
import orm, { selector as entitiesSelector } from 'models';
import createDynamicOrmSelector from 'utils/createDynamicOrmSelector';

export const feedsSelector = createOrmSelector(
  orm,
  entitiesSelector,
  (session) => session.Feed.all().toModelArray()
);

export const feedByIdSelector = createDynamicOrmSelector(
  orm,
  entitiesSelector,
  (session, feedId) => session.Feed.withId(feedId)
);
