import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { phoneMax } from 'utils/media';

export const UnderlineElement = styled.span`
  position: absolute;
  bottom: -10px;
  left: 0;
  height: 8px;
  width: 100%;
  background-color: ${(props) => props.theme.colors.contrast};
  border-radius: 10px 0px;
  visibility: hidden;
  opacity: 0;
  transform: translateY(5px);
`;
export const styledHoverActive = (props) => `
  color: transparent;
  &::after{
    visibility: visible;
    color: ${props.theme.colors.black} !important;
  }
`;
export const styledItem = (props) => `
  font-size: ${props.theme.fonts.size.navbar_item};
  text-decoration: none;
  color: ${props.theme.colors.black};
  cursor: pointer;
  padding: 0 10px;
  &::after{
    content: attr(data-text);
    position: absolute;
    font-family: Barlow-Bold;
    visibility: hidden;
    color: transparent;
  }
`;
// prettier-ignore
const StyledLink = styled(NavLink)`
  display: flex;
  align-items: center;
  position: relative;
  ${styledItem}

  &:hover, &.active, &:focus {
    ${props => styledHoverActive(props)}  
    #arrow {
      border-right: 3px solid ${(props) => props.theme.colors.black};
      border-top: 3px solid ${(props) => props.theme.colors.black};      
    }
  }
  &.active{
    ${UnderlineElement} {
      visibility: visible;
      opacity: 1;
      transform: translateY(0);
      bottom: -12px;
    }
  }
  ${phoneMax`
    font-size: ${props => props.theme.fonts.size.navbar_item_mobile};
  `};
`;

const Item = ({ item }) => {
  return (
    <li>
      <StyledLink to={item.path} data-text={item.name}>
        <div>{item.name}</div>
        <UnderlineElement />
      </StyledLink>
    </li>
  );
};

Item.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired
  }).isRequired
};

export default Item;
