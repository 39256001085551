import { CustomEvent } from '@piwikpro/react-piwik-pro';
import PiwikPro from '@piwikpro/react-piwik-pro';

const { REACT_APP_PIWIK_PRO_SERVER, REACT_APP_PIWIK_PRO_ID } = process.env;

const piwikProLog = (eventCategory, eventAction, eventName) => {
  try {
    CustomEvent.trackEvent(eventCategory, eventAction, eventName);
  } catch (e) {
    console.warn('Unable to access Piwik PRO', e);
  }
};

export const piwikProInitialize = () => {
  try {
    PiwikPro.initialize(REACT_APP_PIWIK_PRO_ID, REACT_APP_PIWIK_PRO_SERVER);
  } catch (e) {
    console.warn('Unable to initialize Piwik PRO', e);
  }
};

export const logShareItem = (title) =>
  piwikProLog('user_shared_item', 'Click', title);

export const logShareLocation = (city) =>
  piwikProLog('user_shared_location', 'Click', city);

export const logStopSharingLocation = () => {
  piwikProLog('user_stopped_sharing_location', 'Click');
};

export const logLikePost = (likedByUser, postTitle) => {
  piwikProLog(likedByUser ? 'liked_post' : 'unliked_post', 'Click', postTitle);
};

export const logTapUserEmail = (userEmail) => {
  piwikProLog('user_tapped_email_address', 'Click', userEmail);
};

export const logTapUserUrl = (userName) => {
  piwikProLog('user_tapped_user_profile_url', 'Click', userName);
};

export const logUserSearchedFeed = (term, feedName) => {
  let normalizedFeedName = feedName.replace(/\W/g, '_').toLowerCase();
  piwikProLog('user_searched_in_feed_' + normalizedFeedName, 'Search', term);
};

export const logUserSearchedMembers = (term) => {
  piwikProLog('user_searched_in_members', 'Search', term);
};

export const logViewPost = (postName, feedName) => {
  let normalizedFeedName = feedName.replace(/\W/g, '_').toLowerCase();
  piwikProLog('view_item_' + normalizedFeedName, 'Screen', postName);
};

export const logViewMember = (userName) => {
  piwikProLog('view_item_member_directory', 'Screen', userName);
};
