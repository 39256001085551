import React from 'react';
import Navbar from './Navbar';
import styled from 'styled-components';
import { phoneMin, tabletMin, tabletMax } from 'utils/media';

const SingleLayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;

  ${phoneMin`
    height: 100vh;
  `}

  ${tabletMin`
    height: 100vh;

  `}
`;
const StyledContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 0 250px 30px;
  overflow: auto;

  ${tabletMax`
    padding: 0 50px 30px;
  `}
`;

export default function SingleLayout({ children, feed }) {
  return (
    <SingleLayoutWrapper>
      <Navbar feed={feed} />
      <StyledContainer>{children}</StyledContainer>
    </SingleLayoutWrapper>
  );
}
