import PostTitle from './PostTitle';
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';
import ListItem from 'components/ListItem';
import SocialBlock from 'components/SocialBlock';
import formatDistance from 'date-fns/formatDistance';
import Img from 'components/Img';
import { PostProps } from 'models/Post';
import { FeedProps } from 'models/Feed';

export const Title = PostTitle;

const PostListItem = ({ post, feed, match, featured }) => {
  if (!post) return null;
  const postUrl = `${match.url}/${post.id}`;
  const since = formatDistance(post.created_at, new Date());

  return (
    <ListItem url={postUrl} featured={featured}>
      <Link to={postUrl} style={{ gridArea: 'image' }}>
        <Img
          src={post.content.image_tablet_x3}
          object={post.content}
          objectImageKey={'image_phone'}
          featured={featured}
          width="80px"
          height="80px"
        />
      </Link>
      <Link to={postUrl} style={{ gridArea: 'content' }}>
        <Title title={post.title} subtitle={`${since} ago`} />
      </Link>
      {post.showCommentsAndLikes && (
        <SocialBlock
          feed={feed}
          post={post}
          commentsUrl={`${postUrl}%23comments`}
        />
      )}
    </ListItem>
  );
};

PostListItem.propTypes = {
  post: PostProps,
  feed: FeedProps,
  match: ReactRouterPropTypes.match,
  featured: PropTypes.bool
};

export default withRouter(PostListItem);
