import { RSAA } from 'redux-api-middleware';
import {
  FETCH_CONTACTS_REQUEST,
  FETCH_CONTACTS_SUCCESS,
  FETCH_CONTACTS_FAILURE
} from './constants.js';

import { endpointUrlWithPaginationAndParams } from 'utils/url';
import { getHeadersWithToken } from 'utils/headers.js';

export const fetchContacts = (feedId, page) => ({
  [RSAA]: {
    endpoint: () =>
      endpointUrlWithPaginationAndParams(
        '/api/v1/contacts',
        [`feed_id=${feedId}`],
        page
      ),
    method: 'GET',
    types: [
      FETCH_CONTACTS_REQUEST,
      {
        type: FETCH_CONTACTS_SUCCESS,
        meta: { feedId, page }
      },
      FETCH_CONTACTS_FAILURE
    ],
    headers: getHeadersWithToken()
  }
});
