import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { DelayInput } from 'react-delay-input';
import { DIRECTORY_FEED, NEWS_FEED, USER_FEED } from 'routes/FeedRouter';

const NEWS_PLACEHOLDER = 'Search news...';
const DIRECTORY_PLACEHOLDER = 'Search projects...';
const USER_PLACEHOLDER = 'Find a colleague...';
const OTHER_PLACEHOLDER = 'Search...';

const SearchDelayedInput = styled(DelayInput)`
  font-family: 'Barlow-Medium';
  font-size: ${(props) => props.theme.fonts.size.search_input};
  line-height: ${(props) => props.theme.fonts.line_height.search_input};
  color: ${(props) => props.theme.colors.gray_1};
  border: 1px solid ${(props) => props.theme.colors.dim_blue};
  width: 100%;
  padding: 9px 40px 9px 20px;
  ${(props) =>
    !props.$isNewsScreen &&
    `
    margin-bottom: 30px;
  `}
  outline: none;
  box-sizing: border-box;
  background: url('/search-icon.svg') no-repeat center right 3%;
`;

export default function SearchInput(props) {
  const { feed } = props;
  const [placeholder, setPlaceholder] = useState(OTHER_PLACEHOLDER);

  useEffect(() => {
    switch (feed) {
      case NEWS_FEED:
        setPlaceholder(NEWS_PLACEHOLDER);
        break;
      case USER_FEED:
        setPlaceholder(USER_PLACEHOLDER);
        break;
      case DIRECTORY_FEED:
        setPlaceholder(DIRECTORY_PLACEHOLDER);
        break;
      default:
        setPlaceholder(OTHER_PLACEHOLDER);
        break;
    }
  }, []);

  return <SearchDelayedInput {...props} placeholder={placeholder} />;
}
