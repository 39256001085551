import { css } from 'styled-components';

// export const phoneWidth = 376
export const tabletWidth = 768; // 876px
export const desktopWidth = 1176; // 992px

export const phoneQueryMin = `(max-width: ${tabletWidth - 1}px)`;
export const tabletQueryMin = `(min-width: ${tabletWidth}px)`;
export const desktopQueryMin = `(min-width: ${desktopWidth + 1}px)`;

export const phoneQueryMax = `(max-width: ${tabletWidth - 1}px)`;
export const tabletQueryMax = `(max-width: ${desktopWidth}px)`;
export const desktopQueryMax = `(min-width: ${desktopWidth + 1}px)`;

export const tabletOnlyQuery = `(min-width: ${tabletWidth}px) and (max-width: ${desktopWidth}px)`;

export const phoneMin = (...args) => css`
  @media ${phoneQueryMin} {
    ${css(...args)};
  }
`;

export const tabletMin = (...args) => css`
  @media ${tabletQueryMin} {
    ${css(...args)};
  }
`;

export const desktopMin = (...args) => css`
  @media ${desktopQueryMin} {
    ${css(...args)};
  }
`;

export const phoneMax = (...args) => css`
  @media ${phoneQueryMax} {
    ${css(...args)};
  }
`;

export const tabletMax = (...args) => css`
  @media ${tabletQueryMax} {
    ${css(...args)};
  }
`;

export const desktopMax = (...args) => css`
  @media ${desktopQueryMax} {
    ${css(...args)};
  }
`;
