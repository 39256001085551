import { DIRECTORY_FEED, USER_FEED } from 'routes/FeedRouter';
import styled from 'styled-components';

const Caption = styled.div`
  font-family: 'Barlow-SemiBold';
  color: ${(props) => props.theme.colors.black};
  ${(props) =>
    [USER_FEED, DIRECTORY_FEED].includes(props.$feedType)
      ? `
      font-size: ${props.theme.fonts.size.directory_project_item_title};
      line-height: ${props.theme.fonts.line_height.directory_project_item_title};
      border-bottom: 1px solid ${props.theme.colors.dim_blue};
      padding-bottom: 30px;
      margin-bottom: 20px;
  `
      : `
      font-size: 22px;
      margin-bottom: 8px;
  `}
`;

export default Caption;
