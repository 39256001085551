import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { logTapUserEmail, logTapUserUrl } from 'utils/piwikpro';

export const StyledProperty = styled.li`
  margin-bottom: 40px;
  padding-bottom: 10px;
  border-bottom: 1px solid ${(props) => props.theme.colors.dim_blue};

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Name = styled.div`
  line-height: ${(props) => props.theme.fonts.line_height.properties_item_name};
  font-size: ${(props) => props.theme.fonts.size.properties_item_name};
  color: ${(props) => props.theme.colors.gray_1};
  opacity: 0.5;
  padding: 0;
  margin-bottom: 15px;
`;

const Value = styled.ul`
  line-height: ${(props) =>
    props.theme.fonts.line_height.properties_item_value};
  font-size: ${(props) => props.theme.fonts.size.properties_item_value};
  font-family: 'Barlow-SemiBold';
  color: ${(props) => props.theme.colors.black};
  min-height: 18px;
  display: flex;
  flex-wrap: wrap;
  word-break: break-word;
  ${(props) =>
    props.$showBulletPoints &&
    `
    list-style: inside;
    color: ${props.theme.colors.contrast};
    flex-direction: column;
  `}
`;
const LinkValue = styled(Link)`
  color: ${(props) => props.theme.colors.contrast};
`;
const HrefValue = styled.a`
  color: ${(props) => props.theme.colors.contrast};
`;

const Item = ({ property }) => {
  // either a "kind" for feed property or a "type" for a user property
  let propType =
    property.property_type === undefined ? '' : property.property_type;
  let propKind = property.kind === undefined ? '' : property.kind;
  let kind = property.type === undefined ? '' : property.type;
  const type = propKind || propType || kind;
  const showBulletPoints =
    ['directory_posts', 'news_posts', 'users'].includes(type) ||
    (type === 'users' && property.values.length > 1);

  const formatValue = (property) => {
    switch (type) {
      case 'url':
        return (
          <HrefValue
            href={property.value}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => {
              logTapUserUrl(property.analyticsPayload);
            }}
          >
            {property.value}
          </HrefValue>
        );
      case 'email':
        return (
          <HrefValue
            href={`mailto:${property.value}`}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => {
              logTapUserEmail(property.value);
            }}
          >
            {property.value}
          </HrefValue>
        );
      case 'users':
        if (showBulletPoints) {
          return property.values.map((value) => {
            const userId = value.url.split('/')[3];
            return (
              <li key={value.url}>
                <LinkValue to={`/member_directory/${userId}`}>
                  <span style={{ marginRight: 5 }}>{value.name}</span>
                </LinkValue>
              </li>
            );
          });
        } else {
          return property.values.map((value) => {
            const userId = value.url.split('/')[3];
            return (
              <LinkValue key={value.url} to={`/member_directory/${userId}`}>
                <span style={{ marginRight: 5 }}>{value.name}</span>
              </LinkValue>
            );
          });
        }
      case 'directory_posts':
      case 'news_posts': {
        let values = property.values ? property.values : [];
        return values
          .filter((value) => value.url !== undefined && value.url !== null)
          .map((value) => {
            const feedId = value.url.split('/')[3];
            const postId = value.url.split('/')[5];

            return (
              // Link to news post by feed_id and post_id
              // N.B. Since app uses routes derived from feed name a redirect in performed in FeedRouter (/feeds/9/2155 -> /news/2155)
              <li key={value.url}>
                <LinkValue to={`/feeds/${feedId}/${postId}`}>
                  <span style={{ marginRight: 5 }}>{value.name}</span>
                </LinkValue>
              </li>
            );
          });
      }
      default:
        return property.value;
    }
  };

  return (
    <StyledProperty>
      <Name>{property.name}</Name>
      <Value $showBulletPoints={showBulletPoints}>
        {formatValue(property)}
      </Value>
    </StyledProperty>
  );
};

Item.propTypes = {
  property: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string
  })
};

export default Item;
