import React, { Component } from 'react';
import { compose } from 'redux';

import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';
import saga from 'modules/authentication/saga';
import reducer from 'modules/authentication/reducer';

import { withRouter } from 'react-router';
import { Route, Switch, Redirect } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import ReactRouterPropTypes from 'react-router-prop-types';
import { token } from 'utils/cookieStorage';

import App from 'components/App';
import SignInScreen from 'screens/Auth/SignInScreen';
import ResetPasswordScreen from 'screens/Auth/ResetPasswordScreen';
import NewPasswordScreen from 'screens/Auth/NewPasswordScreen';
import SuccessScreen from 'screens/Auth/SuccessScreen';
import FailureScreen from 'screens/Auth/FailureScreen';
import AuthCallback from 'screens/Auth/Callback';

class Router extends Component {
  static propTypes = {
    history: ReactRouterPropTypes.history.isRequired,
    location: ReactRouterPropTypes.location.isRequired
  };

  isAuthenticated() {
    return !!token();
  }

  render() {
    const isAuthenticated = this.isAuthenticated();

    return (
      <Switch>
        <LastLocationProvider>
          <Route path="/callback" exact component={AuthCallback} />
          <Route path="/sign_in/failure" component={FailureScreen} />
          <Route
            path="/sign_in"
            render={(props) => <SignInScreen {...props} />}
          />
          <Route
            path="/password/reset"
            render={(props) => <ResetPasswordScreen {...props} />}
          />
          <Route path="/password/confirm" component={NewPasswordScreen} />
          <Route path="/password/success" component={SuccessScreen} />
          {isAuthenticated &&
          this.props.location.pathname !== '/password/success' ? (
            <Route component={App} />
          ) : (
            !this.props.location.pathname.startsWith('/sign_in') &&
            this.props.location.pathname !== '/callback' &&
            this.props.location.pathname !== '/password/reset' &&
            this.props.location.pathname !== '/password/confirm' &&
            this.props.location.pathname !== '/password/success' &&
            this.props.location.pathname !== '/sign_in/by_email/failure' && (
              <Redirect to="/sign_in" />
            )
          )}
        </LastLocationProvider>
      </Switch>
    );
  }
}

const withSaga = injectSaga({ key: 'authentication', saga });
const withReducer = injectReducer({ key: 'authentication', reducer });

export default compose(withSaga, withReducer, withRouter)(Router);
