import React, { Component } from 'react';
import { compose } from 'redux';
import withSizes from 'utils/sizes';
import AuthLayout from '../AuthLayout';
import FormBox from 'components/FormBox';
import Button from '../../../components/Button';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';

class FailurePage extends Component {
  goToHome() {
    const { push } = this.props;
    push('/sign_in');
  }

  render() {
    const { isMobile } = this.props;
    return (
      <AuthLayout>
        <FormBox
          isMobile={isMobile}
          isSmall={false}
          icon={
            <svg width="0px" height="45px" viewBox="0 0 0 0" version="1.1" />
          }
          title="Unable to authenticate"
          subtitleLine1={'The token is invalid or expired.'}
          subtitleLine2={'Please try again.'}
        >
          <Button
            onClick={this.goToHome.bind(this)}
            style={{ width: '227px', alignSelf: 'center' }}
          >
            Go to Log in
          </Button>
        </FormBox>
      </AuthLayout>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  push: (path) => dispatch(push(path))
});

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, withSizes)(FailurePage);
