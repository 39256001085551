import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { space } from 'styled-system';
import { Link } from 'react-router-dom';

// prettier-ignore
const StyledLink = styled(Link)`
  ${space}
  display: flex;
  align-items: center;
  justify-content: center;
  width: 37px;
  height: 22px;
  border-radius: 11px;
  background-image: url("data:image/svg+xml,%3Csvg height='22' viewBox='0 0 37 22' width='37' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m11 .75c-5.66091869 0-10.25 4.58908131-10.25 10.25 0 5.6609187 4.58908131 10.25 10.25 10.25h15c5.6609187 0 10.25-4.5890813 10.25-10.25 0-5.66091869-4.5890813-10.25-10.25-10.25z' fill='none' stroke='%23087cbf' stroke-width='1.5'/%3E%3C/svg%3E");
  text-decoration: none;
`

const Span = styled.span`
  font-family: 'Barlow-SemiBold';
  font-size: 14px;
  color: #4f4f4f;
`;

const Counter = ({ count, basePath, ...props }) => (
  <StyledLink to={basePath} {...props}>
    <Span>+{count}</Span>
  </StyledLink>
);

Counter.propTypes = {
  ...space.propTypes,
  count: PropTypes.number.isRequired,
  basePath: PropTypes.string.isRequired
};

Counter.defaultProps = {
  count: 0
};

export default Counter;
