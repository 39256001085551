import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { space } from 'styled-system';
import { likePost, unlikePost } from './actions';
import { connect } from 'react-redux';
import { loadingSelector } from 'modules/loading/selectors';

// prettier-ignore
const HeartButton = styled.button`
  ${space}
  color: #fff;
  width:  26px;
  height: 22px;
  border: none;
  outline: none;
  cursor: pointer;
  background: ${props => props.likedByUser ? 'url("/filled-heart-icon.svg")' : 'url("/heart-icon.svg")'} ;
  padding: 0;
`

const Heart = (props) => {
  const { likedByUser, likePost, likeactionpath, unlikePost } = props;

  const handleClick = () => {
    if (!likedByUser) handleLike();
    else handleUnlike();
  };

  const handleLike = () => {
    likePost(likeactionpath);
  };

  const handleUnlike = () => {
    unlikePost(likeactionpath);
  };

  return <HeartButton {...props} onClick={handleClick} />;
};

Heart.propTypes = {
  likePost: PropTypes.func.isRequired,
  unlikePost: PropTypes.func.isRequired,
  likedByUser: PropTypes.bool.isRequired,
  isNewsScreen: PropTypes.bool
};
Heart.defaultProps = {
  isNewsScreen: false
};

const mapStateToProps = (state) => ({
  likeLoading: loadingSelector('LIKE')(state),
  unlikeLoading: loadingSelector('UNLIKE')(state)
});

const mapDispatchToProps = (dispatch) => ({
  likePost: (likeactionpath) => dispatch(likePost(likeactionpath)),
  unlikePost: (likeactionpath) => dispatch(unlikePost(likeactionpath))
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(Heart);
