import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';
import theme from './theme';

// TODO: remove background-color (make context-sensitive on mobile)
export default createGlobalStyle`
  ${reset}

  html,
  body,
  #root {
    width: 100%;
    height: 100%;
  }

  html {
    background-color: ${theme.colors.background};
  }

  body {
    font-family: Barlow-Regular;
    -webkit-overflow-scrolling: touch;
    background-color: ${theme.colors.background};
  }

  a {
    color: #a7a7a7;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/* General sidebar styles */
.bm-menu {
  background: #fff;
  font-size: 1.15em;
}

.bm-menu-wrap {
  left: 0;
  top: 50px;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
  left: 0;
  top: 0;
}
`;
