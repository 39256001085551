import { many, attr, Model } from 'redux-orm';
import { FETCH_POST_SUCCESS } from 'pages/PostPage/constants';
import parseISO from 'date-fns/parseISO';

import PropTypes from 'prop-types';
import { LIKE_SUCCESS, UNLIKE_SUCCESS } from 'components/Heart/constants';
import { logLikePost } from 'utils/piwikpro';

class Post extends Model {
  static modelName = 'Post';

  static fields = {
    title: attr(),
    content: attr(),
    comments_count: attr(),
    created_at: attr(),
    likedByUser: attr(),
    showCommentsAndLikes: attr(),
    likesByUsers: many('User', 'likedPosts'),
    files: many('File', 'files'),
    order: attr(),
    likesCount: attr(),
    image_tablet_x3: attr()
  };

  static parse(data) {
    const { File, User } = this.session;

    const post = {
      id: data._id,
      title: data.title,
      content: data.content,
      comments_count: data.comments_count,
      created_at: parseISO(data.created_at),
      showCommentsAndLikes: data.show_comments_and_likes,
      likesByUsers: data.likes_users.map((user) => User.parse(user)),
      likedByUser: data.liked_by_user,
      files: (data.content.files || []).map((file) => File.parse(file)),
      order: data.order,
      likesCount: data.likes_count,
      image_tablet_x3: data.content.image_tablet_x3
    };

    if (data.content.files) {
      data.content.files.map((file) => File.parse(file));
    }

    return this.upsert(post);
  }

  static reducer(action, Post) {
    switch (action.type) {
      case FETCH_POST_SUCCESS:
        action.payload.data.id = action.payload.data._id;

        Post.parse(action.payload.data);

        break;
      case UNLIKE_SUCCESS:
      case LIKE_SUCCESS: {
        const { liked_by_user, post_id, likes_count } = action.payload.data;
        Post.withId(post_id).update({
          likedByUser: liked_by_user,
          likesCount: likes_count
        });
        // Send event to Analytics
        logLikePost(liked_by_user, Post.withId(post_id).title);
        break;
      }
      default:
        break;
    }
  }
}

export default Post;

export const PostProps = PropTypes.shape({
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  comments_count: PropTypes.number.isRequired,
  created_at: PropTypes.object.isRequired,
  likedByUser: PropTypes.bool,
  likesCount: PropTypes.number
});
