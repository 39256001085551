import React, { useState } from 'react';
import Profile from 'components/Profile';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import Dropdown, { MenuItem } from '@trendmicro/react-dropdown';
import '@trendmicro/react-buttons/dist/react-buttons.css';
import '@trendmicro/react-dropdown/dist/react-dropdown.css';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { loadingSelector } from 'modules/loading/selectors';
import { signOut } from 'modules/authentication/actions';
import { push } from 'connected-react-router';
import styled from 'styled-components';
import { phoneMax, tabletMin } from 'utils/media';

const CustomDropdownMenu = styled(Dropdown.Menu)`
  border: none !important;
  font-size: 15px !important;
  border-radius: 5px !important;
  margin-top: 4px !important;
  z-index: 2002;

  &:after {
    bottom: 100%;
    left: 10%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(136, 183, 213, 0);
    border-bottom-color: #fff;
    border-width: 5px;
    margin-left: -5px;
  }

  ${phoneMax`
    left: -90px !important;

    &:after {
      left: 70%;
    }
  `};

  ${tabletMin`
    left: -10px !important;

    &:after {
      left: 25%;
    }
  `};
`;
const CustomMenuItem = styled(MenuItem)`
  div {
    padding: 6px 10px !important;
    margin: 0 7px;
    border-radius: 5px;

    &:hover {
      background-color: rgba(8, 124, 191, 0.1) !important;
    }

    &:focus {
      background-color: rgba(8, 124, 191, 0.1) !important;
    }
  }

  a,
  div {
    color: #505050 !important;
  }

  a.active {
    color: ${({ theme }) => theme.colors.button.primary};
  }
`;

const ProfileDropdown = (props) => {
  const { push, user, signOut } = props;
  const [state, setState] = useState({ toggled: false });

  const clickLink = (path) => {
    setState({ ...state, toggled: false });
    push(path);
  };

  return (
    <Dropdown
      onToggle={(toggled) => setState({ ...state, toggled })}
      rootCloseEvent="mousedown"
      open={state.toggled}
    >
      <Dropdown.Toggle btnStyle="link" noCaret>
        <Profile.Large user={user} />
      </Dropdown.Toggle>
      <CustomDropdownMenu>
        <CustomMenuItem eventKey={1} onClick={() => clickLink('/profile')}>
          <Link to="/profile">Edit Profile</Link>
        </CustomMenuItem>
        <CustomMenuItem
          eventKey={2}
          onClick={() => clickLink('/change_password')}
        >
          <Link to="/change_password">Change Password</Link>
        </CustomMenuItem>
        <CustomMenuItem
          eventKey={3}
          onClick={() =>
            window.confirm('Are you sure you want to logout?') && signOut()
          }
        >
          Logout
        </CustomMenuItem>
      </CustomDropdownMenu>
    </Dropdown>
  );
};

const mapStateToProps = createStructuredSelector({
  loading: loadingSelector('CHANGE_USER')
});

const mapDispatchToProps = (dispatch) => ({
  signOut: () => dispatch(signOut()),
  push: (path) => dispatch(push(path))
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, withRouter)(ProfileDropdown);
