import React from 'react';
import ListItem from 'components/ListItem';
import ReactPlaceholder from 'react-placeholder';
import 'react-placeholder/lib/reactPlaceholder.css';
import { Flex, Box } from '@rebass/grid';
import HeartSVG from 'components/Likes/HeartSVG';
import styled from 'styled-components';
import { Wrapper } from 'components/CommunityListItem/CreatorTop';
import ListNewsItem from './FeedListNewsScreen/ListNewsItem';

const GreyHeartSVG = styled(HeartSVG)`
  margin-right: 7px;
  animation: react-placeholder-pulse 1.5s infinite;
  path {
    stroke: rgb(205, 205, 205);
    fill: rgb(205, 205, 205);
  }
`;
// eslint-disable-next-line no-unused-vars
const Content = ({ gridArea }) => (
  <div style={{ width: '100%' }}>
    <ReactPlaceholder type="text" showLoadingAnimation rows={1} ready={false}>
      .
    </ReactPlaceholder>
    <ReactPlaceholder
      type="textRow"
      showLoadingAnimation
      rows={1}
      ready={false}
      style={{ width: 40, height: 10 }}
    >
      .
    </ReactPlaceholder>
  </div>
);
// eslint-disable-next-line no-unused-vars
const NewsContent = ({ gridArea }) => (
  <div style={{ width: '100%' }}>
    <ReactPlaceholder
      type="textRow"
      showLoadingAnimation
      rows={1}
      ready={false}
      style={{ width: 40, height: 10, marginBottom: 10 }}
    >
      .
    </ReactPlaceholder>
    <ReactPlaceholder type="text" showLoadingAnimation rows={1} ready={false}>
      .
    </ReactPlaceholder>
  </div>
);

const Image = ({ imageType, imageWidth, imageHeight, gridArea }) => (
  <ReactPlaceholder
    type={imageType || 'round'}
    style={{
      width: imageWidth || 44,
      height: imageHeight || 44,
      gridArea,
      marginRight: 0
    }}
    showLoadingAnimation
    ready={false}
  >
    .
  </ReactPlaceholder>
);

const Social = ({ isNewsItem }) => (
  <Flex style={{ gridArea: 'likes', justifyContent: 'end' }}>
    <GreyHeartSVG />
    {!isNewsItem && (
      <>
        <ReactPlaceholder
          type="round"
          style={{
            width: 22,
            height: 22,
            gridArea: 'image',
            marginRight: 7
          }}
          showLoadingAnimation
          ready={false}
        >
          .
        </ReactPlaceholder>
        <ReactPlaceholder
          type="round"
          style={{
            width: 22,
            height: 22,
            gridArea: 'image'
          }}
          showLoadingAnimation
          ready={false}
        >
          .
        </ReactPlaceholder>
      </>
    )}
  </Flex>
);

export const NewsCardPlaceholder = ({ minHeight, isFirstItem, ...props }) => (
  <ListNewsItem minHeight={minHeight} isFirstItem={isFirstItem}>
    <Image
      {...props}
      gridArea="image"
      imageWidth={'100%'}
      imageHeight={isFirstItem ? '500px' : '300px'}
    />
    <NewsContent gridArea="content" />
    <Social gridArea="likes" isNewsItem style={{ justifyContent: 'end' }} />
  </ListNewsItem>
);

export const DefaultPlaceholder = ({ minHeight, ...props }) => (
  <ListItem minHeight={minHeight}>
    <Image {...props} />
    <Content />
  </ListItem>
);

export const CommunityCardPlaceholder = ({ minHeight, ...props }) => (
  <ListItem minHeight={minHeight} noPadding noGrid>
    <Flex flexDirection="column" style={{ width: '100%' }}>
      <Box>
        <Wrapper>
          <Flex alignitems="center">
            <div style={{ marginRight: 15 }}>
              <Image {...props} />
            </div>
            <Content />
          </Flex>
        </Wrapper>
      </Box>
      <Box p="15px" style={{ background: '#FAFAFA' }}>
        <ReactPlaceholder
          type="text"
          showLoadingAnimation
          rows={1}
          ready={false}
        >
          .
        </ReactPlaceholder>
        <Box mt="20px" style={{ textAlign: 'center' }}>
          <ReactPlaceholder
            type="rect"
            style={{
              width: '97%',
              height: 200
            }}
            showLoadingAnimation
            ready={false}
          >
            .
          </ReactPlaceholder>
          <Box mt="20px" style={{ textAlign: 'center' }}>
            <Social />
          </Box>
        </Box>
      </Box>
    </Flex>
  </ListItem>
);
