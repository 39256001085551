import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import PrimaryScreenWrapper from './PrimaryScreenWrapper';
import SecondaryScreenWrapper from './SecondaryScreenWrapper';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

const NewsScreen = (props) => {
  const {
    renderPrimaryPage,
    renderSecondaryPage,
    location,
    feed,
    history,
    changeSourceCallback
  } = props;
  const [showNewsList, setShowNewsList] = useState(true);

  useEffect(() => {
    setShowNewsList(!isPostPage());
  }, [location]);

  const isPostPage = () => {
    const pattern = new RegExp(`^\\/${feed.path.replace('/', '')}\\/\\d+$`);
    return pattern.test(location.pathname);
  };

  const handleBackButton = (e) => {
    e.preventDefault();
    changeSourceCallback('');
    history.push(feed.path);
  };

  return (
    <>
      {showNewsList && (
        <PrimaryScreenWrapper>{renderPrimaryPage(props)}</PrimaryScreenWrapper>
      )}
      {!showNewsList && (
        <SecondaryScreenWrapper
          handleBackButton={handleBackButton}
          feedName={feed.name}
          setShowNewsList={setShowNewsList}
        >
          {renderSecondaryPage(props)}
        </SecondaryScreenWrapper>
      )}
    </>
  );
};

NewsScreen.propTypes = {
  renderPrimaryPage: PropTypes.func.isRequired,
  renderSecondaryPage: PropTypes.func.isRequired,
  changeSourceCallback: PropTypes.func
};

export default compose(withRouter, connect(null))(NewsScreen);
