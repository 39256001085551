import React from 'react';
import { connect } from 'react-redux';
import { userByIdSelector } from './selectors';
import PropertiesCard from 'components/PropertiesCard';
import Page from 'components/Page';
import { UserProps } from 'models/User';
import PropTypes from 'prop-types';
import { fetchSingleContact } from '../../modules/single_contact/actions';
import { compose } from 'redux';
import { FETCH_CONTACT_SUCCESS } from '../../modules/single_contact/constants';
import { FeedProps } from 'models/Feed';
import { logViewMember } from 'utils/piwikpro';

class ContactPage extends React.Component {
  static propTypes = {
    user: UserProps,
    fetched_user: UserProps,
    feedId: PropTypes.number,
    userId: PropTypes.number,
    fetchSingleContact: PropTypes.func,
    feed: FeedProps
  };

  componentDidUpdate(prevProps) {
    const { feedId, userId, fetchSingleContact } = this.props;

    if (userId && userId !== prevProps.userId) {
      fetchSingleContact(feedId, userId).then((res) => {
        if (res.type !== FETCH_CONTACT_SUCCESS) return;

        // Send event to Analytics
        logViewMember(res.payload.data.name);
      });
    }
  }
  componentDidMount() {
    const { user, feedId, userId, fetchSingleContact } = this.props;

    if (!user) {
      fetchSingleContact(feedId, userId).then((res) => {
        if (res.type !== FETCH_CONTACT_SUCCESS) return;

        // Send event to Analytics
        logViewMember(res.payload.data.name);
      });
    }
  }

  render() {
    const { user, fetched_user, feed } = this.props;

    let resource;
    if (user) resource = user;
    else if (fetched_user) resource = fetched_user;
    else return null;

    // Store user name in properties to use in Analytics
    resource.properties['analyticsPayload'] = resource.name;

    return (
      <Page title="PROFILE" secondaryPage>
        <PropertiesCard
          properties={resource.properties}
          title={resource.first_name || resource.email}
          title2={`${resource.middle_name} ${resource.last_name}`}
          subtitle={resource.department}
          imageUrl={resource.pictureUrl_x3}
          location={resource.location}
          feed={feed}
        />
      </Page>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  user: userByIdSelector(state)(ownProps.userId),
  fetched_user: state.single_contact
});

const mapDispatchToProps = (dispatch) => ({
  fetchSingleContact: (feedId, userId) =>
    dispatch(fetchSingleContact(feedId, userId))
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(ContactPage);
