import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import CreatePostButton from 'components/CreatePostButton';
import LoadingProps from 'modules/loading/props';
import VisibilitySensor from 'react-visibility-sensor';
import { FeedProps } from 'models/Feed';
import paginationParser from 'modules/pagination';
import Loading from 'components/Loading';
import qs from 'query-string';
import Flash from 'components/Flash';
import { FeedEmptyPage } from 'pages/EmptyPage';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import { compose } from 'redux';
import SearchInput from 'components/SearchInput';
import styled from 'styled-components';
import { phoneMax } from 'utils/media';
import NewsItem from './NewsItem';

const HeaderListContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 30px;
  padding-bottom: 50px;
  gap: 50px;
  background-color: ${(props) => props.theme.colors.light};
  position: sticky;
  z-index: 1;
  top: 0;

  ${phoneMax`
    ${(props) =>
      props.$isNewsScreen &&
      `
      gap: 20px;
    `}
  `};
`;
export const Title = styled.h2`
  font-family: 'Barlow-SemiBold';
  font-size: ${(props) => props.theme.fonts.size.news_title};
  color: ${(props) => props.theme.colors.black};
  line-height: ${(props) => props.theme.fonts.line_height.news_title};
`;
const SearchInputContainer = styled.div`
  min-width: 250px;
  max-width: 600px;
  width: 420px;
  ${phoneMax`
    width: 100%;
  `};
`;
const FirstItemContainer = styled.div`
  width: 100%;
`;
const NewsListContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  gap: 72px 50px;
  justify-content: center;
  ${(props) =>
    props.$isSearchResult &&
    `
      margin-top: 45px;
  `}
  ${phoneMax`
    & { grid-template-columns: repeat(1, 1fr); }
  `};
`;
const NewsItemContainer = styled.div`
  display: grid;
  grid-template-areas:
    'image image'
    'content content'
    'likes comments copy';
  grid-template-columns: 1fr auto;
  width: 100%;
`;
const SearchText = styled.h3`
  font-family: 'Barlow-Regular';
  color: ${(props) => props.theme.colors.black};
  font-size: ${(props) => props.theme.fonts.size.news_search_result_text};
  line-height: ${(props) =>
    props.theme.fonts.line_height.news_search_result_text};
  margin-top: 30px;
`;
const BackToTopButton = styled.button`
  border: 0;
  background-color: ${(props) => props.theme.colors.light};
  &:hover,
  h3:hover {
    font-family: 'Barlow-SemiBold';
    cursor: pointer;
  }
`;
const ListContainer = styled.div`
  border-top: 1px solid ${(props) => props.theme.colors.dim_blue};
  padding-top: 40px;
`;
const List = (props) => {
  const {
    resources,
    searchCallback,
    feed,
    changeSourceCallback,
    history,
    loading,
    fetchPaginationCallback,
    searchable,
    createableUrl,
    renderPlaceholderListItem,
    location,
    currentSource,
    showFeatured
  } = props;
  const initialData = {
    firstPost: {
      loading: true,
      data: {}
    },
    remainPosts: []
  };

  const state = {
    page: 1,
    resources: resources
  };
  const pagination = paginationParser(feed);
  const [data, setData] = useState(initialData);
  const [searchState, setSearchState] = useState({
    searchTerm: '',
    isSearchResult: false
  });

  useEffect(() => {
    const term = qs.parse(window.location.search).search;
    setSearchState({
      searchTerm: term,
      isSearchResult: currentSource === 'search' && term !== ''
    });
  }, [resources, currentSource]);

  useEffect(() => {
    if (resources && resources.length !== 0) {
      searchState.isSearchResult
        ? setData({
            ...data,
            firstPost: initialData.firstPost,
            remainPosts: resources
          })
        : setData({
            ...data,
            firstPost: {
              loading: false,
              data: resources[0]
            },
            remainPosts: resources.slice(1)
          });
    } else setData(initialData);
  }, [resources, searchState]);

  useEffect(() => {
    if (state.searchTerm) searchCallback(state.searchTerm, feed.id);
  }, [state.searchTerm, feed.id]);

  const changeResourceScope = (e) => {
    const newSearchTerm = e.target.value;
    const currentSearchTerm = state.searchTerm;

    if (isEmpty(newSearchTerm) && !isEmpty(currentSearchTerm))
      changeSourceCallback('controller');
    //Switch back to results taken from pagination
    else searchCallback(newSearchTerm, feed.id);

    history.push({
      search: qs.stringify({ search: newSearchTerm })
    });
  };

  const handleLoadingVisibiliy = (isVisible) => {
    if (!isVisible) return;
    if (!loading.fulfilled || loading.pending) return;
    if (!pagination.hasMore) return;
    fetchPaginationCallback
      ? fetchPaginationCallback(pagination.currentPage + 1)
      : console.error('fetch pagination function missing');
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <>
      {location.state && location.state.message && (
        <Flash type={location.state.type}>{location.state.message}</Flash>
      )}
      {createableUrl && <CreatePostButton createableUrl={createableUrl} />}

      <HeaderListContainer>
        <Title>{feed.name}</Title>
        <SearchInputContainer>
          {searchable && (
            <SearchInput
              onChange={changeResourceScope}
              delayTimeout={1000}
              value={state.searchTerm}
              autoFocus
              $isNewsScreen
              feed={feed.type}
            />
          )}
        </SearchInputContainer>
      </HeaderListContainer>

      <ListContainer>
        {loading.fulfilled && state.resources.length === 0 && (
          <FeedEmptyPage topAjustment="20%;" />
        )}
        {!searchState.isSearchResult &&
          (loading.pending && state.resources.length === 0 ? (
            <FirstItemContainer key={1}>
              {renderPlaceholderListItem(true)}
            </FirstItemContainer>
          ) : (
            data.firstPost.data.id && (
              <FirstItemContainer>
                <NewsItem
                  key={data.firstPost.data.id}
                  post={data.firstPost.data}
                  feed={feed}
                  featured={
                    showFeatured && resources[0].id === data.firstPost.data.id
                  }
                  isFirstItem
                />
              </FirstItemContainer>
            )
          ))}
        {searchState.isSearchResult && resources && resources.length > 0 && (
          <>
            <SearchText>Search results ({resources.length})</SearchText>
          </>
        )}

        <NewsListContainer $isSearchResult={searchState.isSearchResult}>
          {loading.pending && state.resources.length === 0
            ? [1, 2, 3, 4, 5, 6, 7].map((i) => {
                return (
                  <NewsItemContainer key={i}>
                    {renderPlaceholderListItem(false)}
                  </NewsItemContainer>
                );
              })
            : data.remainPosts.map((post) => (
                <NewsItemContainer key={post.id}>
                  <NewsItem
                    post={post}
                    feed={feed}
                    featured={showFeatured && resources[0].id === post.id}
                  />
                </NewsItemContainer>
              ))}
        </NewsListContainer>
        {pagination.hasPagination &&
          !searchState.isSearchResult &&
          pagination.hasMore &&
          (loading.fulfilled || loading.pending) && (
            <VisibilitySensor onChange={handleLoadingVisibiliy}>
              <Loading />
            </VisibilitySensor>
          )}

        {searchState.isSearchResult &&
          !pagination.hasMore &&
          (loading.fulfilled || !loading.pending) && (
            <BackToTopButton onClick={scrollToTop}>
              <SearchText>Back to top</SearchText>
            </BackToTopButton>
          )}
      </ListContainer>
    </>
  );
};

List.propTypes = {
  resources: PropTypes.array.isRequired,
  searchable: PropTypes.bool,
  renderListItem: PropTypes.func.isRequired,
  searchCallback: PropTypes.func.isRequired,
  changeSourceCallback: PropTypes.func.isRequired,
  renderPlaceholderListItem: PropTypes.func.isRequired,
  searchKeys: PropTypes.arrayOf(PropTypes.string),
  createableUrl: PropTypes.string,
  currentSource: PropTypes.string,
  loading: LoadingProps,
  feed: FeedProps,
  fetchPaginationCallback: PropTypes.func
};

function mapStateToProps(state) {
  return { search_status: state.search.search_status };
}

const withConnect = connect(mapStateToProps);

export default compose(withRouter, withConnect)(List);
