import React from 'react';
import { Route } from 'react-router-dom';
import PostPage from 'pages/PostPage';
import LikesPage from 'pages/LikesPage';
import UserPage from 'pages/UserPage';
import DirectoryPage from 'pages/DirectoryPage';
import ContactPage from 'pages/ContactPage';
import VideoPage from 'pages/VideoPage';
import SingleLayout from '../components/SingleLayout';

const MobileLayout = ({ isMobile, children }) =>
  isMobile ? <SingleLayout>{children}</SingleLayout> : children;

export const NewsProfileRoute = (feed, isMobile) => (
  <Route
    key={`${feed.path}/:post_id/likes/:user_id`}
    path={`${feed.path}/:post_id/likes/:user_id`}
    render={({ match }) => (
      <MobileLayout isMobile={isMobile}>
        <UserPage
          postId={parseInt(match.params.post_id, 10)}
          userId={parseInt(match.params.user_id, 10)}
        />
      </MobileLayout>
    )}
  />
);

export const NewsLikesRoute = (feed, isMobile) => (
  <Route
    key={`${feed.path}/:post_id/likes`}
    path={`${feed.path}/:post_id/likes`}
    render={({ match }) => (
      <MobileLayout isMobile={isMobile}>
        <LikesPage postId={parseInt(match.params.post_id, 10)} />
      </MobileLayout>
    )}
  />
);

export const NewsPostRoute = (feed, isMobile) => (
  <Route
    key={`${feed.path}/:post_id`}
    path={`${feed.path}/:post_id`}
    render={({ match }) => {
      return (
        <MobileLayout isMobile={isMobile}>
          <PostPage feed={feed} postId={parseInt(match.params.post_id, 10)} />
        </MobileLayout>
      );
    }}
  />
);

export const DirectoryRoute = (feed, isMobile) => (
  <Route
    key={`${feed.path}/:id`}
    path={`${feed.path}/:id`}
    render={({ match }) => {
      return (
        <MobileLayout isMobile={isMobile}>
          <DirectoryPage
            title="DETAILS"
            postId={parseInt(match.params.id, 10)}
            feed={feed}
          />
        </MobileLayout>
      );
    }}
  />
);

export const ContactRoute = (feed, isMobile) => (
  <Route
    key={`${feed.path}/:id`}
    path={`${feed.path}/:id`}
    render={({ match }) => {
      return (
        <MobileLayout isMobile={isMobile}>
          <ContactPage
            feedId={feed.id}
            userId={parseInt(match.params.id, 10)}
            feed={feed}
          />
        </MobileLayout>
      );
    }}
  />
);

export const VideoRoute = (feed, isMobile) => (
  <Route
    key={`${feed.path}/:post_id`}
    path={`${feed.path}/:post_id`}
    render={({ match }) => {
      return (
        <MobileLayout isMobile={isMobile}>
          <VideoPage feedId={feed.id} postId={Number(match.params.post_id)} />
        </MobileLayout>
      );
    }}
  />
);
