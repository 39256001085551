import React from 'react';
import { Box } from '@rebass/grid';
import { TextField } from '../../../components/Input';
import Button from '../../../components/Button';
import { Link } from 'react-router-dom';
import LoadingProps from '../../../modules/loading/props';
import Flash from '../../../components/Flash';
import { createStructuredSelector } from 'reselect';
import { loadingSelector } from '../../../modules/loading/selectors';
import { signInPassword } from '../../../modules/authentication/actions';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import FormBox from '../../../components/FormBox';
import theme from '../../../styles/theme';

class PasswordForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { password: '' };
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(e) {
    e.preventDefault();

    const { email } = this.props;
    const { password } = this.state;

    if (!email || !password) return;
    this.props.signIn(email, password);
  }

  render() {
    const {
      email,
      isMobile,
      loading: { rejected, reject_reason, pending, fulfilled }
    } = this.props;
    const { password } = this.state;

    return (
      <FormBox
        isMobile={isMobile}
        isSmall={false}
        icon={
          <svg width="120px" height="120px" viewBox="0 0 120 120" version="1.1">
            <title>icon_login_password</title>
            <g
              id="icon_login_password"
              stroke="none"
              strokeWidth="1"
              fill="none"
              fillRule="evenodd"
            >
              <g id="Group" transform="translate(1.000000, 22.000000)">
                <g id="Group-18">
                  <path
                    d="M0,24 L18,24 C22.6391919,24 26.4,27.7608081 26.4,32.4 L26.4,60 C26.4,64.6391919 22.6391919,68.4 18,68.4 L0,68.4 L0,68.4 L0,24 Z"
                    id="Rectangle"
                    fill="#FFFFFF"
                    transform="translate(13.200000, 46.200000) scale(-1, 1) translate(-13.200000, -46.200000) "
                  />
                  <path
                    d="M43.2,24 L43.2,16.7969276 C43.2,7.52024062 35.6797594,0 26.4030724,0 C26.4030724,2.46831402 26.4030724,2.46831402 26.4,4.8 C33.027417,4.8 38.4,10.172583 38.4,16.8 L38.4,24 L43.2,24 Z"
                    id="Rectangle-Copy-14"
                    fill="#E5EFFA"
                  />
                  <path
                    d="M26.4,24 L26.4,16.7969276 C26.4,7.52024062 18.8797594,0 9.60307244,0 C9.60307244,2.46831402 9.60307244,2.46831402 9.6,4.8 C16.227417,4.8 21.6,10.172583 21.6,16.8 L21.6,24 L26.4,24 Z"
                    id="Rectangle-Copy-14"
                    fill="#FFFFFF"
                    transform="translate(18.000000, 12.000000) scale(-1, 1) translate(-18.000000, -12.000000) "
                  />
                  <path
                    d="M43.2,24 L43.2,16.7969276 C43.2,7.52024062 35.6797594,0 26.4030724,0 C17.1216162,0 9.6,7.5216162 9.6,16.8 L9.6,24 L14.4,24 L14.4,16.8 C14.4,10.172583 19.772583,4.8 26.4,4.8 C33.027417,4.8 38.4,10.172583 38.4,16.8 L38.4,24 L43.2,24 Z"
                    id="Rectangle"
                    stroke="#0E7EBE"
                    strokeWidth="2"
                    strokeLinecap="square"
                  />
                </g>
                <g id="Group-14" transform="translate(0.000000, 24.000000)">
                  <path
                    d="M26.4,0 L44.4,0 C49.0391919,-2.62856257e-15 52.8,3.7608081 52.8,8.4 L52.8,36 C52.8,40.6391919 49.0391919,44.4 44.4,44.4 L26.4,44.4 L26.4,44.4 L26.4,0 Z"
                    id="Rectangle"
                    fill="#E5EFFA"
                  />
                  <rect
                    id="Rectangle"
                    stroke="#0E7EBE"
                    strokeWidth="2"
                    strokeLinejoin="round"
                    x="0"
                    y="0"
                    width="52.8"
                    height="44.4"
                    rx="8.4"
                  />
                </g>
                <path
                  d="M26.4,38.4 C29.0509668,38.4 31.2,40.5490332 31.2,43.2 C31.2,44.9766143 30.2347921,46.5277922 28.8001535,47.3577567 L28.8,51.6 C28.8,52.9254834 27.7254834,54 26.4,54 C25.0745166,54 24,52.9254834 24,51.6 L23.9998465,47.3577567 C22.5652079,46.5277922 21.6,44.9766143 21.6,43.2 C21.6,40.5490332 23.7490332,38.4 26.4,38.4 Z"
                  id="Combined-Shape"
                  stroke="#0E7EBE"
                  strokeWidth="2"
                  fill="#FFFFFF"
                />
                <g
                  id="Group-7"
                  transform="translate(59.290782, 43.664850) rotate(-125.000000) translate(-59.290782, -43.664850) translate(30.790782, 28.164850)"
                >
                  <path
                    d="M0.0150440654,14.2384681 C1.02568507,6.3507343 7.52058772,0.26593569 15.3821513,0.26593569 C20.9046371,0.26593569 25.7527313,3.26853628 28.4991143,7.78947794 L51.9227074,7.78963401 L56.0629392,14.2385183 L0.0150440654,14.2384681 Z"
                    id="Combined-Shape"
                    fill="#FFFFFF"
                  />
                  <g id="Group-4" transform="translate(0.000000, -0.000000)">
                    <path
                      d="M54.6720351,12.6968895 L56.4339849,15.4640372 L51.3737133,20.9363915 L48.1951382,20.9369374 L45.0185085,24.0921783 L41.8336455,20.9369374 L38.6663648,24.0921783 L35.5109913,20.9369374 L32.3209995,24.0921783 L29.1550296,20.9369374 L28.6139207,21.4717512 C26.265993,26.682271 21.0426871,30.3075775 14.976004,30.3075775 C6.71328902,30.3075775 0.0150350545,23.5826491 0.0150350545,15.2870295 C0.0150350545,14.4037696 0.0909698852,13.5383158 0.23664289,12.6968895 L54.6720351,12.6968895 Z"
                      id="Combined-Shape"
                      fill="#E5EFFA"
                    />
                    <path
                      d="M14.976004,0.26593569 C20.8275296,0.26593569 25.8944387,3.63865604 28.3533507,8.55345481 L51.3770276,8.55313456 L56.4339849,16.0027501 L51.3737133,21.1335941 L48.1951382,21.1335941 L45.0185085,24.0916324 L41.8336455,21.1335941 L38.6663648,24.0916324 L35.5109913,21.1335941 L32.3209995,24.0916324 L29.1550296,21.1335941 L28.4803768,21.7593667 C26.0720229,26.8149053 20.9302108,30.3070316 14.976004,30.3070316 C6.71328902,30.3070316 0.0150350545,23.5821032 0.0150350545,15.2864836 C0.0150350545,6.99086407 6.71328902,0.26593569 14.976004,0.26593569 Z"
                      id="Combined-Shape"
                      stroke="#0E7EBE"
                      strokeWidth="2"
                      strokeLinejoin="round"
                    />
                    <ellipse
                      id="Oval"
                      stroke="#0E7EBE"
                      strokeWidth="2"
                      fill="#FFFFFF"
                      cx="9.30115373"
                      cy="15.8044336"
                      rx="3.12716385"
                      ry="3.14359943"
                    />
                    <line
                      x1="54.1840606"
                      y1="12.696734"
                      x2="30.4528685"
                      y2="12.696734"
                      id="Line-6"
                      stroke="#0E7EBE"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                  </g>
                </g>
                <g
                  id="Group-2"
                  transform="translate(94.500000, 59.000000) scale(1, -1) rotate(-90.000000) translate(-94.500000, -59.000000) translate(84.000000, 35.000000)"
                >
                  <path
                    d="M2.56392077,14.187819 L10.5096141,9.75480705 L10.5096141,9.75480705 L10.5000013,15.617446 C10.5000004,15.6179925 10.5,15.6185391 10.5,15.6190856 L10.5,47.754807 L10.5,47.754807 L5,47.754807 C2.23857625,47.754807 -5.37144649e-14,45.5162308 -5.68434189e-14,42.754807 L-5.68434189e-14,18.5542269 C-5.52890431e-14,16.7416139 0.980999215,15.0709528 2.56392077,14.187819 Z"
                    id="Rectangle-Copy-7"
                    fill="#E5EFFA"
                  />
                  <path
                    d="M12.5639208,14.187819 L20.5096141,9.75480705 L20.5096141,9.75480705 L20.5000013,15.617446 C20.5000004,15.6179925 20.5,15.6185391 20.5,15.6190856 L20.5,47.754807 L20.5,47.754807 L15,47.754807 C12.2385763,47.754807 10,45.5162308 10,42.754807 L10,18.5542269 C10,16.7416139 10.9809992,15.0709528 12.5639208,14.187819 Z"
                    id="Rectangle-Copy-7"
                    fill="#FFFFFF"
                    transform="translate(15.254807, 28.754807) scale(-1, 1) translate(-15.254807, -28.754807) "
                  />
                  <path
                    d="M2.56392077,13.8980107 L8.07130276,10.8253663 C9.58670767,9.97990097 11.4321188,9.98062025 12.9468642,10.8272666 L18.4394822,13.8972909 C20.020497,14.7809776 21,16.4505806 21,18.2617984 L21,42.4649987 C21,45.2264225 18.7614237,47.4649987 16,47.4649987 L5,47.4649987 C2.23857625,47.4649987 1.2263553e-15,45.2264225 0,42.4649987 L0,18.2644186 C-1.91512137e-15,16.4518056 0.980999215,14.7811445 2.56392077,13.8980107 Z"
                    id="Rectangle-Copy-7"
                    stroke="#087CBF"
                    strokeWidth="2"
                    strokeLinejoin="round"
                  />
                  <ellipse
                    id="Oval-Copy-21"
                    stroke="#0E7EBE"
                    strokeWidth="2"
                    fill="#FFFFFF"
                    cx="10.6271639"
                    cy="21.1435994"
                    rx="3.12716385"
                    ry="3.14359943"
                  />
                  <path
                    d="M8,1.03924976 C8.62415444,0.403538546 9.55614144,0 10.5973595,0 C12.476586,0 14,1.31449773 14,2.93601285 L14,18.0639872 C14,19.6855023 12.476586,21 10.5973595,21 C9.78689124,21 9.04260419,20.7555029 8.45816082,20.3473266"
                    id="Shape"
                    stroke="#087CBF"
                    strokeWidth="2"
                  />
                </g>
              </g>
            </g>
          </svg>
        }
        title="Log in using your password"
        subtitleLine1="Type in your password below to log in to your"
        subtitleLine2="imCORE account."
      >
        <form
          onSubmit={this.onSubmit}
          style={{
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            alignSelf: 'center',
            alignItems: 'center'
          }}
        >
          {rejected && (reject_reason || {}).status === 401 && (
            <Flash type="error">Invalid email or password.</Flash>
          )}
          <TextField type="hidden" name="email" required value={email} />
          <TextField
            type="password"
            name="password"
            placeholder="Password"
            value={password}
            onChange={(e) => this.setState({ password: e.target.value })}
            required
            style={{
              width: '355px',
              borderRadius: '5px',
              border: 'solid 1px #e9e9e9',
              backgroundColor: '#f7f7f7',
              alignSelf: 'center',
              fontFamily: 'Barlow-Regular'
            }}
          />
          <Box
            mt={'30px'}
            style={{
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <Link
              style={{
                width: '166px',
                color: theme.colors.contrast,
                fontSize: '16px',
                lineHeight: '1.13',
                alignSelf: 'center'
              }}
              to={{
                pathname: '/password/reset',
                state: {
                  email: email
                }
              }}
            >
              Forgot password?
            </Link>
            <Button
              loading={pending || fulfilled}
              style={{
                width: '166px',
                alignSelf: 'center'
              }}
            >
              Login
            </Button>
          </Box>
        </form>
      </FormBox>
    );
  }
}

PasswordForm.propTypes = {
  email: PropTypes.string,
  loading: LoadingProps
};

const mapStateToProps = createStructuredSelector({
  loading: loadingSelector('SIGN_IN')
});

const mapDispatchToProps = (dispatch) => ({
  signIn: (email, password) => dispatch(signInPassword(email, password))
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(PasswordForm);
