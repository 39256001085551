import React, { Component } from 'react';
import qs from 'query-string';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import withSizes from 'utils/sizes';
import AuthLayout from '../AuthLayout';
import FormBox from 'components/FormBox';
import LoadingProps from '../../../modules/loading/props';
import { createStructuredSelector } from 'reselect';
import { loadingSelector } from '../../../modules/loading/selectors';
import { submitNewPassword } from '../../../modules/authentication/actions';
import { connect } from 'react-redux';
import { Box } from '@rebass/grid';
import { TextField } from '../../../components/Input';
import Button from '../../../components/Button';
import Flash from '../../../components/Flash';

const SUCCESS_MESSAGE = 'Passwords match';

const SubtitleStyle = {
  width: '100%',
  height: '18px',
  fontFamily: 'Barlow-SemiBold',
  fontSize: '15px',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: '1.2',
  letterSpacing: 'normal',
  textAlign: 'center',
  color: '#a1a1a1',
  alignSelf: 'center'
};

class NewPasswordPage extends Component {
  static propTypes = {
    isMobile: PropTypes.bool
  };

  constructor(props) {
    super(props);

    let params = qs.parse(this.props.location.search);

    this.state = {
      id: params.id,
      token: params.token,
      new_password: '',
      new_password_verification: ''
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  getMessage(new_password, new_password_verification, rejected, reject_reason) {
    let offences = [];

    if (rejected) {
      return reject_reason?.response?.data?.message;
    }
    if (new_password.length < 8) offences.push('8 digits');
    if (!/\d/.test(new_password)) offences.push('one number');
    if (!/[a-z]/.test(new_password)) offences.push('one lowercase letter');
    if (!/[A-Z]/.test(new_password)) offences.push('one capital letter');

    return offences.length === 0 && new_password === new_password_verification
      ? SUCCESS_MESSAGE
      : offences.length === 0
        ? 'OK'
        : 'Min. ' + offences.join(', ');
  }

  onSubmit(e) {
    e.preventDefault();

    const { submitNewPassword } = this.props;
    const { id, token, new_password, new_password_verification } = this.state;

    submitNewPassword(id, token, new_password, new_password_verification);
  }

  render() {
    const {
      loading: { rejected, reject_reason, pending, fulfilled },
      isMobile
    } = this.props;
    const { new_password, new_password_verification } = this.state;
    const currentMessage = this.getMessage(
      new_password,
      new_password_verification,
      rejected,
      reject_reason
    );
    const validPasswords = currentMessage === SUCCESS_MESSAGE;

    return (
      <AuthLayout>
        <FormBox
          isMobile={isMobile}
          isSmall={false}
          icon={
            <svg width="0px" height="45px" viewBox="0 0 0 0" version="1.1" />
          }
          title="Create a new password"
          subtitleLine1={currentMessage}
          subtitleStyle={SubtitleStyle}
        >
          <form
            onSubmit={this.onSubmit}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignSelf: 'stretch',
              alignItems: 'stretch'
            }}
          >
            {rejected && (reject_reason || {}).status === 400 && (
              <Flash type="error">
                {reject_reason?.response?.error ||
                  'An error occurred. Please try again.'}
              </Flash>
            )}
            <Box mb="16px">
              <TextField
                type="password"
                name="new_password"
                placeholder="New password"
                value={new_password}
                onChange={(e) =>
                  this.setState({ new_password: e.target.value })
                }
                required
              />
            </Box>
            <Box mb="30px">
              <TextField
                mt="16px"
                type="password"
                name="new_password_verification"
                placeholder="Confirm new password"
                value={new_password_verification}
                onChange={(e) =>
                  this.setState({ new_password_verification: e.target.value })
                }
                required
              />
            </Box>
            <Button
              style={{
                width: '163px',
                alignSelf: 'center'
              }}
              disabled={!validPasswords}
              loading={pending || fulfilled}
            >
              Save password
            </Button>
          </form>
        </FormBox>
      </AuthLayout>
    );
  }
}

NewPasswordPage.propTypes = {
  loading: LoadingProps
};

const mapStateToProps = createStructuredSelector({
  loading: loadingSelector('NEW_PASSWORD')
});

const mapDispatchToProps = (dispatch) => ({
  submitNewPassword: (id, token, new_password, new_password_verification) =>
    dispatch(
      submitNewPassword(id, token, new_password, new_password_verification)
    )
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, withSizes)(NewPasswordPage);
