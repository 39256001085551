import { many, attr, Model } from 'redux-orm';
import { FETCH_NEWS_FEED_POSTS_SUCCESS } from 'screens/NewsFeedScreen/constants';
import { FETCH_DIRECTORY_FEED_POSTS_SUCCESS } from 'screens/DirectoryFeedScreen/constants';
import { FETCH_CONTACTS_SUCCESS } from 'screens/ContactsFeedScreen/constants';
import { FETCH_COMMUNITY_FEED_POSTS_SUCCESS } from 'screens/CommunityFeedScreen/constants';
import { FETCH_NAVIGATION_ITEMS_SUCCESS } from 'components/App/constants';
import {
  SEARCH_FEEDS_SUCCESS,
  SEARCH_CONTACTS_SUCCESS
} from 'modules/search/constants';
import PropTypes from 'prop-types';

const getPathFromName = (name) => `/${name.replace(/ /g, '_').toLowerCase()}`;

class Feed extends Model {
  static modelName = 'Feed';

  static fields = {
    name: attr(),
    path: attr(),
    type: attr(),
    style: attr(),
    posts: many('Post', 'feeds'),
    search_posts: many('Post', 'search_posts'),
    contacts: many('Contact', 'feeds'),
    search_contacts: many('Contact', 'search_contacts'),
    meta: attr()
  };

  static parse(data) {
    return this.upsert({
      id: data._id,
      name: data.name,
      path: data.name && getPathFromName(data.name),
      type: data.feed_type,
      style: data.feed_style,
      meta: data.meta
    });
  }

  static reducer(action, Feed, session) {
    switch (action.type) {
      case FETCH_NAVIGATION_ITEMS_SUCCESS:
        action.payload.data.map((feed) => this.parse(feed));
        break;
      case FETCH_CONTACTS_SUCCESS: {
        const contact_meta = action.meta;
        const contact_payload = action.payload;
        const contact_feed = Feed.withId(contact_meta.feedId);
        contact_payload.data.forEach((contact, i) => {
          contact.order = (contact_meta.page || 1) * 1000 + i;
          session.Contact.parse(contact);
          if (!contact_feed.contacts.filter({ id: contact._id }).exists()) {
            contact_feed.contacts.add(contact._id);
          }
        });
        contact_feed.update({
          meta: contact_payload.meta
        });
        break;
      }
      case SEARCH_CONTACTS_SUCCESS: {
        const searchContactsMeta = action.meta;
        const searchContactsPayload = action.payload;
        const searchContact = Feed.withId(searchContactsMeta.feedId);
        searchContact.search_contacts.clear();
        searchContactsPayload.data.forEach((contact, i) => {
          contact.order = (searchContactsMeta.page || 1) * 1000 + i;
          session.Contact.parse(contact);
          if (
            !searchContact.search_contacts.filter({ id: contact._id }).exists()
          ) {
            searchContact.search_contacts.add(contact._id);
          }
        });
        break;
      }
      case SEARCH_FEEDS_SUCCESS: {
        const searchFeedsMeta = action.meta;
        const searchFeedsPayload = action.payload;
        const searchFeed = Feed.withId(searchFeedsMeta.feedId);
        searchFeed.search_posts.clear();
        searchFeedsPayload.data.forEach((post, i) => {
          post.order = (searchFeedsMeta.page || 1) * 1000 + i;
          session.Post.parse(post);
          if (!searchFeed.search_posts.filter({ id: post._id }).exists()) {
            searchFeed.search_posts.add(post._id);
          }
        });
        break;
      }
      case FETCH_NEWS_FEED_POSTS_SUCCESS:
      case FETCH_DIRECTORY_FEED_POSTS_SUCCESS:
      case FETCH_COMMUNITY_FEED_POSTS_SUCCESS: {
        const { meta, payload } = action;
        const feed = Feed.withId(meta.feedId);
        payload.data.forEach((post, i) => {
          post.order = (meta.page || 1) * 1000 + i;
          session.Post.parse(post);
          if (!feed.posts.filter({ id: post._id }).exists()) {
            feed.posts.add(post._id);
          }
        });
        feed.update({
          meta: payload.meta
        });
        break;
      }
      default:
        break;
    }
  }
}

export default Feed;

export const FeedProps = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  style: PropTypes.string
});
