import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { fetchPosts } from './actions';
import { feedByIdSelector } from '../../selectors';
import { loadingSelector } from 'modules/loading/selectors';

import DirectoryFeedItem from 'components/DirectoryFeedItem';
import { DefaultPlaceholder } from 'components/CardPlaceholder';
import FeedListSplitScreen from 'components/FeedListSplitScreen';
import LoadingProps from 'modules/loading/props';
import { PostEmptyPage } from 'pages/EmptyPage';
import { clearSearch, searchFeed } from '../../modules/search/actions';
import styled from 'styled-components';
import { logUserSearchedFeed } from 'utils/piwikpro';

const Section = styled.div`
  margin-top: 5px;
  margin-bottom: 15px;
  width: 100%;
  height: 17px;
  opacity: 0.5;
  font-family: 'Barlow-SemiBold';
  font-size: 17px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4f4f4f;
`;

let latestValue = null;

class DirectoryFeedScreen extends Component {
  static propTypes = {
    feed: PropTypes.shape({
      id: PropTypes.number.isRequired,
      type: PropTypes.string
    }),
    feedId: PropTypes.number,
    fetchPosts: PropTypes.func.isRequired,
    searchFeed: PropTypes.func.isRequired,
    loading: LoadingProps
  };

  constructor() {
    super();
    this.state = { source: '' };
  }
  componentDidMount() {
    const { feedId, fetchPosts } = this.props;
    fetchPosts(feedId);
  }
  getResultsFromSource = (source) => {
    this.setState({ source: source });
  };
  render() {
    const { feed, loading, searchFeed } = this.props;
    const resources =
      this.state.source === 'search'
        ? feed.search_posts.toModelArray()
        : feed.posts.toModelArray();

    const groupingPropertySchemaId = feed?.meta?.grouping_property_schema_id;
    const largeImage = feed?.meta?.style === 'carousel';
    let postsWithValue = [];
    if (groupingPropertySchemaId) {
      let mappedPosts = resources.reduce((acc, post) => {
        const property = post?.content?.properties?.find(
          (property) => property.property_schema_id === groupingPropertySchemaId
        );

        if (property) {
          // Group initialization
          if (!acc[property.value]) {
            acc[property.value] = [];
          }

          // Grouping
          acc[property.value].push(post);
        }

        return acc;
      }, {});

      postsWithValue = Object.keys(mappedPosts).flatMap((key) => {
        return mappedPosts[key].map((post) => {
          return {
            id: post.id,
            value: key,
            post: post
          };
        });
      });
    }

    return (
      <FeedListSplitScreen
        feedLoading={loading}
        searchable
        searchCallback={(term, feedId) => {
          // Send event to Analytics
          logUserSearchedFeed(term, feed.name);

          this.getResultsFromSource('search');
          searchFeed(feedId, term);
        }}
        currentSource={this.state.source}
        changeSourceCallback={this.getResultsFromSource}
        renderListItem={(postWithValue, { match }, searchTerm) => {
          const newSectionFlag = postWithValue.value !== latestValue;
          latestValue = postWithValue.value;

          return (
            <React.Fragment>
              {!searchTerm && newSectionFlag && (
                <Section>{postWithValue.value.toUpperCase()}</Section>
              )}
              <DirectoryFeedItem
                post={postWithValue.post}
                url={match.url}
                key={postWithValue.post.id}
                searchTerm={searchTerm}
                largeImage={largeImage}
                feedType={feed.type}
              />
            </React.Fragment>
          );
        }}
        renderPlaceholderListItem={() => (
          <DefaultPlaceholder minHeight="39px" imageType="round" />
        )}
        feed={feed}
        resources={postsWithValue}
        emptyPageComponent={PostEmptyPage}
        fetchPaginationCallback={(page) => {
          fetchPosts(feed.id, page);
        }}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  feed: feedByIdSelector(state)(ownProps.feedId),
  loading: loadingSelector('DIRECTORY_FEED_POSTS')(state)
});

const mapDispatchToProps = (dispatch) => ({
  fetchPosts: (feedId, page) => dispatch(fetchPosts(feedId, page)),
  searchFeed: (feedId, query) =>
    dispatch(searchFeed(feedId, query)).then(() => {
      dispatch(clearSearch());
    })
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withRouter, withConnect)(DirectoryFeedScreen);
