import { createSelector } from 'reselect';
import memoize from 'lodash.memoize';

const createDynamicOrmSelector = (orm, ...selectors) =>
  createSelector(...selectors.slice(0, -1), (state, ...values) =>
    memoize((...args) =>
      selectors[selectors.length - 1](orm.session(state), ...values, ...args)
    )
  );

// const createDynamicOrmSelector = (orm, ...selectors) =>
//   createSelector(orm, ...selectors.slice(0, -1), (session) =>
//     memoize(...args => selectors[selectors.length - 1](session, ...args))
//   )

export default createDynamicOrmSelector;
