import React from 'react';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';
import styled from 'styled-components';
import { tabletQueryMin, phoneMax } from 'utils/media';
import { UserProps } from 'models/User';
import { Img } from 'react-image';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
`;
const Image = styled(Img)`
  width: 50px;
  height: 50px;
  min-width: 50px;
  min-height: 50px;
  border-radius: 50%;
  object-fit: cover;

  ${phoneMax`
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;  
  `};
`;
const Span = styled.span`
  margin-left: 15px;
  font-size: ${(props) => props.theme.fonts.size.profile};
  font-family: 'Barlow-Bold';
  font-weight: 400;
  color: ${({ theme }) => theme.colors.contrast};
`;

const Large = ({ user, children }) => {
  return (
    <Container>
      <Image src={user.pictureUrl_x3} />
      <MediaQuery query={tabletQueryMin}>
        <Span>{user.name}</Span>
      </MediaQuery>
      {children}
    </Container>
  );
};

Large.propTypes = {
  user: UserProps.isRequired,
  subtitle: PropTypes.string
};

export default Large;
