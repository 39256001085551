import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import ReactRouterPropTypes from 'react-router-prop-types';
import { PostProps } from 'models/Post';
import { FeedProps } from 'models/Feed';
import Profile from 'components/Profile';
import Heart from 'components/Heart';
import Counter from './Counter';
import ContainerDimensions from 'react-container-dimensions';
import PropTypes from 'prop-types';

const OUTER_WIDTH = 36 + 40; // heart + counter
const PROFILE_WIDTH = 22 + 7; // width + margin

const StyledLikes = styled.ul`
  display: flex;
  grid-area: likes;
  align-items: center;
  ${(props) => props.$isNewsScreen && 'justify-content: end;'}
`;
const Span = styled.span`
  font-family: Barlow-Regular;
  font-size: ${(props) => props.theme.fonts.size.news_item_likes};
  color: ${(props) => props.theme.colors.black};
  margin-right: 2px;
`;

const getProfileCount = (width) =>
  Math.max(0, Math.min(5, Math.round(width / PROFILE_WIDTH) - 1));

const getTruncatedUsers = (users, count) =>
  users.length - count > 1 ? users.slice(0, count) : users;

const Likes = (props) => {
  const { post, match, feed, isNewsScreen } = props;

  const basePath = `${match.url}/${post.id}/likes`;
  const likeactionpath = `/api/v1/feeds/${feed.id}/posts/${post.id}/like`;
  const getUsers = () => {
    return post ? post.likesByUsers.toModelArray() : [];
  };

  return (
    <ContainerDimensions style={{ position: 'unset' }}>
      {({ width }) => {
        const innerWidth = width - OUTER_WIDTH;
        const profileCount = getProfileCount(innerWidth);
        const truncatedUsers = getTruncatedUsers(getUsers(), profileCount);
        const remainingUserCount = getUsers().length - truncatedUsers.length;

        return (
          <StyledLikes $isNewsScreen={isNewsScreen}>
            {isNewsScreen && (
              <>
                {post && post.likesCount && (
                  <li>
                    <Span>{post.likesCount}</Span>
                  </li>
                )}
                <li>
                  <Heart
                    likeactionpath={likeactionpath}
                    likedByUser={post.likedByUser}
                    isNewsScreen
                  />
                </li>
              </>
            )}
            {!isNewsScreen && (
              <Heart
                mr="5px"
                likeactionpath={likeactionpath}
                likedByUser={post.likedByUser}
              />
            )}
            <li></li>
            {!isNewsScreen &&
              truncatedUsers.map((user) => (
                <li key={user.id} style={{ marginRight: 5 }}>
                  <Profile.Small user={user} basePath={basePath} />
                </li>
              ))}
            {!isNewsScreen && remainingUserCount > 0 && (
              <li>
                <Counter
                  ml="3px"
                  count={remainingUserCount}
                  basePath={basePath}
                />
              </li>
            )}
          </StyledLikes>
        );
      }}
    </ContainerDimensions>
  );
};

Likes.propTypes = {
  post: PostProps.isRequired,
  feed: FeedProps.isRequired,
  match: ReactRouterPropTypes.match,
  isNewsScreen: PropTypes.bool
};
Likes.defaultProps = {
  isNewsScreen: false
};

export default compose(withRouter)(Likes);
