import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// prettier-ignore
const StyledSecondaryScreen = styled.main`
  grid-area: secondary;
  background-color: ${props => props.theme.colors.background};
  width: 100%;
`

const SecondaryScreen = ({ children }) => (
  <StyledSecondaryScreen>{children}</StyledSecondaryScreen>
);

SecondaryScreen.propTypes = {
  children: PropTypes.node.isRequired
};

export default SecondaryScreen;
