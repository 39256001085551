import createDynamicOrmSelector from 'utils/createDynamicOrmSelector';
import orm, { selector as entitiesSelector } from 'models';

export const contactsSelector = createDynamicOrmSelector(
  orm,
  entitiesSelector,
  (session, feedId) => {
    return session.Contact.all()
      .toModelArray()
      .filter((contact) => contact.feed_id === feedId);
  }
);
