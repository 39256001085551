import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { resetPassword } from 'modules/authentication/actions';
import PropTypes from 'prop-types';
import withSizes from 'utils/sizes';
import AuthLayout from '../AuthLayout';
import FormBox from 'components/FormBox';
import { createStructuredSelector } from 'reselect';
import { loadingSelector } from 'modules/loading/selectors';
import { clearLoading } from 'modules/loading/actions';
import { push } from 'connected-react-router';
import Button from 'components/Button';
import { TextField } from '../../../components/Input';
import checkmark from '../../../assets/checkmark.json';
import { Box } from '@rebass/grid';
import Lottie from 'lottie-react';

const ResetPasswordPage = (props) => {
  const { isMobile, resetPassword, location } = props;
  const [state, setState] = useState({
    email: '',
    emailSent: false,
    isAnimationStopped: true
  });

  useEffect(() => {
    if (!location.state) return;
    const { email } = location.state;
    if (email) setState({ email: email });
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    resetPassword(state.email);
    setState({ ...state, emailSent: true });
  };

  const resendEmail = (e) => {
    e.preventDefault();
    resetPassword(state.email);
    setState({ ...state, isAnimationStopped: false });
  };

  const isValidEmail = new RegExp(
    /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/i
  ).test(state.email);

  return (
    <AuthLayout>
      {state.emailSent ? (
        <FormBox
          isMobile={isMobile}
          isSmall={false}
          icon={
            <svg
              width="120px"
              height="120px"
              viewBox="0 0 120 120"
              version="1.1"
            >
              <title>icon_reset_password_confirmation_letter</title>
              <g
                id="icon_reset_password_confirmation_letter"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
              >
                <g
                  id="Group"
                  transform="translate(1.000000, 18.000000)"
                  stroke="#0E7EBE"
                  strokeWidth="2"
                >
                  <path
                    d="M24.7859193,26.899645 L58.7859193,0.927422761 C59.5027041,0.379878781 60.4972959,0.379878781 61.2140807,0.927422761 L95.2140807,26.899645 C95.7094017,27.2780152 96,27.8656837 96,28.488987 L96,29.9843454 C96,30.6226533 95.6953197,31.2226217 95.1799319,31.5992016 L61.2880534,56.3630832 C60.5870024,56.8753224 59.635529,56.8767283 58.9329672,56.3665632 L24.8248456,31.5989614 C24.3066614,31.2226821 24,30.6210165 24,29.9806252 L24,28.488987 C24,27.8656837 24.2905983,27.2780152 24.7859193,26.899645 Z"
                    id="Rectangle"
                    fill="#FFFFFF"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M35.5,10.5 L84.5,10.5 C85.6045695,10.5 86.5,11.3954305 86.5,12.5 L86.5,70.5 C86.5,71.6045695 85.6045695,72.5 84.5,72.5 L35.5,72.5 C34.3954305,72.5 33.5,71.6045695 33.5,70.5 L33.5,12.5 C33.5,11.3954305 34.3954305,10.5 35.5,10.5 Z"
                    id="Rectangle-Copy-3"
                    fill="#FFFFFF"
                    strokeLinejoin="round"
                    transform="translate(60.000000, 41.500000) rotate(-270.000000) translate(-60.000000, -41.500000) "
                  />
                  <g
                    id="Group-11"
                    transform="translate(23.111778, 28.000000)"
                    fill="#E5EFFA"
                    strokeLinejoin="round"
                  >
                    <path
                      d="M0.888222268,0 L37,25.6125967 L72.8882223,0 L72.8882223,49 C72.8882223,50.1045695 71.9927918,51 70.8882223,51 L2.88822227,51 C1.78365277,51 0.888222268,50.1045695 0.888222268,49 L0.888222268,0 L0.888222268,0 Z"
                      id="Rectangle-Copy-4"
                    />
                    <path
                      d="M72,50 L38.2859454,22.057467 C37.5458715,21.4440866 36.474121,21.4439353 35.7338739,22.0571066 L2,50 L2,50"
                      id="Line-4"
                      strokeLinecap="round"
                    />
                  </g>
                  <line
                    x1="19.147939"
                    y1="60"
                    x2="7"
                    y2="60"
                    id="Line-7"
                    strokeLinecap="round"
                  />
                  <line
                    x1="14.133497"
                    y1="65"
                    x2="0.850790952"
                    y2="65"
                    id="Line-7-Copy"
                    strokeLinecap="round"
                  />
                  <line
                    x1="73.2093188"
                    y1="30"
                    x2="46.8396144"
                    y2="30"
                    id="Line-7-Copy-2"
                    strokeLinecap="round"
                  />
                  <line
                    x1="73.2093188"
                    y1="35"
                    x2="46.8396144"
                    y2="35"
                    id="Line-7-Copy-3"
                    strokeLinecap="round"
                  />
                </g>
              </g>
            </svg>
          }
          title="Password reset link sent"
          subtitleLine1={'We’ve sent an email to ' + state.email + '.'}
          subtitleLine2="In the email you will find instructions to reset your password."
        >
          <Box
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignSelf: 'center'
            }}
          >
            <Box
              style={{ width: '30px', display: 'flex', alignSelf: 'center' }}
            >
              <Lottie
                onLoopComplete={state.isAnimationStopped}
                animationData={checkmark}
                autoplay={false}
                interactivity={[
                  {
                    eventName: 'loopComplete',
                    callback: () => {
                      setState({ ...state, isAnimationStopped: true });
                    }
                  }
                ]}
              />
            </Box>
            <Button
              onClick={resendEmail}
              isPrimary={false}
              style={{
                width: '161px',
                alignSelf: 'center'
              }}
            >
              Resend email
            </Button>
          </Box>
        </FormBox>
      ) : (
        <FormBox
          isMobile={isMobile}
          isSmall={false}
          icon={
            <svg
              width="120px"
              height="120px"
              viewBox="0 0 120 120"
              version="1.1"
            >
              <title>icon_reset_password_lock</title>
              <g
                id="icon_reset_password_lock"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
              >
                <g id="Group-2" transform="translate(32.000000, 22.000000)">
                  <g id="Group-18">
                    <path
                      d="M0,24 L18,24 C22.6391919,24 26.4,27.7608081 26.4,32.4 L26.4,60 C26.4,64.6391919 22.6391919,68.4 18,68.4 L0,68.4 L0,68.4 L0,24 Z"
                      id="Rectangle"
                      fill="#FFFFFF"
                      transform="translate(13.200000, 46.200000) scale(-1, 1) translate(-13.200000, -46.200000) "
                    />
                    <path
                      d="M43.2,24 L43.2,16.7969276 C43.2,7.52024062 35.6797594,0 26.4030724,0 C26.4030724,2.46831402 26.4030724,2.46831402 26.4,4.8 C33.027417,4.8 38.4,10.172583 38.4,16.8 L38.4,24 L43.2,24 Z"
                      id="Rectangle-Copy-14"
                      fill="#E5EFFA"
                    />
                    <path
                      d="M26.4,24 L26.4,16.7969276 C26.4,7.52024062 18.8797594,0 9.60307244,0 C9.60307244,2.46831402 9.60307244,2.46831402 9.6,4.8 C16.227417,4.8 21.6,10.172583 21.6,16.8 L21.6,24 L26.4,24 Z"
                      id="Rectangle-Copy-14"
                      fill="#FFFFFF"
                      transform="translate(18.000000, 12.000000) scale(-1, 1) translate(-18.000000, -12.000000) "
                    />
                    <path
                      d="M43.2,24 L43.2,16.7969276 C43.2,7.52024062 35.6797594,0 26.4030724,0 C17.1216162,0 9.6,7.5216162 9.6,16.8 L9.6,24 L14.4,24 L14.4,16.8 C14.4,10.172583 19.772583,4.8 26.4,4.8 C33.027417,4.8 38.4,10.172583 38.4,16.8 L38.4,24 L43.2,24 Z"
                      id="Rectangle"
                      stroke="#0E7EBE"
                      strokeWidth="2"
                      strokeLinecap="square"
                    />
                  </g>
                  <g id="Group-14" transform="translate(0.000000, 24.000000)">
                    <path
                      d="M26.4,0 L44.4,0 C49.0391919,-2.62856257e-15 52.8,3.7608081 52.8,8.4 L52.8,36 C52.8,40.6391919 49.0391919,44.4 44.4,44.4 L26.4,44.4 L26.4,44.4 L26.4,0 Z"
                      id="Rectangle"
                      fill="#E5EFFA"
                    />
                    <rect
                      id="Rectangle"
                      stroke="#0E7EBE"
                      strokeWidth="2"
                      strokeLinejoin="round"
                      x="0"
                      y="0"
                      width="52.8"
                      height="44.4"
                      rx="8.4"
                    />
                  </g>
                  <path
                    d="M26.4,38.4 C29.0509668,38.4 31.2,40.5490332 31.2,43.2 C31.2,44.9766143 30.2347921,46.5277922 28.8001535,47.3577567 L28.8,51.6 C28.8,52.9254834 27.7254834,54 26.4,54 C25.0745166,54 24,52.9254834 24,51.6 L23.9998465,47.3577567 C22.5652079,46.5277922 21.6,44.9766143 21.6,43.2 C21.6,40.5490332 23.7490332,38.4 26.4,38.4 Z"
                    id="Combined-Shape"
                    stroke="#0E7EBE"
                    strokeWidth="2"
                    fill="#FFFFFF"
                  />
                  <g
                    id="Group"
                    transform="translate(46.058579, 58.341421) rotate(-315.000000) translate(-46.058579, -58.341421) translate(37.058579, 32.341421)"
                  >
                    <g id="Group-12">
                      <path
                        d="M9,0 C13.9705627,-9.13077564e-16 18,4.02943725 18,9 L18,42.6 C18,47.5705627 13.9705627,51.6 9,51.6 L9,51.6 L9,51.6 L9,0 Z"
                        id="Rectangle-Copy-9"
                        fill="#E5EFFA"
                      />
                      <path
                        d="M0,0 C4.97056275,-9.13077564e-16 9,4.02943725 9,9 L9,42.6 C9,47.5705627 4.97056275,51.6 0,51.6 L0,51.6 L0,51.6 L0,0 Z"
                        id="Rectangle-Copy-9"
                        fill="#FFFFFF"
                        transform="translate(4.500000, 25.800000) scale(-1, 1) translate(-4.500000, -25.800000) "
                      />
                      <rect
                        id="Rectangle-Copy-10"
                        stroke="#0E7EBE"
                        strokeWidth="2"
                        x="0"
                        y="0"
                        width="18"
                        height="51.6"
                        rx="9"
                      />
                      <rect
                        id="Rectangle-Copy-11"
                        stroke="#0E7EBE"
                        strokeWidth="2"
                        x="5"
                        y="21.64"
                        width="8"
                        height="8.32"
                        rx="2.4"
                      />
                    </g>
                    <circle
                      id="Oval-Copy-57"
                      stroke="#0E7EBE"
                      strokeWidth="1.2"
                      cx="6"
                      cy="34.8"
                      r="1"
                    />
                    <circle
                      id="Oval-Copy-59"
                      stroke="#0E7EBE"
                      strokeWidth="1.2"
                      cx="6"
                      cy="8.4"
                      r="1"
                    />
                    <circle
                      id="Oval-Copy-58"
                      stroke="#0E7EBE"
                      strokeWidth="1.2"
                      cx="12"
                      cy="34.8"
                      r="1"
                    />
                    <circle
                      id="Oval-Copy-63"
                      stroke="#0E7EBE"
                      strokeWidth="1.2"
                      cx="12"
                      cy="8.4"
                      r="1"
                    />
                    <circle
                      id="Oval-Copy-60"
                      stroke="#0E7EBE"
                      strokeWidth="1.2"
                      cx="9"
                      cy="39"
                      r="1"
                    />
                    <circle
                      id="Oval-Copy-64"
                      stroke="#0E7EBE"
                      strokeWidth="1.2"
                      cx="9"
                      cy="12.6"
                      r="1"
                    />
                    <circle
                      id="Oval-Copy-61"
                      stroke="#0E7EBE"
                      strokeWidth="1.2"
                      cx="6"
                      cy="43.2"
                      r="1"
                    />
                    <circle
                      id="Oval-Copy-65"
                      stroke="#0E7EBE"
                      strokeWidth="1.2"
                      cx="6"
                      cy="16.8"
                      r="1"
                    />
                    <circle
                      id="Oval-Copy-62"
                      stroke="#0E7EBE"
                      strokeWidth="1.2"
                      cx="12"
                      cy="43.2"
                      r="1"
                    />
                    <circle
                      id="Oval-Copy-66"
                      stroke="#0E7EBE"
                      strokeWidth="1.2"
                      cx="12"
                      cy="16.8"
                      r="1"
                    />
                  </g>
                </g>
              </g>
            </svg>
          }
          title="Don’t worry. Let’s reset your password"
          subtitleLine1="We will send you an email with a link that lets you"
          subtitleLine2="reset your password."
        >
          <form
            onSubmit={onSubmit}
            noValidate
            style={{
              display: 'flex',
              width: '100%',
              flexDirection: 'column',
              alignSelf: 'center'
            }}
          >
            <TextField
              type="email"
              name="email"
              placeholder="Email"
              value={state.email}
              onChange={(e) => setState({ ...state, email: e.target.value })}
              style={{ fontFamily: 'Barlow-Regular' }}
              required
            />
            <Button
              mt="30px"
              style={{ width: '160px', alignSelf: 'center' }}
              disabled={!isValidEmail}
            >
              Send link
            </Button>
          </form>
        </FormBox>
      )}
    </AuthLayout>
  );
};

ResetPasswordPage.propTypes = {
  isMobile: PropTypes.bool
};

const mapDispatchToProps = (dispatch) => ({
  push: (path) => dispatch(push(path)),
  resetPassword: (email) => dispatch(resetPassword(email)),
  clearLoading: () => dispatch(clearLoading('RESET_PASSWORD'))
});

const mapStateToProps = createStructuredSelector({
  loading: loadingSelector('RESET_PASSWORD')
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, withSizes)(ResetPasswordPage);
