import { all, takeLatest, put, call, select } from 'redux-saga/effects';
import { delay } from 'redux-saga/effects';
import {
  SIGN_IN_SUCCESS,
  SIGN_IN_FAILURE,
  SIGN_OUT,
  TOKEN_SIGN_IN_SUCCESS,
  TOKEN_SIGN_IN_FAILURE,
  NEW_PASSWORD_SUCCESS
} from './constants';
import { setToken, clearToken } from 'utils/cookieStorage';
import { clearLoading } from 'modules/loading/actions';
import { push } from 'connected-react-router';
import {
  startLoading,
  stopLoadingSuccess,
  stopLoadingFailure
} from 'modules/loading/actions';

function* handleSignInSuccess(action) {
  yield setToken(action.payload.data.token);
  yield delay(500); // Make sure cookie is loaded
  yield put(push('/'));
  yield put(clearLoading('SIGN_IN'));
}

function* handleSignInFailure() {
  yield clearToken();
}

function* handleTokenSignInFailure() {
  yield clearToken();
  yield put(push('/sign_in/failure'));
  yield put(clearLoading('SIGN_IN'));
}

function* handleSignout() {
  yield clearToken();
  yield put(push('/sign_in'));
}

function* handleUnauthorized() {
  const state = yield select();

  // If login fail on sigin it throws 401.
  // So an exception for redirect is made here
  if (state.router.location.pathname !== '/sign_in') {
    yield clearToken();
    yield put(push('/sign_in'));
  }
}

function* handleNewPasswordSuccess(action) {
  yield setToken(action.payload.data.token);
  yield call(delay, 500); // Make sure cookie is loaded
  yield put(push('/password/success'));
  yield put(clearLoading('NEW_PASSWORD'));
}

function* handleRequest({ type }) {
  let key = type.replace(/FETCH_/, '');
  key = key.replace(/_REQUEST/, '');
  key = key.replace(/_SUCCESS/, '');
  key = key.replace(/_FAILURE/, '');

  yield put(startLoading(key));
}

function* handleSuccessRequest({ type }) {
  let key = type.replace(/FETCH_/, '');
  key = key.replace(/_REQUEST/, '');
  key = key.replace(/_SUCCESS/, '');
  key = key.replace(/_FAILURE/, '');

  yield put(stopLoadingSuccess(key));
}

function* handleFailureRequest({ type, error, payload }) {
  let key = type.replace(/FETCH_/, '');
  key = key.replace(/_REQUEST/, '');
  key = key.replace(/_SUCCESS/, '');
  key = key.replace(/_FAILURE/, '');

  if (error && payload.status === 401) {
    yield call(handleUnauthorized);
  }

  yield put(stopLoadingFailure(key, payload));
}

function* authenticationSaga() {
  yield all([
    takeLatest(TOKEN_SIGN_IN_FAILURE, handleTokenSignInFailure),
    takeLatest(TOKEN_SIGN_IN_SUCCESS, handleSignInSuccess),
    takeLatest(SIGN_IN_SUCCESS, handleSignInSuccess),
    takeLatest(SIGN_IN_FAILURE, handleSignInFailure),
    takeLatest(SIGN_OUT, handleSignout),
    takeLatest(NEW_PASSWORD_SUCCESS, handleNewPasswordSuccess),
    takeLatest(NEW_PASSWORD_SUCCESS, handleNewPasswordSuccess),
    takeLatest((action) => /_REQUEST/.test(action.type), handleRequest),
    takeLatest((action) => /_SUCCESS/.test(action.type), handleSuccessRequest),
    takeLatest((action) => /_FAILURE/.test(action.type), handleFailureRequest)
  ]);
}

export default authenticationSaga;
