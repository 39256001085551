import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import {
  NewsProfileRoute,
  NewsLikesRoute,
  NewsPostRoute,
  DirectoryRoute,
  ContactRoute,
  VideoRoute
} from './secondary_routes';

import news_feed from 'screens/NewsFeedScreen';
import user_feed from 'screens/ContactsFeedScreen';
import community_feed from 'screens/CommunityFeedScreen';
import directory_feed from 'screens/DirectoryFeedScreen';
import video_feed from 'screens/VideoFeedScreen';

const NewPostScreen = React.lazy(() => import('screens/NewPostScreen'));

export const NEWS_FEED = 'news_feed';
export const USER_FEED = 'user_feed';
export const COMMUNITY_FEED = 'community_feed';
export const DIRECTORY_FEED = 'directory_feed';
export const VIDEO_FEED = 'video_feed';

const componentByType = {
  news_feed: {
    component: news_feed,
    secondary_routes: [NewsProfileRoute, NewsLikesRoute, NewsPostRoute]
  },
  user_feed: {
    component: user_feed,
    secondary_routes: [ContactRoute]
  },
  community_feed: {
    component: community_feed
  },
  directory_feed: {
    component: directory_feed,
    secondary_routes: [DirectoryRoute]
  },
  video_feed: {
    component: video_feed,
    secondary_routes: [VideoRoute]
  }
};

export const secondaryRouteForFeed = (feed, isMobile) => {
  const componentType = componentByType[feed.type];
  if (!componentType || !componentType.secondary_routes) return [];
  return (componentType.secondary_routes || []).map((secondary_route) => {
    return secondary_route(feed, isMobile);
  });
};

export default (feeds, isMobile) => {
  let routes = [];
  const feedRoutes = feeds.map((feed) => {
    const routeDefinition = componentByType[feed.type];

    if (!routeDefinition) return null;

    const Component = routeDefinition['component'];

    if (!Component) {
      return null;
    }

    if (isMobile && routeDefinition.secondary_routes) {
      const oldRoutes = routes || [];
      routes = secondaryRouteForFeed(feed, true).concat(oldRoutes);
    }

    if (feed.type === 'community_feed') {
      routes.push(
        <Route
          key={`${feed.id}/new`}
          path={`${feed.path}/new`}
          render={() => <NewPostScreen feed={feed} />}
        />
      );
    }

    return (
      <Route
        key={feed.id}
        path={feed.path}
        render={() => <Component feedId={feed.id} />}
      />
    );
  });

  // Routes for redirecting "/feeds/<feed_id>/<post_id>" to "/<feed_path>/<post_id>" (e.g. /feeds/9/2155 -> /news/2155)
  const feedByIdRedirectRoutes = feeds.map((feed) => {
    const pathByFeedId = `/feeds/${feed.id}`;

    return (
      <Route
        key={`redirect_route_feed_${feed.id}`}
        path={pathByFeedId}
        component={({ location }) => (
          <Redirect
            to={{
              ...location,
              pathname: location.pathname.replace(pathByFeedId, `${feed.path}`)
            }}
          />
        )}
      />
    );
  });

  return routes.concat(feedRoutes).concat(feedByIdRedirectRoutes);
};
