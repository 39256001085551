import React from 'react';
import { Box } from '@rebass/grid';
import Likes from 'components/Likes';
import { FeedProps } from 'models/Feed';
import { PostProps } from 'models/Post';

const SocialBlock = ({ feed, post }) => (
  <Box flex="1 1 auto" style={{ gridArea: 'likes', position: 'static' }}>
    <Likes feed={feed} post={post} />
  </Box>
);

SocialBlock.propTypes = {
  feed: FeedProps,
  post: PostProps
};

export default SocialBlock;
