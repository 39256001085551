import endpointUrl from 'utils/url';
import { getHeadersWithToken } from 'utils/headers';

export const fetchConsentDisclaimer = async () => {
  try {
    const response = await fetch(
      endpointUrl('/api/v1/consent_disclaimer/latest'),
      {
        method: 'GET',
        headers: getHeadersWithToken()
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error.message);
  }
};

export const consentLatestDisclaimer = async () => {
  try {
    const response = await fetch(
      endpointUrl('/api/v1/consent_disclaimer/latest/consent'),
      {
        method: 'POST',
        headers: getHeadersWithToken()
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error.message);
  }
};
