import React from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import configureStore from './configureStore';
import theme from '../styles/theme';
import GlobalStyle from '../styles/global-styles';
import PropTypes from 'prop-types';

const initialState = {};

const history = createBrowserHistory();
const store = configureStore(initialState, history);

const providers = ({ children }) => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <ThemeProvider theme={theme}>
        <React.Fragment>
          {children} <GlobalStyle />
        </React.Fragment>
      </ThemeProvider>
    </ConnectedRouter>
  </Provider>
);

providers.propTypes = {
  children: PropTypes.node
};

export default providers;
