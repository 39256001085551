import * as serviceWorker from './utils/serviceWorker';
import Providers from './utils/providers';
import React from 'react';
import ReactDOM from 'react-dom';
import RootRouter from 'routes/RootRouter';
import { piwikProInitialize } from 'utils/piwikpro';

let providers = (
  <Providers>
    <React.Fragment>
      <RootRouter />
      <style>{`
          @font-face {
            font-family: 'Barlow-Regular';
            font-style: normal;
            font-weight: 400;
            font-display: fallback;
            src: local('Barlow'), local('Barlow-Regular'),
                 url('/fonts/Barlow-Regular.ttf') format('truetype');
          }
          @font-face {
            font-family: 'Barlow-Medium';
            font-style: normal;
            font-weight: 500;
            font-display: fallback;
            src: local('Barlow Medium'), local('Barlow-Medium'),
                 url('/fonts/Barlow-Medium.ttf') format('truetype');
          }
          @font-face {
            font-family: 'Barlow-SemiBold';
            font-style: normal;
            font-weight: 600;
            font-display: fallback;
            src: local('Barlow SemiBold'), local('Barlow-SemiBold'),
                 url('/fonts/Barlow-SemiBold.ttf') format('truetype');
          }
          @font-face {
            font-family: 'Barlow-Bold';
            font-style: normal;
            font-weight: 700;
            font-display: fallback;
            src: local('Barlow Bold'), local('Barlow-Bold'),
                 url('/fonts/Barlow-Bold.ttf') format('truetype');
          }
          @font-face {
            font-family: 'Barlow-ExtraBold';
            font-style: normal;
            font-weight: 800;
            font-display: fallback;
            src: local('Barlow ExtraBold'), local('Barlow-ExtraBold'),
                 url('/fonts/Barlow-ExtraBold.ttf') format('truetype');
          }
          @font-face {
            font-family: 'Barlow-Black';
            font-style: normal;
            font-weight: 900;
            font-display: fallback;
            src: local('Barlow Black'), local('Barlow-Black'),
                 url('/fonts/Barlow-Black.ttf') format('truetype');
          }
      `}</style>
    </React.Fragment>
  </Providers>
);

piwikProInitialize();

ReactDOM.render(providers, document.getElementById('root'));

serviceWorker.unregister();
