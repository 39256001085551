import React from 'react';
import PropTypes from 'prop-types';

import PrimaryScreenWrapper from './PrimaryScreenWrapper';
import SecondaryScreenWrapper from './SecondaryScreenWrapper';

const SplitScreen = (props) => {
  const { renderPrimaryPage, renderSecondaryPage } = props;

  return (
    <>
      <PrimaryScreenWrapper>{renderPrimaryPage(props)}</PrimaryScreenWrapper>
      <SecondaryScreenWrapper>
        {renderSecondaryPage(props)}
      </SecondaryScreenWrapper>
    </>
  );
};

SplitScreen.propTypes = {
  renderPrimaryPage: PropTypes.func.isRequired,
  renderSecondaryPage: PropTypes.func.isRequired
};

export default SplitScreen;
