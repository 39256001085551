import React, { Component } from 'react';
import { compose } from 'redux';
import withSizes from 'utils/sizes';
import AuthLayout from '../AuthLayout';
import EmailForm from './EmailForm';
import SelectMethodForm, { PASSWORD, TOKEN } from './SelectMethodForm';
import PasswordForm from './PasswordForm';
import TokenForm from './TokenForm';
import qs from 'query-string';

class SignInPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      method: '',
      emailSubmitted: false
    };
    // Bind the submission to handleChange()
    this.changeEmail = this.changeEmail.bind(this);
    this.submitEmail = this.submitEmail.bind(this);
    this.setMethod = this.setMethod.bind(this);
  }

  changeEmail(e) {
    this.setState({
      email: e.target.value
    });
  }

  submitEmail(e) {
    e.preventDefault();
    this.setState({ emailSubmitted: true });
  }

  setMethod(method) {
    this.setState({ method: method });
  }

  render() {
    const { isMobile } = this.props;
    const { email, method, emailSubmitted } = this.state;
    let currentForm;
    let params = qs.parse(this.props.location.search);

    // In this case the Magic Link button in the email is pressed
    if (params['token']) {
      return (
        <AuthLayout isLanding={false} isMobile={isMobile}>
          {<TokenForm token={params['token']} />}
        </AuthLayout>
      );
    } else if (!emailSubmitted) {
      currentForm = (
        <EmailForm
          isMobile={isMobile}
          email={email}
          onChange={this.changeEmail}
          onSubmit={this.submitEmail}
        />
      );
    } else if (method === PASSWORD) {
      currentForm = <PasswordForm isMobile={isMobile} email={email} />;
    } else if (method === TOKEN) {
      currentForm = <TokenForm isMobile={isMobile} email={email} />;
    } else {
      currentForm = (
        <SelectMethodForm isMobile={isMobile} setMethod={this.setMethod} />
      );
    }

    return (
      <AuthLayout isLanding={!this.state.emailSubmitted} isMobile={isMobile}>
        {currentForm}
      </AuthLayout>
    );
  }
}

export default compose(withSizes)(SignInPage);
