import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Item from './Item';
import { UserProps } from 'models/User';

const StyledList = styled.article`
  padding: 12px 10px;
  border-radius: 5px;
  box-shadow: ${(props) => props.theme.shadows.normal};
  background-color: ${(props) => props.theme.colors.light};
`;

const List = ({ users, basePath }) => (
  <StyledList>
    <ul>
      {users.map((user) => (
        <Item key={user.id} user={user} basePath={basePath} />
      ))}
    </ul>
  </StyledList>
);

List.propTypes = {
  users: PropTypes.arrayOf(UserProps),
  basePath: PropTypes.string.isRequired
};

List.defaultProps = {
  users: []
};

export default List;
