import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { DIRECTORY_FEED, USER_FEED } from 'routes/FeedRouter';

const BoxWrapper = styled.article`
  padding: ${({ noPadding }) => (noPadding ? '0' : '38px 0 30px 0')};
  margin: 0;
  background-color: ${(props) => props.theme.colors.light};
  transition:
    transform 0.2s,
    box-shadow 0.2s;
  position: relative;
  ${({ feedType }) =>
    ![USER_FEED, DIRECTORY_FEED].includes(feedType) &&
    `min-height: ${({ minheight }) => (minheight ? minheight : '80px')};
  `}
  ${(props) =>
    !props.isProfileScreen &&
    `
    border-bottom: 1px solid ${props.theme.colors.dim_blue};
    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 10px;
      background-color: transparent;
      transition: background-color 0.05s;
    }
    &:hover::before {
      background-color: rgb(3, 106, 182);
    }
  `}
  ${(props) =>
    props.$active &&
    !props.isProfileScreen &&
    css`
      &::before {
        background-color: rgb(3, 106, 182);
      }
    `};
`;

BoxWrapper.propTypes = {
  active: PropTypes.bool,
  flex: PropTypes.bool,
  noPadding: PropTypes.bool,
  feedType: PropTypes.string,
  isProfileScreen: PropTypes.bool
};

BoxWrapper.defaultProps = {
  isProfileScreen: false
};

export default BoxWrapper;
