import { handleActions } from 'redux-actions';
import {
  SEARCH_FEEDS_REQUEST,
  SEARCH_FEEDS_SUCCESS,
  SEARCH_CONTACTS_REQUEST,
  SEARCH_CONTACTS_SUCCESS,
  CLEAR_SEARCH
} from './constants';

export const initialState = { search_status: 'none' };
// eslint-disable-next-line no-unused-vars
const handleRequest = (state, action) => {
  return {
    ...state,
    search_status: 'pending'
  };
};
// eslint-disable-next-line no-unused-vars
const handleSuccess = (state, action) => {
  return {
    ...state,
    search_status: 'finished'
  };
};
// eslint-disable-next-line no-unused-vars
const handleClear = (state, action) => {
  return {
    ...state,
    search_status: 'success'
  };
};

const searchReducer = handleActions(
  {
    [SEARCH_FEEDS_REQUEST]: handleRequest,
    [SEARCH_CONTACTS_REQUEST]: handleRequest,
    [SEARCH_FEEDS_SUCCESS]: handleSuccess,
    [SEARCH_CONTACTS_SUCCESS]: handleSuccess,
    [CLEAR_SEARCH]: handleClear
  },
  initialState
);

export default searchReducer;
