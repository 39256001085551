import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import logo3x from './logo@3x.png';
import { Flex } from '@rebass/grid';
import { Img as ReactImage } from 'react-image';
import MediaQuery from 'react-responsive';
import { desktopQueryMin } from 'utils/media';

// prettier-ignore
const StyledFlex = styled(Flex)`
  h1 {
    font-size: 40px;
    color: ${props => props.theme.colors.black};
    line-height: 1.4;
  }

  ${props => props.$small && css`

    img {
      width: 25px;
      height: 25px;
    }

    h1 {
      margin-right: 0px;
      font-size: 17px;
      color: ${props => props.theme.colors.contrast};
    }
  `};
`

const Logo = ({ small }) => (
  <StyledFlex $small={small}>
    <ReactImage src={logo3x} height="58px" width="51px" />
    <MediaQuery query={desktopQueryMin}>
      <Flex flexdirection="column" ml={small ? '6px' : '18px'}>
        <h1>imCORE</h1>
      </Flex>
    </MediaQuery>
  </StyledFlex>
);

Logo.propTypes = {
  small: PropTypes.bool.isRequired
};

Logo.defaultProps = {
  small: false
};

export default Logo;
