import { RSAA } from 'redux-api-middleware';
import {
  FETCH_USER_REQUEST,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAILURE
} from './constants';
import endpointUrl from 'utils/url';
import { getHeadersWithToken } from 'utils/headers';

export const fetchUser = () => {
  return {
    [RSAA]: {
      endpoint: () => endpointUrl('/api/v1/user'),
      method: 'GET',
      types: [FETCH_USER_REQUEST, FETCH_USER_SUCCESS, FETCH_USER_FAILURE],
      headers: getHeadersWithToken()
    }
  };
};
