import { RSAA } from 'redux-api-middleware';
import {
  FETCH_NAVIGATION_ITEMS_REQUEST,
  FETCH_NAVIGATION_ITEMS_SUCCESS,
  FETCH_NAVIGATION_ITEMS_FAILURE
} from './constants';
import endpointUrl from 'utils/url';
import { getHeadersWithToken } from 'utils/headers';

export const fetchNavigationItems = () => ({
  [RSAA]: {
    endpoint: () => endpointUrl('/api/v1/navigation_items'),
    method: 'GET',
    types: [
      FETCH_NAVIGATION_ITEMS_REQUEST,
      FETCH_NAVIGATION_ITEMS_SUCCESS,
      FETCH_NAVIGATION_ITEMS_FAILURE
    ],
    headers: getHeadersWithToken()
  }
});
