import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import CreatePostButton from 'components/CreatePostButton';
import LoadingProps from 'modules/loading/props';
import VisibilitySensor from 'react-visibility-sensor';
import { FeedProps } from 'models/Feed';
import paginationParser from 'modules/pagination';
import Loading from 'components/Loading';
import qs from 'query-string';
import SearchInput from './SearchInput';
import Flash from 'components/Flash';
import { FeedEmptyPage } from 'pages/EmptyPage';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import { compose } from 'redux';
import styled from 'styled-components';

const SeacrhInputContainer = styled.header`
  top: -30px;
  position: sticky;
  z-index: 999;
  background-color: ${(props) => props.theme.colors.light};
  padding-top: 30px;
`;

const List = (props) => {
  const {
    resources,
    searchCallback,
    feed,
    changeSourceCallback,
    history,
    loading,
    fetchPaginationCallback,
    searchable,
    createableUrl,
    renderListItem,
    renderPlaceholderListItem,
    location,
    currentSource
  } = props;
  const state = {
    page: 1,
    resources: resources,
    searchTerm: qs.parse(window.location.search).search
  };
  const pagination = paginationParser(feed);

  useEffect(() => {
    if (state.searchTerm) searchCallback(state.searchTerm, feed.id);
  }, [state.searchTerm, feed.id]);

  const changeResourceScope = (e) => {
    const newSearchTerm = e.target.value;
    const currentSearchTerm = state.searchTerm;

    if (isEmpty(newSearchTerm) && !isEmpty(currentSearchTerm))
      changeSourceCallback('controller');
    //Switch back to results taken from pagination
    else searchCallback(newSearchTerm, feed.id);

    history.push({
      search: qs.stringify({ search: newSearchTerm })
    });
  };

  const handleLoadingVisibiliy = (isVisible) => {
    if (!isVisible) return;
    if (!loading.fulfilled || loading.pending) return;
    if (!pagination.hasMore) return;
    fetchPaginationCallback
      ? fetchPaginationCallback(pagination.currentPage + 1)
      : console.error('fetch pagination function missing');
  };

  return (
    <>
      {location.state && location.state.message && (
        <Flash type={location.state.type}>{location.state.message}</Flash>
      )}
      {createableUrl && <CreatePostButton createableUrl={createableUrl} />}

      <SeacrhInputContainer>
        {searchable && (
          <SearchInput
            onChange={changeResourceScope}
            delayTimeout={1000}
            value={state.searchTerm}
            autoFocus
            feed={feed.type}
          />
        )}
      </SeacrhInputContainer>

      {loading.fulfilled && state.resources.length === 0 && (
        <FeedEmptyPage topAjustment="-60px;" />
      )}
      {loading.pending && state.resources.length === 0
        ? [1, 2, 3, 4, 5, 6, 7].map((i) => {
            return <div key={i}>{renderPlaceholderListItem()}</div>;
          })
        : state.resources.map((resource) => (
            <div key={resource.id}>
              {renderListItem(resource, props, state.searchTerm)}
            </div>
          ))}
      {pagination.hasPagination &&
        currentSource !== 'search' &&
        pagination.hasMore &&
        (loading.fulfilled || loading.pending) && (
          <VisibilitySensor onChange={handleLoadingVisibiliy}>
            <Loading />
          </VisibilitySensor>
        )}
    </>
  );
};

List.propTypes = {
  resources: PropTypes.array.isRequired,
  searchable: PropTypes.bool,
  renderListItem: PropTypes.func.isRequired,
  searchCallback: PropTypes.func.isRequired,
  changeSourceCallback: PropTypes.func.isRequired,
  renderPlaceholderListItem: PropTypes.func.isRequired,
  searchKeys: PropTypes.arrayOf(PropTypes.string),
  createableUrl: PropTypes.string,
  currentSource: PropTypes.string,
  loading: LoadingProps,
  feed: FeedProps,
  fetchPaginationCallback: PropTypes.func
};

function mapStateToProps(state) {
  return { search_status: state.search.search_status };
}

const withConnect = connect(mapStateToProps);

export default compose(withRouter, withConnect)(List);
