import React from 'react';
import { Flex, Box } from '@rebass/grid';

const ColumnWrapper = ({ children, ...props }) => (
  <Flex flexdirection="column" css={{ width: '100%' }} {...props}>
    <Box flex="1">{children}</Box>
  </Flex>
);

export default ColumnWrapper;
