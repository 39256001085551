import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// prettier-ignore
const StyledTextField = styled.input`
  flex: 1 0;
  border: none;
  outline: none;
  background-color: ${props => props.theme.colors.primary};
  font-size: 17px;
  color: #4f4f4f;
  border-radius: 5px;
  border: solid 1px #e9e9e9;

  padding: 15px;

  &::placeholder {
    color: #a7a7a7;
  }
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  border-radius: 5px;
  background-color: ${(props) => props.theme.colors.primary};
`;

// TODO: hoist props
const TextField = ({ className, ...props }) => (
  <Wrapper className={className}>
    <StyledTextField {...props} />
  </Wrapper>
);

TextField.propTypes = {
  className: PropTypes.string
};

export default TextField;
