import { DIRECTORY_FEED, USER_FEED } from 'routes/FeedRouter';
import styled from 'styled-components';

// prettier-ignore
const Wrapper = styled.article`
  display: grid;
  border-radius: 5px;
  align-items: center;
  background-color: ${props => props.theme.colors.light};
  grid-gap: 20px;
  ${({ feedType }) => [USER_FEED, DIRECTORY_FEED].includes(feedType) ? `
  grid:
    [row1-start] 'caption caption' auto [row1-end]
    [row2-start] 'image image' auto [row2-end]
    [row3-start] 'properties properties' 1fr [row3-end]
    [row4-start] 'body body' auto [row4-end]
    / auto 1fr;
  ` : `
  grid:
    [row1-start] 'image caption' auto [row1-end]
    [row2-start] 'properties properties' 1fr [row2-end]
    [row3-start] 'body body' auto [row3-end]
    / auto 1fr;
  grid-gap: 40px;
  `}

  ${props => props.empty && `
    grid-row-gap: 0;
  `}
`

export default Wrapper;
