import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { fetchPosts } from './actions';
import { feedByIdSelector } from '../../selectors';

import CommunityListItem from 'components/CommunityListItem';
import { CommunityCardPlaceholder } from 'components/CardPlaceholder';
import FeedListSplitScreen from 'components/FeedListSplitScreen';

import { FeedProps } from 'models/Feed';

import { loadingSelector } from 'modules/loading/selectors';
import LoadingProps from 'modules/loading/props';
import { CommentEmptyPage } from 'pages/EmptyPage';

class CommunityFeed extends Component {
  static propTypes = {
    feed: FeedProps,
    feedId: PropTypes.number,
    fetchPosts: PropTypes.func,
    loading: LoadingProps.isRequired
  };

  componentDidMount() {
    const { feedId, fetchPosts } = this.props;
    fetchPosts(feedId);
  }

  render() {
    const { feed, loading, fetchPosts } = this.props;
    const resources = feed.posts.toModelArray();
    resources.sort((a, b) => b.created_at - a.created_at);

    return (
      <FeedListSplitScreen
        feedLoading={loading}
        createableUrl={`${feed.path}/new`}
        feed={feed}
        resources={resources}
        renderListItem={(post) => (
          <CommunityListItem key={post.id} feed={feed} post={post} />
        )}
        renderPlaceholderListItem={() => <CommunityCardPlaceholder />}
        emptyPageComponent={CommentEmptyPage}
        fetchPaginationCallback={(page) => {
          fetchPosts(feed.id, page);
        }}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  feed: feedByIdSelector(state)(ownProps.feedId),
  loading: loadingSelector('COMMUNITY_FEED_POSTS')(state)
});

const mapDispatchToProps = (dispatch) => ({
  fetchPosts: (feedId, paginationPage) =>
    dispatch(fetchPosts(feedId, paginationPage))
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withRouter, withConnect)(CommunityFeed);
