import React from 'react';
import { Box } from '@rebass/grid';
import Button from 'components/Button';
import PropTypes from 'prop-types';
import FormBox from '../../../components/FormBox';

export const PASSWORD = 'password';
export const TOKEN = 'token';

class SelectMethodForm extends React.Component {
  render() {
    const { isMobile, setMethod } = this.props;
    return (
      <FormBox
        isMobile={isMobile}
        isSmall={false}
        icon={
          <svg width="120px" height="120px" viewBox="0 0 120 120" version="1.1">
            <title>icon_login_magiclink_letter</title>
            <g
              id="icon_login_magiclink_letter"
              stroke="none"
              strokeWidth="1"
              fill="none"
              fillRule="evenodd"
            >
              <g id="Group-5" transform="translate(2.000000, -1.000000)">
                <g
                  id="Group"
                  transform="translate(22.388222, 17.388222)"
                  strokeLinejoin="round"
                  strokeWidth="2"
                >
                  <path
                    d="M0.897697026,27.0114227 L34.897697,1.03920049 C35.6144819,0.491656513 36.6090736,0.491656513 37.3258584,1.03920049 L71.3258584,27.0114227 C71.8211795,27.3897929 72.1117777,27.9774615 72.1117777,28.6007647 L72.1117777,29.9824822 C72.1117777,30.6218338 71.8061057,31.2226528 71.2893173,31.5990837 L37.2893173,56.3648312 C36.5875121,56.8760292 35.6360433,56.8760292 34.9342382,56.3648312 L0.934238155,31.5990837 C0.417449729,31.2226528 0.111777732,30.6218338 0.111777732,29.9824822 L0.111777732,28.6007647 C0.111777732,27.9774615 0.402375996,27.3897929 0.897697026,27.0114227 Z"
                    id="Rectangle"
                    stroke="#0E7EBE"
                    fill="#FFFFFF"
                  />
                  <g id="Group-11" transform="translate(0.111778, 27.000000)">
                    <path
                      d="M0,1.11177773 L36,26.6125967 L72,1.11177773 L72,50.1117777 C72,51.2163472 71.1045695,52.1117777 70,52.1117777 L2,52.1117777 C0.8954305,52.1117777 1.3527075e-16,51.2163472 0,50.1117777 L0,1.11177773 L0,1.11177773 Z"
                      id="Rectangle-Copy-4"
                      stroke="#0E7EBE"
                      fill="#E5EFFA"
                    />
                    <path
                      d="M71,51 L37.2731126,23.1625766 C36.5339932,22.5525239 35.4660068,22.5525239 34.7268874,23.1625766 L1,51 L1,51"
                      id="Line-4"
                      stroke="#0E7EBE"
                      fill="#E5EFFA"
                      strokeLinecap="round"
                    />
                    <line
                      x1="68.3882223"
                      y1="1"
                      x2="3.38822227"
                      y2="1"
                      id="Line-5"
                      stroke="#E5EFFA"
                      strokeLinecap="square"
                      strokeDasharray="4,6"
                    />
                  </g>
                </g>
                <path
                  d="M5.60968422,64.2039904 C8.38324758,65.3850341 10.9890447,65.4023301 13.2648957,64.3908408 C13.3505073,64.3527912 13.4434367,64.3530447 13.5244757,64.3842135 C13.6055146,64.4153823 13.674663,64.4774664 13.7127126,64.5630781 C13.7517006,64.650801 13.7517006,64.7509347 13.7127126,64.8386577 C12.697445,67.1230098 12.697445,69.7305436 13.7127126,72.0148957 C13.7505107,72.0999413 13.7512394,72.1920164 13.7205176,72.2723349 C13.6903997,72.3510745 13.6301711,72.4180983 13.54717,72.4549877 C13.461452,72.4930846 13.3635719,72.4929039 13.2779952,72.4544907 C10.9998408,71.4318867 8.39379228,71.4289162 6.11331259,72.4463241 C6.01731039,72.4891544 5.91475739,72.4949403 5.82521342,72.4618027 C5.74533805,72.4322432 5.67813421,72.3717745 5.64103417,72.2886163 C5.60218027,72.2015268 5.60210998,72.10204 5.64084078,72.0148957 C6.65696381,69.7286189 6.62537713,67.1128607 5.55434242,64.8517875 C5.47246038,64.6789254 5.4312987,64.5124205 5.46981398,64.3688298 C5.48966895,64.2948075 5.54012918,64.2369375 5.60968422,64.2039904 Z"
                  id="Star-Copy"
                  stroke="#0E7EBE"
                  strokeWidth="2"
                  transform="translate(9.500000, 68.250000) rotate(-315.000000) translate(-9.500000, -68.250000) "
                />
                <path
                  d="M68.088119,5.46406408 C68.1737306,5.42601447 68.26666,5.42626798 68.347699,5.4574368 C68.5749239,5.72402434 68.5749239,5.82415804 68.5359359,5.91188102 C67.5206683,8.19623311 67.5206683,10.8037669 68.5359359,13.088119 C68.5739855,13.1737306 68.573732,13.26666 68.5425632,13.347699 C68.5113944,13.4287379 68.4493103,13.4978863 68.3636986,13.5359359 C68.2759757,13.5749239 68.175842,13.5749239 68.088119,13.5359359 C65.8037669,12.5206683 63.1962331,12.5206683 60.911881,13.5359359 C60.8262694,13.5739855 60.73334,13.573732 60.652301,13.5425632 C60.5712621,13.5113944 60.5021137,13.4493103 60.4640641,13.3636986 C60.4250761,13.2759757 60.4250761,13.175842 60.4640641,13.088119 C61.4793317,10.8037669 61.4793317,8.19623311 60.4640641,5.91188102 C60.4260145,5.8262694 60.426268,5.73333997 60.4574368,5.65230104 C60.4886056,5.57126211 60.5506897,5.50211369 60.6363014,5.46406408 C60.7240243,5.42507609 60.824158,5.42507609 60.911881,5.46406408 C63.1962331,6.47933168 65.8037669,6.47933168 68.088119,5.46406408 Z"
                  id="Star-Copy-4"
                  stroke="#0E7EBE"
                  strokeWidth="2"
                  transform="translate(64.500000, 9.500000) rotate(-315.000000) translate(-64.500000, -9.500000) "
                />
                <path
                  d="M111.088119,32.4640641 C111.173731,32.4260145 111.26666,32.426268 111.347699,32.4574368 C111.574924,32.7240243 111.574924,32.824158 111.535936,32.911881 C110.520668,35.1962331 110.520668,37.8037669 111.535936,40.088119 C111.573986,40.1737306 111.573732,40.26666 111.542563,40.347699 C111.511394,40.4287379 111.44931,40.4978863 111.363699,40.5359359 C111.275976,40.5749239 111.175842,40.5749239 111.088119,40.5359359 C108.803767,39.5206683 106.196233,39.5206683 103.911881,40.5359359 C103.826269,40.5739855 103.73334,40.573732 103.652301,40.5425632 C103.571262,40.5113944 103.502114,40.4493103 103.464064,40.3636986 C103.425076,40.2759757 103.425076,40.175842 103.464064,40.088119 C104.479332,37.8037669 104.479332,35.1962331 103.464064,32.911881 C103.426014,32.8262694 103.426268,32.73334 103.457437,32.652301 C103.488606,32.5712621 103.55069,32.5021137 103.636301,32.4640641 C103.724024,32.4250761 103.824158,32.4250761 103.911881,32.4640641 C106.196233,33.4793317 108.803767,33.4793317 111.088119,32.4640641 Z"
                  id="Star-Copy-5"
                  stroke="#0E7EBE"
                  strokeWidth="2"
                  transform="translate(107.500000, 36.500000) rotate(-315.000000) translate(-107.500000, -36.500000) "
                />
                <path
                  d="M42.0885904,78.4619299 C42.1741153,78.423866 42.266972,78.4240714 42.3479635,78.455174 C42.5751477,78.7214354 42.5751993,78.8214906 42.5362871,78.9091649 C41.521296,81.1960721 41.5220891,83.8033382 42.5372967,86.0875553 C42.5753464,86.1731669 42.5750928,86.2660964 42.543924,86.3471353 C42.5127552,86.4281742 42.4506711,86.4973226 42.3650595,86.5353722 C42.2773365,86.5743602 42.1772028,86.5743602 42.0894798,86.5353722 C39.8051706,85.5201237 37.1978167,85.5194513 34.9129842,86.5335216 C34.8266741,86.5718284 34.7331107,86.5719385 34.6515251,86.5405836 C34.5706003,86.5094826 34.5016372,86.4474791 34.4637124,86.3620296 C34.4248001,86.2743552 34.4248518,86.1743 34.4638544,86.0866659 C35.4801408,83.8031966 35.4808134,81.1959415 34.4657053,78.9119482 C34.4275454,78.8260884 34.4276616,78.7329236 34.4588866,78.6516759 C34.4900271,78.5706485 34.5520887,78.5015331 34.6376622,78.4635004 C34.7253852,78.4245124 34.8255189,78.4245124 34.9132418,78.4635004 C37.1979261,79.4789156 39.8051519,79.4782027 42.0885904,78.4619299 Z"
                  id="Star-Copy-2"
                  stroke="#0E7EBE"
                  strokeWidth="2"
                  fill="#FFFFFF"
                  transform="translate(38.500000, 82.498075) rotate(-315.000000) translate(-38.500000, -82.498075) "
                />
                <g
                  id="Group-7"
                  transform="translate(71.500000, 34.888222) rotate(-35.000000) translate(-71.500000, -34.888222) translate(43.000000, 19.388222)"
                >
                  <path
                    d="M9.01097649e-06,13.9725324 C1.01065001,6.08479861 7.50555266,6.82121026e-13 15.3671162,6.82121026e-13 C20.889602,6.82121026e-13 25.7376963,3.00260059 28.4840792,7.52354225 L51.9076723,7.52369832 L56.0479042,13.9725826 L9.01097649e-06,13.9725324 Z"
                    id="Combined-Shape"
                    fill="#FFFFFF"
                  />
                  <g id="Group-4">
                    <path
                      d="M54.657,12.4309538 L56.4189499,15.1981015 L51.3586782,20.6704558 L48.1801031,20.6710017 L45.0034734,23.8262426 L41.8186104,20.6710017 L38.6513297,23.8262426 L35.4959563,20.6710017 L32.3059644,23.8262426 L29.1399946,20.6710017 L28.5988856,21.2058155 C26.2509579,26.4163353 21.027652,30.0416418 14.960969,30.0416418 C6.69825397,30.0416418 1.0263842e-13,23.3167134 1.0263842e-13,15.0210938 C1.0263842e-13,14.1378339 0.0759348308,13.2723801 0.221607835,12.4309538 L54.657,12.4309538 Z"
                      id="Combined-Shape"
                      fill="#E5EFFA"
                    />
                    <path
                      d="M14.960969,0 C20.8124946,0 25.8794037,3.37272035 28.3383156,8.28751912 L51.3619926,8.28719887 L56.4189499,15.7368144 L51.3586782,20.8676584 L48.1801031,20.8676584 L45.0034734,23.8256967 L41.8186104,20.8676584 L38.6513297,23.8256967 L35.4959563,20.8676584 L32.3059644,23.8256967 L29.1399946,20.8676584 L28.4653418,21.493431 C26.0569879,26.5489696 20.9151757,30.0410959 14.960969,30.0410959 C6.69825397,30.0410959 1.0263842e-13,23.3161675 1.0263842e-13,15.0205479 C1.0263842e-13,6.72492838 6.69825397,0 14.960969,0 Z"
                      id="Combined-Shape"
                      stroke="#0E7EBE"
                      strokeWidth="2"
                      strokeLinejoin="round"
                    />
                    <ellipse
                      id="Oval"
                      stroke="#0E7EBE"
                      strokeWidth="2"
                      fill="#FFFFFF"
                      cx="9.28611867"
                      cy="15.5384979"
                      rx="3.12716385"
                      ry="3.14359943"
                    />
                    <line
                      x1="54.1690256"
                      y1="12.4307983"
                      x2="30.4378334"
                      y2="12.4307983"
                      id="Line-6"
                      stroke="#0E7EBE"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                  </g>
                </g>
              </g>
            </g>
          </svg>
        }
        title="Want to log in without a password?"
        subtitleLine1="We can email you a magic link that logs you in to"
        subtitleLine2="imCORE without a password!"
      >
        <Box
          style={{
            width: '228px',
            display: 'flex',
            flexDirection: 'column',
            alignSelf: 'center',
            alignItems: 'stretch'
          }}
        >
          <Button mb="15px" onClick={() => setMethod(TOKEN)}>
            Log in with a magic link
          </Button>
          <Button isPrimary={false} onClick={() => setMethod(PASSWORD)}>
            Type password
          </Button>
        </Box>
      </FormBox>
    );
  }
}

SelectMethodForm.propTypes = {
  setMethod: PropTypes.func.isRequired
};

export default SelectMethodForm;
