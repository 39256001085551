import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Item, { UnderlineElement, styledHoverActive, styledItem } from './Item';
import Dropdown, { MenuItem } from '@trendmicro/react-dropdown';
import { phoneMax, tabletMin } from 'utils/media';
import { Link, withRouter } from 'react-router-dom';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Arrow } from '../../Arrow';
import theme from 'styles/theme';

const StyledNavigation = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 50px;
  ${phoneMax`
    gap: 40px;
  `};
`;
const StyledMoreButton = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: ${(props) =>
    props.$toggled ? 'Barlow-Bold' : 'Barlow-Regular'};
  position: relative;
  ${styledItem}
  padding: 0 0 0 10px;

  &:hover
    ${UnderlineElement},
    &:focus
    ${UnderlineElement},
    ${(props) => props.$toggled && UnderlineElement} {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
  }
  ${phoneMax`
    font-size: ${(props) => props.theme.fonts.size.navbar_item_mobile};
  `};
`;
const CustomDropdown = styled(Dropdown)`
  button {
    padding: 0;
    margin:0;
    color: ${(props) => props.theme.colors.black} !important;
    &:hover, &:hover > *{
      color: transparent !important;
      ${(props) => styledHoverActive(props)}
      &::after{
        visibility: visible;
        color: ${(props) => props.theme.colors.black} !important;
        margin-left: ${(props) => (props.$toggled ? '-32px' : '-28px')}
      }
      #arrow {
        border-right: 3px solid ${(props) => props.theme.colors.black};
        border-top: 3px solid ${(props) => props.theme.colors.black};      
      }
  
    }
`;
const CustomDropdownMenu = styled(Dropdown.Menu)`
  border: 1px solid ${(props) => props.theme.colors.dim_blue} !important;
  box-shadow: none !important;
  font-size: 15px !important;
  margin-top: 16px !important;
  z-index: 2002;
  min-width: 250px;
  padding: 0;

  &:after {
    bottom: 99%;
    left: 10%;
    content: ' ';
    width: 14px;
    height: 14px;
    position: absolute;
    pointer-events: none;
    border-right: 1px solid ${(props) => props.theme.colors.dim_blue};
    border-top: 1px solid ${(props) => props.theme.colors.dim_blue};
    border-bottom-color: ${(props) => props.theme.colors.light};
    margin-left: -5px;
    transform: rotate(-45deg);
    background-color: ${(props) => props.theme.colors.light};
  }

  ${phoneMax`
    left: -140px !important;

    &:after {
      left: 87%;
    }
  `};

  ${tabletMin`
    left: -160% !important;

    &:after {
      left: 90%;
    }
  `};
`;
const CustomMenuItem = styled(MenuItem)`
  div {
    padding: 15px 15px !important;

    &:hover {
      background-color: ${(props) => props.theme.colors.light} !important;
      border-right: 10px solid ${(props) => props.theme.colors.contrast};
      border-radius: 0;
      padding-right: 0;
      margin-right: 0;
    }

    &:focus {
      background-color: rgba(8, 124, 191, 0.1) !important;
    }
  }

  a,
  div {
    color: #505050 !important;
  }

  a.active {
    color: ${({ theme }) => theme.colors.button.primary};
  }
`;

const Navigation = (props) => {
  const { push, mainNavigation, moreNavigation } = props;
  const [state, setState] = useState({ toggled: false });

  const clickLink = (path) => {
    setState({ ...state, toggled: false });
    push(path);
  };

  return (
    <StyledNavigation>
      {mainNavigation.map((item) => (
        <Item key={item.id} item={item} />
      ))}
      <CustomDropdown
        onToggle={(toggled) => setState({ ...state, toggled })}
        rootCloseEvent="click"
        open={state.toggled}
        data-text="More"
        $toggled={state.toggled}
      >
        <Dropdown.Toggle btnStyle="link" noCaret>
          <StyledMoreButton $toggled={state.toggled} data-text="More">
            <div>
              More
              <Arrow
                color={theme.colors.black}
                anchor={state.toggled ? '3px' : '2px'}
                size={'12px'}
                verticalPosition={state.toggled ? '-3px' : '4px'}
                rotate={state.toggled ? 'down' : 'up'}
                isNavbar
              />
            </div>
            {state.toggled && <UnderlineElement />}
          </StyledMoreButton>
        </Dropdown.Toggle>
        <CustomDropdownMenu rootCloseEvent="click">
          {moreNavigation.map((item) => (
            <CustomMenuItem key={item.id} onClick={() => clickLink(item.path)}>
              <Link to={item.path}>{item.name}</Link>
            </CustomMenuItem>
          ))}
        </CustomDropdownMenu>
      </CustomDropdown>
    </StyledNavigation>
  );
};

Navigation.propTypes = {
  mainNavigation: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired
    })
  ).isRequired,
  moreNavigation: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired
    })
  ).isRequired
};

Navigation.defaultProps = {
  mainNavigation: [],
  moreNavigation: []
};

const mapDispatchToProps = (dispatch) => ({
  push: (path) => dispatch(push(path))
});

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, withRouter)(Navigation);
