import React from 'react';
import { Box } from '@rebass/grid';
import { TextField } from 'components/Input';
import Button from 'components/Button';
import PropTypes from 'prop-types';
import FormBox from '../../../components/FormBox';

const { REACT_APP_SAML_URL } = process.env;

class EmailForm extends React.Component {
  render() {
    const { isMobile, email, onChange, onSubmit } = this.props;
    const isSAML =
      new RegExp(/@(contractors\.)?roche\.com/i).test(email) ||
      new RegExp(/@gene\.com/i).test(email);
    const isValidEmail = new RegExp(
      /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/i
    ).test(email);

    return (
      <React.Fragment>
        <FormBox
          isMobile={isMobile}
          isSmall={true}
          title="Log in with your email address"
          subtitleLine1="Enter the email associated with your account. "
        >
          <Box flex="0 0 auto" mt="25px" mb="25px">
            <TextField
              type="email"
              name="email"
              placeholder="Email"
              required
              value={email}
              onChange={(e) => onChange(e)}
              style={{
                borderRadius: '5px',
                border: 'solid 1px #e9e9e9',
                backgroundColor: '#f7f7f7',
                fontFamily: 'Barlow-Regular'
              }}
            />
          </Box>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            {isSAML ? (
              <Button
                onClick={() => (window.location = REACT_APP_SAML_URL)}
                style={{ width: '100%' }}
              >
                Login with Roche account
              </Button>
            ) : (
              <Button
                style={{
                  width: '168px'
                }}
                disabled={!isValidEmail}
                onClick={(e) => onSubmit(e)}
              >
                Continue
              </Button>
            )}
          </Box>
        </FormBox>
      </React.Fragment>
    );
  }
}

EmailForm.propTypes = {
  email: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default EmailForm;
