import { handleActions } from 'redux-actions';
import { FETCH_CONTACT_SUCCESS, FETCH_CONTACT_FAILURE } from './constants.js';

export const initialState = null;

const transformUser = (data) => ({
  id: data._id,
  name: data.name,
  first_name: data.first_name,
  email: data.email,
  middle_name: data.middle_name,
  last_name: data.last_name,
  location: data.location,
  pictureUrl_x3: data.picture_x3,
  properties: data.properties
});
// eslint-disable-next-line no-unused-vars
const handleSuccess = (state, action) => {
  return transformUser(action.payload.data);
};
// eslint-disable-next-line no-unused-vars
const handleFailure = (state, action) => {
  return initialState;
};

const singleContactReducer = handleActions(
  {
    [FETCH_CONTACT_SUCCESS]: handleSuccess,
    [FETCH_CONTACT_FAILURE]: handleFailure
  },
  initialState
);

export default singleContactReducer;
