import styled from 'styled-components';
import { space } from 'styled-system';

export const Title = styled.h2`
  text-transform: uppercase;
  margin-bottom: 20px;
  font-family: 'Barlow-SemiBold';
  font-size: '20px';
  color: '#a2a2a2';
`;

export const SubTitle = styled.h2`
  margin: 20px 0 13px 10px;
  font-family: 'Barlow-Bold';
  font-size: 18px;
  color: #a2a2a2;
  opacity: 0.5;
`;

// prettier-ignore
export const H1 = styled.h1`
  ${space}
  font-family: 'Barlow-Bold';
  font-size: 40px;
  color: ${props =>
    props.dark ? props.theme.colors.dark : props.theme.colors.light};
`

// prettier-ignore
export const H2 = styled.h2`
  ${space}
  font-family: 'Barlow-Bold';
  font-size: 25px;
  color: #7a7a7a;
`

// prettier-ignore
export const H3 = styled.h3`
  ${space}
  font-size: 20px;
  font-family: 'Barlow-Bold';
  color: #4f4f4f;
`
