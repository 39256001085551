import { createSelector as createOrmSelector } from 'redux-orm';

import orm, { selector as entitiesSelector } from 'models';
import createDynamicOrmSelector from 'utils/createDynamicOrmSelector';

export const routeSelector = (state) => state.router;

export const userByIdSelector = createDynamicOrmSelector(
  orm,
  entitiesSelector,
  (session, userId) => session.User.withId(userId)
);

export const postByIdSelector = createDynamicOrmSelector(
  orm,
  entitiesSelector,
  (session, postId) => session.Post.withId(postId)
);

export const postsByFeedIdSelector = createDynamicOrmSelector(
  orm,
  entitiesSelector,
  (session, feedId) => session.Feed.withId(feedId).posts.toModelArray()
);

export const likesByUsersByPostIdSelector = createDynamicOrmSelector(
  orm,
  entitiesSelector,
  (session, postId) =>
    session.Post.idExists(postId) &&
    session.Post.withId(postId).likesByUsers.toModelArray()
);

export const feedsSelector = createOrmSelector(
  orm,
  entitiesSelector,
  (session) => session.Feed.all().toModelArray()
);

export const feedByIdSelector = createDynamicOrmSelector(
  orm,
  entitiesSelector,
  (session, feedId) => session.Feed.withId(feedId)
);
