import PropTypes from 'prop-types';

const LoadingProps = PropTypes.shape({
  pending: PropTypes.bool.isRequired,
  fulfilled: PropTypes.bool.isRequired,
  rejected: PropTypes.bool.isRequired,
  reject_reason: PropTypes.any
});

export default LoadingProps;
