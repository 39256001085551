import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { UserProps } from 'models/User';
import Img from 'components/Img';

const StyledItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 0 5px 12px 5px;
  border-bottom: solid 1px #f2f2f2;
  text-decoration: none;

  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  margin-left: 10px;
`;

const Name = styled.span`
  line-height: 1.1;
  font-size: 17px;
  font-family: Barlow-Bold;
  color: #4f4f4f;
`;

const Department = styled.span`
  font-size: 17px;
  color: #7a7a7a;
  opacity: 0.7;
`;

const Item = ({ user, basePath }) => (
  <StyledItem>
    <Img
      src={user.pictureUrl_x3}
      object={user}
      objectImageKey="pictureUrl"
      rounded
    />
    <StyledLink to={`${basePath}/${user.id}`}>
      <Name>{user.name}</Name>
      <br />
      <Department>{user.department}</Department>
    </StyledLink>
  </StyledItem>
);

Item.propTypes = {
  user: UserProps,
  basePath: PropTypes.string.isRequired
};

export default Item;
