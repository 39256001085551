import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex } from '@rebass/grid';
import styled from 'styled-components';

import Logo from './Logo';
import { H2, H3 } from './Title';

// prettier-ignore
const LargeStyledBox = styled.article`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: space-between;
  padding-top: 100px;
  padding-bottom: 100px;
  padding-left: 120px;
  padding-right: 120px;
  box-sizing: border-box;
  border-radius: 5px;
  box-shadow: 0 6px 14px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  width: 662px;
  height: 610px;
`

const SmallStyledBox = styled.article`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: flex-start;
  padding-top: 100px;
  padding-bottom: 55px;
  padding-left: 50px;
  padding-right: 50px;
  box-sizing: border-box;
  border-radius: 5px;
  box-shadow: 0 6px 14px 0 rgba(0, 0, 0, 0.05);
  background-color: #fbfbfb;
  width: 485px;
  height: 410px;
`;

const MobileBox = styled.article`
  min-height: 428px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 0;
  padding-top: 70px;
`;

const TitleStyle = {
  width: '100%',
  height: '18px',
  fontFamily: 'Barlow-SemiBold',
  fontSize: '18px',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: '1',
  letterSpacing: 'normal',
  textAlign: 'center',
  alignSelf: 'center',
  color: '#505050'
};

const SubtitleStyle = {
  width: '100%',
  height: '18px',
  marginBottom: '4px',
  fontFamily: 'Barlow-Regular',
  fontSize: '16px',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: '1.13',
  letterSpacing: 'normal',
  textAlign: 'center',
  color: '#505050',
  alignSelf: 'center'
};

const ConditionalWrapper = ({ isMobile, isSmall, children }) =>
  isMobile ? (
    <MobileBox> {children} </MobileBox>
  ) : isSmall ? (
    <SmallStyledBox> {children} </SmallStyledBox>
  ) : (
    <LargeStyledBox> {children} </LargeStyledBox>
  );

const FormBox = ({
  children,
  isMobile,
  isSmall,
  title,
  subtitleLine1,
  subtitleLine2,
  subtitleLine3,
  icon,
  subtitleStyle
}) => {
  return (
    <React.Fragment>
      <Flex
        mb="40px"
        style={{ alignItems: 'center', justifyContent: 'flex-start' }}
      >
        <Logo />
      </Flex>
      <ConditionalWrapper isMobile={isMobile} isSmall={isSmall}>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignSelf: 'center',
            alignContent: 'center'
          }}
        >
          {icon && <Box style={{ alignSelf: 'center' }}>{icon}</Box>}
          <H2 mb="8px" style={TitleStyle}>
            {title}
          </H2>
          {subtitleLine1 && (
            <Flex mb="46px" flexDirection={'column'}>
              <H3 style={subtitleStyle ? subtitleStyle : SubtitleStyle}>
                {subtitleLine1}
              </H3>
              {subtitleLine2 && (
                <H3 style={subtitleStyle ? subtitleStyle : SubtitleStyle}>
                  {subtitleLine2}
                </H3>
              )}
              {subtitleLine3 && (
                <H3 style={subtitleStyle ? subtitleStyle : SubtitleStyle}>
                  {subtitleLine3}
                </H3>
              )}
            </Flex>
          )}
        </Box>
        {children}
      </ConditionalWrapper>
    </React.Fragment>
  );
};

FormBox.propTypes = {
  children: PropTypes.node,
  isMobile: PropTypes.bool,
  isSmall: PropTypes.bool,
  title: PropTypes.string
};

export default FormBox;
