import { ORM, createReducer } from 'redux-orm';
import Feed from './Feed';
import Post from './Post';
import User from './User';
import Contact from './Contact';
import File from './File';

const orm = new ORM({
  stateSelector: (state) => state.entities
});
export const selector = orm.stateSelector;
orm.register(Feed, Post, User, Contact, File);

export const reducer = createReducer(orm);

export default orm;
