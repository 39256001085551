import React, { Component } from 'react';
import { setToken } from 'utils/cookieStorage';
import { withRouter } from 'react-router';

export class Callback extends Component {
  componentDidMount() {
    const url = new URL(window.location.href);
    const token = url.searchParams.get('token');
    setToken(token);
    this.props.history.push('/');
  }

  render() {
    return <div>Callback</div>;
  }
}

export default withRouter(Callback);
