import styled from 'styled-components';
import PropTypes from 'prop-types';
import { phoneMax } from 'utils/media';

const ROTATE_UP = '-45deg';
const ROTATE_DOWN = '135deg';
// eslint-disable-next-line no-unused-vars
const ROTATE_LEFT = '-135deg';
// eslint-disable-next-line no-unused-vars
const ROTATE_RIGHT = '45deg';

const StyledArrow = styled.div`
  position: relative;
  top: ${(props) => props.$verticalPosition};
  ${(props) =>
    props.$horizontalPosition &&
    `
      left: ${props.$horizontalPosition};
  `}
  content: '';
  display: inline-block;
  width: ${(props) => props.$size};
  height: ${(props) => props.$size};
  border-right: ${(props) => props.$anchor} solid ${(props) => props.$color};
  border-top: ${(props) => props.$anchor} solid ${(props) => props.$color};
  transform: rotate(${(props) => props.$rotate});
  margin-right: 0.5em;
  margin-left: 0.5em;
  transition: opacity 0.3s ease;
  ${phoneMax`
    width: 10px;
    height: 10px;
    ${(props) =>
      props.$isNavbar &&
      props.$rotate === ROTATE_UP &&
      `
      top: 3px;
    `}
  `};
`;

export const Arrow = (props) => {
  let rotate;
  switch (props.rotate) {
    case 'up':
      rotate = ROTATE_UP;
      break;
    case 'down':
      rotate = ROTATE_DOWN;
      break;
    case 'left':
      rotate = ROTATE_LEFT;
      break;
    case 'right':
      rotate = ROTATE_RIGHT;
      break;
    default:
      break;
  }

  return (
    <StyledArrow
      id="arrow"
      $color={props.color}
      $anchor={props.anchor}
      $size={props.size}
      $verticalPosition={props.verticalPosition}
      $horizontalPosition={props.horizontalPosition}
      $isNavbar={props.isNavbar}
      $rotate={rotate}
    />
  );
};

Arrow.propTypes = {
  color: PropTypes.string.isRequired,
  anchor: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  verticalPosition: PropTypes.string.isRequired,
  horizontalPosition: PropTypes.string,
  isNavbar: PropTypes.bool
};

Arrow.defaultProps = {
  isNavbar: false
};
