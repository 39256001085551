import React from 'react';
import { Box, Flex } from '@rebass/grid';
import styled from 'styled-components';

const FileListStyle = styled.div`
  .file {
    margin: 5px 0 30px;
  }

  .file:last-child {
    margin-bottom: 5px;
  }
`;

const FilesList = ({ files }) => (
  <FileListStyle>
    {files.map((file) => (
      <Flex key={file.filename} alignItems="center" className="file">
        <svg width={18} height={18} style={{ marginRight: 10 }}>
          <path
            fill="#7A7A7A"
            d="M1.448 10.058L11.118.97c1.376-1.293 3.624-1.293 5 0a3.191 3.191 0 0 1 0 4.701L6.45 14.759c-.822.773-2.178.773-3 0a1.92 1.92 0 0 1 0-2.82l9.335-8.775a.738.738 0 0 1 .687-.18c.247.061.44.243.505.474a.639.639 0 0 1-.191.646l-9.336 8.774a.623.623 0 0 0 0 .94.722.722 0 0 0 1 0l9.67-9.087c.84-.79.84-2.031 0-2.82-.84-.79-2.162-.79-3.001 0l-9.67 9.087c-1.394 1.31-1.394 3.39 0 4.7a3.673 3.673 0 0 0 5.002 0l9.335-8.774a.738.738 0 0 1 .687-.18c.247.062.44.243.505.475a.639.639 0 0 1-.191.646L8.45 16.639c-1.93 1.815-5.071 1.815-7.002 0-1.93-1.815-1.93-4.766 0-6.58z"
          />
        </svg>
        <Box flex="1 1 auto">
          <a href={file.uri} target="_blank" rel="noopener noreferrer">
            {file.label}
          </a>
        </Box>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="6"
          height="14"
          viewBox="0 0 6 14"
        >
          <polyline
            fill="none"
            stroke="#D8D8D8"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            points="451 38 455 44 451 50"
            transform="translate(-450 -37)"
          />
        </svg>
      </Flex>
    ))}
  </FileListStyle>
);

export default FilesList;
