import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import React from 'react';
import { Img as ReactImage } from 'react-image';

const defaultSize = '45px';

const ImagePlaceholder = () => (
  <svg
    height="26"
    viewBox="0 0 26 26"
    width="26"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <rect id="a" height="26" rx="5" width="26" />
      <mask id="b" fill="#fff">
        <use fillRule="evenodd" xlinkHref="#a" />
      </mask>
    </defs>
    <g fill="none" fillRule="evenodd">
      <use fill="#d8d8d8" xlinkHref="#a" />
      <path
        d="m-1.5 21 9.5-8 6 5 5-3 8 3v8.5h-28.5z"
        stroke="#f1f1f1"
        strokeWidth="1.5"
        fill="#cbcbcb"
        strokeLinecap="round"
        strokeLinejoin="round"
        mask="url(#b)"
      />
      <circle cx="17.5" cy="8.5" fill="#f1f1f1" r="2.5" />
    </g>
  </svg>
);

const FakeImage = styled.div`
  width: ${({ width }) => (width ? width : defaultSize)};
  height: ${({ height }) => (height ? height : defaultSize)};
  ${({ background }) => css`
    background: ${background ? background : '#f1f1f1'};
  `};
  ${({ rounded }) =>
    rounded &&
    css`
      border-radius: 50%;
    `};
  display: flex;
  align-items: center;
  justify-content: center;
`;

// eslint-disable-next-line no-unused-vars
const Image = styled(({ rounded, ...props }) => <ReactImage {...props} />)`
  width: ${({ width }) => (width ? width : defaultSize)};
  height: ${({ height }) => (height ? height : defaultSize)};
  ${({ background }) => css`
    background: ${background ? background : '#f1f1f1'};
  `};
  ${({ rounded }) =>
    rounded &&
    css`
      border-radius: 50%;
    `};

  object-fit: cover;
`;
const Img = ({ src, ...props }) =>
  src ? (
    <Image
      src={
        src ||
        'data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII='
      }
      {...props}
    />
  ) : (
    <FakeImage {...props}>
      <ImagePlaceholder />
    </FakeImage>
  );

Img.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  object: PropTypes.object,
  objectImageKey: PropTypes.string,
  background: PropTypes.string,
  src: PropTypes.string
};

export default Img;
