import authenticationReducer from 'modules/authentication/reducer';
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as entitiesReducer } from 'models';
import loadingReducers from 'modules/loading/reducer';
import profileReducer from 'modules/profile/reducer';
import searchReducer from 'modules/search/reducer';
import singleContactReducer from 'modules/single_contact/reducer';

export const createReducer = (history, injectedReducers) =>
  combineReducers({
    ...injectedReducers,
    router: connectRouter(history),
    loading: loadingReducers,
    profile: profileReducer,
    entities: entitiesReducer,
    authentication: authenticationReducer,
    search: searchReducer,
    single_contact: singleContactReducer
  });

export default createReducer;
