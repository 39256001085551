import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import ListItem from 'components/ListItem';
import { Title } from 'components/PostListItem';
import Img from 'components/Img';
import ColumnWrapper from 'components/Wrappers/ColumnWrapper';
import { PostProps } from 'models/Post';
import styled from 'styled-components';
import theme from 'styles/theme';

const Subtitle = styled.h2`
  font-family: 'Barlow-Medium';
  font-size: ${(props) => props.theme.fonts.size.directory_post_subtitle};
  line-height: ${(props) =>
    props.theme.fonts.line_height.directory_post_subtitle};
  margin-top: 4px;
`;
const ContentContainer = styled(Link)`
  &,
  &:hover {
    text-decoration: none;
  }
`;

const DirectoryFeedItem = ({
  post,
  url,
  searchTerm,
  largeImage = false,
  feedType
}) => {
  const cardUrl = `${url}/${post.id}?search=${searchTerm || ''}`;
  const image = largeImage ? (
    <Img
      src={post.content.image_tablet_x3}
      width="160px"
      height="90px"
      opacity="0.99"
      border-radius="5px"
      padding="-15px"
    />
  ) : (
    <Img src={post.content.icon_url} width="52px" height="52px" rounded />
  );

  return (
    <ListItem url={cardUrl} minHeight="38px" feedType={feedType}>
      <Link to={cardUrl} key={post.id} style={{ gridArea: 'image' }}>
        {image}
      </Link>
      <ColumnWrapper style={{ gridArea: 'content' }}>
        <ContentContainer to={cardUrl} key={post.id}>
          <Title
            title={post.title}
            size={theme.fonts.size.directory_post_title}
            lineHeight={theme.fonts.line_height.directory_post_title}
          />
          <Subtitle>{post.content.subtitle}</Subtitle>
        </ContentContainer>
      </ColumnWrapper>
    </ListItem>
  );
};

DirectoryFeedItem.propTypes = {
  post: PostProps,
  url: PropTypes.string.isRequired,
  largeImage: PropTypes.bool,
  feedType: PropTypes.string
};

export default DirectoryFeedItem;
