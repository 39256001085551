import React from 'react';
import PropTypes from 'prop-types';
import Img from 'components/Img';
import { Box } from '@rebass/grid';
import styled from 'styled-components';
import ListItem from 'components/ListItem';
import VisibilitySensor from 'react-visibility-sensor';
import SingleLocation from './Profile/location/SingleLocation';

const Title = styled.h1`
  font-family: 'Barlow-Bold';
  color: ${(props) => props.theme.colors.black};
  font-size: ${(props) => props.theme.fonts.size.user_feed_card_title};
  line-height: ${(props) => props.theme.fonts.line_height.user_feed_card_title};
  margin-bottom: 4px;
`;

const Subtitle = styled.h2`
  font-family: 'Barlow-Medium';
  color: ${(props) => props.theme.colors.gray_1};
  font-size: ${(props) => props.theme.fonts.size.user_feed_card_subtitle};
  line-height: ${(props) =>
    props.theme.fonts.line_height.user_feed_card_subtitle};
`;

class ListCard extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    imageUrl: PropTypes.string,
    url: PropTypes.string.isRequired,
    feedType: PropTypes.string
  };

  render() {
    const { imageUrl, url, title, subtitle, location, feedType } = this.props;

    return (
      <ListItem url={url} minHeight="39px" feedType={feedType}>
        <Box style={{ gridArea: 'image' }}>
          <VisibilitySensor>
            <Img src={imageUrl} rounded width="50px" height="50px" />
          </VisibilitySensor>
        </Box>
        <Box style={{ gridArea: 'content' }}>
          <Title>{title}</Title>
          <Subtitle>{subtitle}</Subtitle>
          {location ? <SingleLocation pin smaller text={location} /> : ''}
        </Box>
      </ListItem>
    );
  }
}

export default ListCard;
