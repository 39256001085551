import { RSAA } from 'redux-api-middleware';
import {
  TOKEN_SIGN_IN_REQUEST,
  TOKEN_SIGN_IN_SUCCESS,
  TOKEN_SIGN_IN_FAILURE,
  TOKEN_REQUEST_INITIATED,
  TOKEN_REQUEST_COMPLETE,
  SIGN_IN_REQUEST,
  SIGN_IN_SUCCESS,
  SIGN_IN_FAILURE,
  SIGN_OUT,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  NEW_PASSWORD_REQUEST,
  NEW_PASSWORD_SUCCESS,
  NEW_PASSWORD_FAILURE
} from './constants';
import endpointUrl from 'utils/url';
import { getHeadersWithToken } from 'utils/headers';

export const signInPassword = (email, password) => ({
  [RSAA]: {
    endpoint: endpointUrl('/api/v1/sign_in'),
    method: 'POST',
    types: [SIGN_IN_REQUEST, SIGN_IN_SUCCESS, SIGN_IN_FAILURE],
    headers: getHeadersWithToken(),
    body: JSON.stringify({ email, password })
  }
});

export const requestToken = (email) => ({
  [RSAA]: {
    endpoint: endpointUrl('/api/v1/sign_in/by_email'),
    method: 'POST',
    types: [
      TOKEN_REQUEST_INITIATED,
      TOKEN_REQUEST_COMPLETE,
      TOKEN_REQUEST_COMPLETE
    ],
    headers: getHeadersWithToken(),
    body: JSON.stringify({ email })
  }
});

export const signInToken = (token) => ({
  [RSAA]: {
    endpoint: endpointUrl('/api/v1/sign_in/confirm_email'),
    method: 'POST',
    types: [
      TOKEN_SIGN_IN_REQUEST,
      TOKEN_SIGN_IN_SUCCESS,
      TOKEN_SIGN_IN_FAILURE
    ],
    headers: getHeadersWithToken(),
    body: JSON.stringify({ token })
  }
});

export const signOut = () => ({
  type: SIGN_OUT
});

export const resetPassword = (email) => ({
  [RSAA]: {
    endpoint: endpointUrl('/api/v1/password/reset'),
    method: 'POST',
    types: [
      RESET_PASSWORD_REQUEST,
      RESET_PASSWORD_SUCCESS,
      RESET_PASSWORD_FAILURE
    ],
    headers: getHeadersWithToken(),
    body: JSON.stringify({ email })
  }
});

export const submitNewPassword = (
  id,
  token,
  new_password,
  new_password_verification
) => ({
  [RSAA]: {
    endpoint: endpointUrl('/api/v1/password/confirm'),
    method: 'POST',
    types: [NEW_PASSWORD_REQUEST, NEW_PASSWORD_SUCCESS, NEW_PASSWORD_FAILURE],
    headers: getHeadersWithToken(),
    body: JSON.stringify({ id, token, new_password, new_password_verification })
  }
});
