import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { UserProps } from 'models/User';
import Img from 'components/Img';

// prettier-ignore
const StyledImg = styled(Img)`
  display: block;
  width: 22px;
  height: 22px;
`

const Small = ({ user, basePath }) => (
  <Link to={`${basePath}/${user.id}`}>
    <StyledImg
      src={user.pictureUrl_x3}
      object={user}
      objectImageKey={'pictureUrl'}
      rounded
    />
  </Link>
);

Small.propTypes = {
  user: UserProps.isRequired,
  basePath: PropTypes.string.isRequired
};

export default Small;
