import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// prettier-ignore
const StyledPrimaryScreen = styled.main`
  grid-area: primary;
`

const PrimaryScreen = ({ children }) => (
  <StyledPrimaryScreen>{children}</StyledPrimaryScreen>
);

PrimaryScreen.propTypes = {
  children: PropTypes.node.isRequired
};

export default PrimaryScreen;
