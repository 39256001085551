import React from 'react';

const HeartSVG = (props) => (
  <svg width={26} height={22} {...props}>
    <path
      d="M2.854 2.87C5.434.333 9.822.41 12.452 2.9l.548.528.548-.518c2.63-2.499 7.018-2.577 9.598-.04 2.507 2.466 2.467 6.378-.09 8.798l-.67.634-9.051 8.566a.489.489 0 0 1-.336.132.489.489 0 0 1-.337-.132l-9.718-9.2C.387 9.248.347 5.336 2.854 2.87z"
      fillRule="nonzero"
      stroke="#087CBF"
      strokeWidth={1.5}
      fill="none"
    />
  </svg>
);

export default HeartSVG;
