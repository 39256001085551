import React from 'react';
import { Switch, Route } from 'react-router-dom';

const SecondaryRoute = ({
  renderRoute,
  url,
  emptyComponent: EmptyComponent
}) => (
  <Switch>
    <Route key={url} path={url} render={({ match }) => renderRoute(match)} />
    {EmptyComponent && <Route render={() => <EmptyComponent />} />}
  </Switch>
);

export default SecondaryRoute;
