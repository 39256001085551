import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { loadingSelector } from 'modules/loading/selectors';
import { connect } from 'react-redux';
import LoadingProps from '../../modules/loading/props';

class WaitingScreen extends PureComponent {
  static propTypes = {
    loading: LoadingProps,
    sizeMultiplier: PropTypes.number
  };

  render() {
    const { loading } = this.props;
    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column'
        }}
      >
        {loading.rejected ? (
          <div style={{ textAlign: 'center' }}>
            Site failed to load data.
            <br />
            <br />
            <a href={window.location.pathname}>Try to reload the page</a>
          </div>
        ) : (
          <div style={{ color: '#4f4f4f', margin: 15, fontWeight: 'bold' }}>
            Loading ...
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: loadingSelector('NAVIGATION_ITEMS')(state)
});

const withConnect = connect(mapStateToProps, null);

export default withConnect(WaitingScreen);
