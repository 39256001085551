import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { postByIdSelector } from '../../selectors';
import Page from 'components/Page';
import PropertiesCard from 'components/PropertiesCard';
import BoxWrapper from '../../components/Wrappers/BoxWrapper';
import FilesList from '../../components/FilesList';
import styled from 'styled-components';
import { FeedProps } from 'models/Feed';

const SubPartTitle = styled.h2`
  margin: 15px 0 5px 15px;
  font-size: 14px;
  color: #a1a1a1;
`;

const DirectoryPage = ({ post, title, feed }) => {
  if (!post) {
    return null;
  }

  const files = post && post.files.toModelArray();

  return (
    <Page title={title} secondaryPage>
      <PropertiesCard
        title={post.title}
        subtitle={post.content.subtitle}
        imageUrl={post.content.image_tablet_x3}
        properties={post.content.properties}
        body={post.content.body}
        feed={feed}
      />
      {files && files.length > 0 && (
        <React.Fragment>
          <SubPartTitle>Attachments</SubPartTitle>
          <BoxWrapper minHeight="15px">
            <FilesList files={files} />
          </BoxWrapper>
        </React.Fragment>
      )}
    </Page>
  );
};

DirectoryPage.propTypes = {
  post: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    image_tablet_x3: PropTypes.string,
    properties: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        value: PropTypes.string
      })
    )
  }),
  title: PropTypes.string.isRequired,
  feed: FeedProps
};

const mapStateToProps = (state, ownProps) => ({
  post: postByIdSelector(state)(ownProps.postId)
});

const withConnect = connect(mapStateToProps);

export default withConnect(DirectoryPage);
