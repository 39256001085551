import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';

import withSizes from 'utils/sizes';

import List from 'components/List';
import SplitScreen from 'components/SplitScreen/index';
import Page from 'components/Page';
import { FeedProps } from 'models/Feed';
import LoadingProps from 'modules/loading/props';
import { secondaryRouteForFeed } from 'routes/FeedRouter';
import SplitLayout from '../SplitLayout';

const FeedListSplitScreen = (props) => {
  const {
    feed,
    resources,
    feedLoading,
    renderListItem,
    isMobile,
    emptyPageComponent: EmptyPageComponent
  } = props;

  const listPage = () => (
    <Page title={feed.name} {...props}>
      <List
        feed={feed}
        resources={resources}
        loading={feedLoading}
        renderListItem={renderListItem}
        {...props}
      />
    </Page>
  );

  const secondaryRoute = secondaryRouteForFeed(feed);

  return (
    <SplitLayout>
      {isMobile ? (
        listPage()
      ) : (
        <SplitScreen
          styled={{ gridArea: 'children' }}
          feed={feed}
          resources={resources}
          renderPrimaryPage={listPage}
          renderSecondaryPage={() => (
            <Switch>
              {secondaryRoute}
              {EmptyPageComponent && (
                <Route render={() => <EmptyPageComponent />} />
              )}
            </Switch>
          )}
        />
      )}
    </SplitLayout>
  );
};

FeedListSplitScreen.propTypes = {
  feed: FeedProps,
  resources: PropTypes.array.isRequired,
  renderListItem: PropTypes.func.isRequired,
  renderPlaceholderListItem: PropTypes.func.isRequired,
  searchable: PropTypes.bool,
  searchKeys: PropTypes.arrayOf(PropTypes.string),
  isMobile: PropTypes.bool,
  createableUrl: PropTypes.string,
  feedLoading: LoadingProps
};

export default withSizes(FeedListSplitScreen);
