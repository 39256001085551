import PostTitle from './PostTitle';
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';
import formatDistance from 'date-fns/formatDistance';
import Img from 'components/Img';
import { PostProps } from 'models/Post';
import { FeedProps } from 'models/Feed';
import ListItem from './ListNewsItem';
import styled from 'styled-components';
import { Box } from '@rebass/grid';
import Likes from 'components/Likes';

export const Title = PostTitle;
const DateSpan = styled.span`
  color: ${(props) => props.theme.colors.gray_1};
  font-family: 'Barlow-Regular';
  font-size: ${(props) => props.theme.fonts.size.news_post_date};
`;
const TitleContainer = styled(Link)`
  box-sizing: border-box;
  color: ${(props) => props.theme.colors.black};
  font-family: 'Barlow-SemiBold';
  font-size: ${(props) => props.theme.fonts.size.news_item_title};
  line-height: ${(props) => props.theme.fonts.line_height.news_item_title};
  padding: 25px 0 30px;
  &,
  &:hover {
    text-decoration: none;
  }
`;
const NewsItem = ({ post, feed, match, featured, isFirstItem }) => {
  if (!post) return null;
  const postUrl = `${match.url}/${post.id}`;
  const since = formatDistance(post.created_at, new Date());

  return (
    <ListItem url={postUrl} featured={featured} isFirstItem={isFirstItem}>
      <Link to={postUrl} style={{ gridArea: 'image' }}>
        <Img
          src={post.content.image_tablet_x3}
          object={post.content}
          objectImageKey={'image_phone'}
          width="100%"
          height={isFirstItem ? '500px' : '300px'}
        />
      </Link>
      <Link to={postUrl} style={{ gridArea: 'date', textDecoration: 'none' }}>
        <DateSpan>{`${since} ago`}</DateSpan>
      </Link>
      <Box style={{ gridArea: 'likes', position: 'static' }}>
        {post.showCommentsAndLikes && (
          <Likes feed={feed} post={post} isNewsScreen />
        )}
      </Box>
      <TitleContainer to={postUrl} style={{ gridArea: 'title' }}>
        <h1>{post.title}</h1>
      </TitleContainer>
    </ListItem>
  );
};

NewsItem.propTypes = {
  post: PostProps,
  feed: FeedProps,
  match: ReactRouterPropTypes.match,
  featured: PropTypes.bool,
  isFirstItem: PropTypes.bool
};
NewsItem.defaultProps = {
  isFirstItem: false
};

export default withRouter(NewsItem);
