import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import withSizes from 'utils/sizes';
import List from './List';
import NewsScreen from 'components/NewsScreen/index';
import Page from 'components/Page';
import { FeedProps } from 'models/Feed';
import LoadingProps from 'modules/loading/props';
import { secondaryRouteForFeed } from 'routes/FeedRouter';
import SingleLayout from '../SingleLayout';

const FeedListNewsScreen = (props) => {
  const {
    feed,
    resources,
    feedLoading,
    renderListItem,
    isMobile,
    emptyPageComponent: EmptyPageComponent,
    location,
    changeSourceCallback,
    currentSource
  } = props;

  const listPage = () => {
    return (
      <Page title={feed.name} isNewsScreen {...props}>
        <List
          feed={feed}
          resources={resources}
          loading={feedLoading}
          renderListItem={renderListItem}
          {...props}
        />
      </Page>
    );
  };

  const secondaryRoute = secondaryRouteForFeed(feed);

  return (
    <SingleLayout feed={feed}>
      {isMobile ? (
        listPage()
      ) : (
        <NewsScreen
          feed={feed}
          resources={resources}
          location={location}
          renderPrimaryPage={listPage}
          renderSecondaryPage={() => (
            <Switch>
              {secondaryRoute}
              {EmptyPageComponent && (
                <Route render={() => <EmptyPageComponent />} />
              )}
            </Switch>
          )}
          changeSourceCallback={changeSourceCallback}
          currentSource={currentSource}
        />
      )}
    </SingleLayout>
  );
};

FeedListNewsScreen.propTypes = {
  feed: FeedProps,
  resources: PropTypes.array.isRequired,
  renderListItem: PropTypes.func.isRequired,
  renderPlaceholderListItem: PropTypes.func.isRequired,
  searchable: PropTypes.bool,
  searchKeys: PropTypes.arrayOf(PropTypes.string),
  isMobile: PropTypes.bool,
  createableUrl: PropTypes.string,
  feedLoading: LoadingProps,
  changeSourceCallback: PropTypes.func
};

export default withSizes(FeedListNewsScreen);
