import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { phoneMin, phoneMax, tabletMin, desktopMin } from 'utils/media';
import ScrollManager from 'components/ScrollManager';
import PageTop from './PageTop';

export const PageWrapper = styled.div`
  ${(props) =>
    !props.$isNewsScreen &&
    !props.isLoading &&
    `
    ${phoneMin`
      padding: 20px;
    `}
    ${tabletMin`
      padding: 20px;
      height: calc(100% - 40px);
    `}

    ${desktopMin`
      padding: 20px 50px;
      height: calc(100% - 100px);
    `}  
  `}
  ${({ isLoading }) =>
    isLoading &&
    `
    padding: 40px 0 60px;
    height: 40px;
  `}
`;
// prettier-ignore
const PageScroller = styled.div`
  ${props => !props.$isNewsScreen && `
    padding: 30px 0 0 47px;
    height: calc(100vh - 10px);
    overflow-y: overlay;
    &::-webkit-scrollbar {
      display: none;
    }
    ${(!props.$secondaryPage && !props.$isProfileScreen) && `
      border-right: 1px solid ${props.theme.colors.dim_blue};
      padding: 30px 47px 0 0;
    `}

  `}

  ${phoneMax`
    height: calc(100% - 50px)
  `};
`;
const PageHeader = styled.header`
  margin: 10px 0 30px;
  background: ${(props) => props.theme.colors.light};
`;

const Page = ({
  children,
  title,
  disableScrollMemory,
  isNewsScreen,
  secondaryPage,
  isProfileScreen
}) => {
  const pageInner = (connectScrollTarget) => (
    <PageScroller
      ref={connectScrollTarget}
      style={{ postion: 'relative' }}
      $isNewsScreen={isNewsScreen}
      $secondaryPage={secondaryPage}
      $isProfileScreen={isProfileScreen}
    >
      <PageWrapper $isNewsScreen={isNewsScreen}>
        {isProfileScreen && (
          <PageHeader secondaryPage={secondaryPage}>
            <PageTop title={title} />
          </PageHeader>
        )}
        {children}
      </PageWrapper>
    </PageScroller>
  );

  if (disableScrollMemory) {
    return pageInner();
  }

  return (
    <ScrollManager scrollKey={title}>
      {({ connectScrollTarget }) => pageInner(connectScrollTarget)}
    </ScrollManager>
  );
};

Page.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  secondaryPage: PropTypes.bool,
  disableScrollMemory: PropTypes.bool,
  noScroll: PropTypes.bool,
  isNewsScreen: PropTypes.bool,
  searchable: PropTypes.bool,
  searchCallback: PropTypes.func,
  changeSourceCallback: PropTypes.func,
  isProfileScreen: PropTypes.bool
};

Page.defaultProps = {
  isNewsScreen: false,
  isProfileScreen: false
};

function mapStateToProps(state) {
  return { search_status: state.search.search_status };
}

const withConnect = connect(mapStateToProps);

export default compose(withRouter, withConnect)(Page);
