import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { fetchPosts } from './actions';
import { feedByIdSelector } from '../../selectors';

import SecondaryRoute from 'routes/SecondaryRoute';
import { DefaultPlaceholder } from 'components/CardPlaceholder';
import FeedListSplitScreen from 'components/FeedListSplitScreen';
import { Title } from 'components/PostListItem';
import Img from 'components/Img';

import { loadingSelector } from 'modules/loading/selectors';
import LoadingProps from 'modules/loading/props';
import ListItem from 'components/ListItem';
import { Box } from '@rebass/grid';
import { Link } from 'react-router-dom';
import { since } from 'components/Since';

const VideoPage = React.lazy(() => import('pages/VideoPage'));
class VideoFeedScreen extends Component {
  static propTypes = {
    feed: PropTypes.shape({
      id: PropTypes.number.isRequired
    }),
    feedId: PropTypes.number,
    fetchPosts: PropTypes.func,
    match: PropTypes.shape({
      url: PropTypes.string
    }),
    loading: LoadingProps.isRequired
  };

  componentDidMount() {
    const { feedId, fetchPosts } = this.props;
    fetchPosts(feedId);
  }

  render() {
    const { feed, loading, match } = this.props;
    const resources = feed.posts.toModelArray();
    if (resources.length > 0 && resources[0].featured_post_feed) {
      resources[0].featured = true;
    }

    return (
      <FeedListSplitScreen
        feedLoading={loading}
        renderListItem={(post) => {
          const postUrl = `${match.url}/${post.id}`;
          return (
            <Link to={postUrl} key={post.id}>
              <ListItem url={postUrl}>
                <Img
                  src={post.content.image_tablet_x3}
                  object={post.content}
                  objectImageKey={'image_phone'}
                  style={{ gridArea: 'image' }}
                  width="110px"
                  height="110px"
                />
                <Box style={{ gridArea: 'content' }}>
                  <Title title={post.title} subtitle={since(post.created_at)} />
                </Box>
              </ListItem>
            </Link>
          );
        }}
        renderPlaceholderListItem={() => (
          <DefaultPlaceholder type="rect" imageSize="110px" imageType="rect" />
        )}
        feed={feed}
        resources={resources}
        renderSecondaryPage={() => (
          <SecondaryRoute
            url={`${feed.path}/:id`}
            renderRoute={(match) => (
              <VideoPage feedId={feed.id} postId={Number(match.params.id)} />
            )}
          />
        )}
        fetchPaginationCallback={(page) => {
          fetchPosts(feed.id, page);
        }}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  feed: feedByIdSelector(state)(ownProps.feedId),
  loading: loadingSelector('NEWS_FEED_POSTS')(state)
});

const mapDispatchToProps = (dispatch) => ({
  fetchPosts: (feedId, page) => dispatch(fetchPosts(feedId, page))
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withRouter, withConnect)(VideoFeedScreen);
