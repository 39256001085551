import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Arrow } from 'components/Arrow';
import theme from 'styles/theme';

// prettier-ignore
const StyledSecondaryScreen = styled.main`
  grid-area: secondary;
  background-color: ${props => props.theme.colors.background};
`;
const BackButtonContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 0 30px 0;
  width: 100%;
  border-bottom: 1px solid ${(props) => props.theme.colors.dim_blue};
`;
const BackButton = styled.button`
  color: ${(props) => props.theme.colors.contrast};
  font-family: 'Barlow-Regular';
  font-size: ${(props) => props.theme.fonts.size.back_button};
  line-height: ${(props) => props.theme.fonts.line_height.back_button};
  border: 0;
  background-color: ${(props) => props.theme.colors.light};
  cursor: pointer;
  &:hover {
    font-family: 'Barlow-SemiBold';
  }
`;

const SecondaryScreen = ({ children, handleBackButton, feedName }) => (
  <StyledSecondaryScreen>
    <BackButtonContainer>
      <BackButton onClick={(e) => handleBackButton(e)}>
        <Arrow
          color={theme.colors.contrast}
          anchor={'2px'}
          size={'10px'}
          verticalPosition={'0'}
          horizontalPosition={'-0.25rem'}
          rotate={'left'}
        />
        {`Back to ${feedName}`}
      </BackButton>
    </BackButtonContainer>
    {children}
  </StyledSecondaryScreen>
);

SecondaryScreen.propTypes = {
  children: PropTypes.node.isRequired,
  feedName: PropTypes.string
};

export default SecondaryScreen;
