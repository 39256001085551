import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { phoneMin, tabletMin, desktopMin } from 'utils/media';

const Styled = styled.div`
  position: fixed;
  bottom: 0;
  z-index: 9999;

  ${phoneMin`
   right: 10px;
  `}

  ${tabletMin`
    left: calc(50% - 90px);
  `}

  ${desktopMin`
    left: 52.5%;
  `}

  a:hover {
    svg {
      fill: #000;
    }
  }
`;
const CreatePostButton = ({ createableUrl }) => {
  return (
    <Styled>
      <Link to={createableUrl}>
        <svg
          className="svg"
          height="83"
          viewBox="0 0 83 83"
          width="83"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <defs>
            <circle id="a" cx="27.5" cy="27.5" r="27.5" />
            <filter id="b" height="180%" width="180%" x="-40%" y="-36.4%">
              <feOffset
                dx="0"
                dy="2"
                in="SourceAlpha"
                result="shadowOffsetOuter1"
              />
              <feGaussianBlur
                in="shadowOffsetOuter1"
                result="shadowBlurOuter1"
                stdDeviation="7"
              />
              <feColorMatrix
                in="shadowBlurOuter1"
                type="matrix"
                values="0 0 0 0 0   0 0 0 0 0.105882353   0 0 0 0 0.168627451  0 0 0 0.3 0"
              />
            </filter>
          </defs>
          <g fill="none" fillRule="evenodd" transform="translate(14 12)">
            <use fill="#000" filter="url(#b)" xlinkHref="#a" />
            <use fill="#087cbf" fillRule="evenodd" xlinkHref="#a" />
            <g
              stroke="#fff"
              strokeWidth="1.5"
              transform="matrix(.70710678 .70710678 -.70710678 .70710678 34.278175 12.943651)"
            >
              <path d="m.87169798 25.25351 4.42832193 5.902797c.01420274.0189318.0310218.0357508.04995355.0499536.11044595.0828573.26714926.0604924.35000663-.0499536l4.41376091-5.8784016c.0885323-.1430232.136259-.312084.136259-.4856779v-23.17673468c0-.48597266-.36142356-.86549282-.78846154-.86549282h-7.92307692c-.42703798 0-.78846154.37952016-.78846154.86549282v23.17673468c0 .1638225.04250498.3236079.12169798.4612825z" />
              <path d="m10 5h-9" strokeLinecap="round" strokeLinejoin="round" />
            </g>
          </g>
        </svg>
      </Link>
    </Styled>
  );
};

export default CreatePostButton;
