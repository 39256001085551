import React, { useEffect, useRef } from 'react';
import Popup from 'reactjs-popup';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { profileSelector } from 'modules/profile/selectors';
import { UserProps } from 'models/User';
import withSizes from 'utils/sizes';
import { compose } from 'redux';
import { fetchConsentDisclaimer } from './actions';
import styled from 'styled-components';

const StyledPopUp = styled(Popup)`
  &-overlay {
    z-index: 100000 !important;
    backdrop-filter: blur(4px);
  }
  &-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 605px !important;
    height: 668px;
    box-shadow: 0 11px 24px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    font-family: 'Barlow-Regular';
    overflow: hidden;
    b {
      font-family: 'Barlow-SemiBold';
    }
    i {
      font-style: italic;
    }
    header {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 1.25rem 2.188rem;
      margin-bottom: 1.875rem;
      border-bottom: 1px solid rgba(118, 118, 118, 0.3);
      h1 {
        font-size: ${(props) => props.theme.fonts.size.disclaimer_popup_title};
        line-height: ${(props) =>
          props.theme.fonts.line_height.disclaimer_popup_title};
        width: 100%;
      }
      button {
        background-color: ${(props) => props.theme.colors.gray_light};
        color: ${(props) => props.theme.colors.gray_3};
        font-family: 'Barlow-Bold';
        font-size: ${(props) => props.theme.fonts.size.disclaimer_popup_button};
        width: 6.25rem;
        height: 3rem;
        border: 0;
        border-radius: 0;
        cursor: pointer;
        &:hover {
          background-color: ${(props) => props.theme.colors.contrast};
          color: ${(props) => props.theme.colors.light};
        }
      }
    }
    .bg-content {
      padding: 1.25rem 2.188rem 8rem;
      overflow-y: auto;
    }
    .gradient {
      height: 600px;
      width: 100%;
      margin-top: -150px;
      background-image: linear-gradient(transparent, white);
      backface-visibility: hidden;
    }
    footer {
      height: 300px;
      width: 100%;
      background-color: white;
      backface-visibility: hidden;
    }
  }
`;

const Disclaimer = (props) => {
  const { modalIsOpen, closeModal, handleAcceptDisclaimer } = props;
  const myRef = useRef(null);

  useEffect(() => {
    fetchConsentDisclaimer().then((response) => {
      myRef.current.innerHTML = response.body;
    });
  }, []);
  return (
    <StyledPopUp
      open={modalIsOpen}
      onClose={(e) => closeModal(e)}
      modal
      closeOnDocumentClick={false}
      closeOnEscape={false}
      lockScroll
    >
      <header>
        <h1>PRIVACY NOTICE</h1>
        <button onClick={(e) => handleAcceptDisclaimer(e)}>I accept</button>
      </header>
      <div className="bg-content" ref={myRef} />
      <div className="gradient" />
      <footer />
    </StyledPopUp>
  );
};

Disclaimer.propTypes = {
  user: UserProps
};

const mapStateToProps = createStructuredSelector({
  user: profileSelector
});

const withConnect = connect(mapStateToProps, null);

export default compose(withSizes, withConnect)(Disclaimer);
