import { handleActions } from 'redux-actions';
import {
  START_LOADING,
  END_LOADING_OK,
  END_LOADING_ERROR,
  CLEAR_LOADING
} from './constants';

export const defaultLoadingState = {
  pending: false,
  fulfilled: false,
  rejected: false,
  reject_reason: null
};

const authenticationReducer = handleActions(
  {
    [START_LOADING]: (state, action) => ({
      ...state,
      [action.name]: {
        pending: true,
        fulfilled: false,
        rejected: false,
        reject_reason: null
      }
    }),
    [END_LOADING_OK]: (state, action) => ({
      ...state,
      [action.name]: {
        pending: false,
        fulfilled: true,
        rejected: false,
        reject_reason: null
      }
    }),
    [END_LOADING_ERROR]: (state, action) => ({
      ...state,
      [action.name]: {
        pending: false,
        fulfilled: false,
        rejected: true,
        reject_reason: action.payload
      }
    }),
    [CLEAR_LOADING]: (state, action) => ({
      ...state,
      [action.name]: null
    })
  },
  {}
);

export default authenticationReducer;
