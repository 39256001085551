import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { fetchPosts } from './actions';
import { feedByIdSelector } from '../../selectors';
import { NewsCardPlaceholder } from 'components/CardPlaceholder';
import FeedListNewsScreen from 'components/FeedListNewsScreen';
import { loadingSelector } from 'modules/loading/selectors';
import LoadingProps from 'modules/loading/props';
import ReactRouterPropTypes from 'react-router-prop-types';
import { PostEmptyPage } from 'pages/EmptyPage';
import { searchFeed, clearSearch } from '../../modules/search/actions';
// import NewsItem from 'components/NewsItem';
import PostListItem from 'components/FeedListNewsScreen/NewsItem';
import { logUserSearchedFeed } from 'utils/piwikpro';

const NewsFeedScreen = (props) => {
  const { feedId, fetchPosts, loading, feed, searchFeed, location, match } =
    props;
  const [state, setState] = useState({ source: '' });

  useEffect(() => {
    fetchPosts(feedId);
  }, []);

  const getResultsFromSource = (source) => {
    setState({ ...state, source: source });
  };

  const resources =
    state.source === 'search'
      ? feed.search_posts.toModelArray()
      : feed.posts.toModelArray();
  resources.sort((a, b) => a.order - b.order);
  const showFeatured = resources.length > 0 && resources[0].featured_post_feed;

  return (
    <FeedListNewsScreen
      feedLoading={loading}
      searchable
      searchCallback={(term, feedId) => {
        // Send event to Analytics
        logUserSearchedFeed(term, feed.name);

        getResultsFromSource('search');
        searchFeed(feedId, term);
      }}
      currentSource={state.source}
      changeSourceCallback={getResultsFromSource}
      renderListItem={(post) => (
        <PostListItem
          post={post}
          feed={feed}
          featured={showFeatured && resources[0].id === post.id}
        />
      )}
      renderPlaceholderListItem={(isFirstItem) => (
        <NewsCardPlaceholder
          imageType="rect"
          imageSize="80px"
          isFirstItem={isFirstItem}
        />
      )}
      feed={feed}
      resources={resources}
      emptyPageComponent={PostEmptyPage}
      fetchPaginationCallback={(page) => fetchPosts(feed.id, page)}
      location={location}
      match={match}
      showFeatured={showFeatured}
    />
  );
};

NewsFeedScreen.propTypes = {
  feed: PropTypes.shape({
    id: PropTypes.number.isRequired
  }),
  feedId: PropTypes.number.isRequired,
  fetchPosts: PropTypes.func.isRequired,
  searchFeed: PropTypes.func.isRequired,
  match: ReactRouterPropTypes.match.isRequired,
  loading: LoadingProps.isRequired
};

const mapStateToProps = (state, ownProps) => ({
  feed: feedByIdSelector(state)(ownProps.feedId),
  loading: loadingSelector('NEWS_FEED_POSTS')(state)
});

const mapDispatchToProps = (dispatch) => ({
  fetchPosts: (feedId, page) => dispatch(fetchPosts(feedId, page)),
  searchFeed: (feedId, query) =>
    dispatch(searchFeed(feedId, query)).then(() => {
      dispatch(clearSearch());
    })
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withRouter, withConnect)(NewsFeedScreen);
