import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, matchPath } from 'react-router-dom';
import BoxWrapper from 'components/Wrappers/BoxWrapper';
import ReactRouterPropTypes from 'react-router-prop-types';
import styled from 'styled-components';
import { DIRECTORY_FEED, USER_FEED } from 'routes/FeedRouter';

// prettier-ignore
const InnerWrapper = styled.div`
  display: grid;
  flex: 1 0;
  column-gap: 15px;
  ${({ feedType }) => [USER_FEED, DIRECTORY_FEED].includes(feedType) && `
    min-height: 39px;
`}
  ${({ feedType }) => feedType === USER_FEED && `
    align-items: center;
  `}
  grid:
    [row1-start] "image content ........." 1fr [row1-end]
    [row2-start] "image likes copy" [row2-end]
    / auto 1fr auto;
  ${props => props.featured && `
    grid:
      [row1-start] "image image" 1fr [row1-end]
      [row2-start] "content ......... ........." auto [row2-end]
      [row3-start] "likes  copy" auto [row3-end]
      / 1fr auto;
  `}
`

const isActive = (location, url) => {
  const urlPath = url && url.split('?')[0];
  return Boolean(matchPath(location.pathname, { path: urlPath }));
};

const ListItem = ({
  children,
  location,
  url,
  noPadding,
  featured,
  minHeight,
  noGrid,
  feedType
}) => {
  return (
    <BoxWrapper
      minheight={minHeight}
      $active={isActive(location, url)}
      $flex
      noPadding={noPadding}
      feedType={feedType}
    >
      {noGrid ? (
        children
      ) : (
        <InnerWrapper featured={featured} feedType={feedType}>
          {children}
        </InnerWrapper>
      )}
    </BoxWrapper>
  );
};

ListItem.propTypes = {
  children: PropTypes.node.isRequired,
  location: ReactRouterPropTypes.location.isRequired,
  url: PropTypes.string,
  noPadding: PropTypes.bool,
  featured: PropTypes.bool,
  minHeight: PropTypes.string,
  noGrid: PropTypes.bool,
  feedType: PropTypes.string
};
ListItem.defaultProps = {
  feedType: null
};

export default withRouter(ListItem);
