export const TOKEN_SIGN_IN_REQUEST = 'TOKEN_SIGN_IN_REQUEST';
export const TOKEN_SIGN_IN_SUCCESS = 'TOKEN_SIGN_IN_SUCCESS';
export const TOKEN_SIGN_IN_FAILURE = 'TOKEN_SIGN_IN_FAILURE';
export const TOKEN_REQUEST_INITIATED = 'TOKEN_REQUEST_INITIATED';
export const TOKEN_REQUEST_COMPLETE = 'TOKEN_REQUEST_COMPLETE';
export const SIGN_IN_REQUEST = 'SIGN_IN_REQUEST';
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const SIGN_IN_FAILURE = 'SIGN_IN_FAILURE';
export const SIGN_OUT = 'SIGN_OUT';
export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';
export const NEW_PASSWORD_REQUEST = 'NEW_PASSWORD_REQUEST';
export const NEW_PASSWORD_SUCCESS = 'NEW_PASSWORD_SUCCESS';
export const NEW_PASSWORD_FAILURE = 'NEW_PASSWORD_FAILURE';
