import { attr, Model } from 'redux-orm';

class Contact extends Model {
  static modelName = 'Contact';

  static fields = {
    name: attr(),
    first_name: attr(),
    middle_name: attr(),
    last_name: attr(),
    email: attr(),
    location: attr(),
    department: attr(),
    properties: attr(),
    pictureUrl_x3: attr(),
    feed_id: attr()
  };

  static parse(data, feed_id) {
    return this.upsert({
      id: data._id,
      name: data.name,
      first_name: data.first_name,
      middle_name: data.middle_name,
      last_name: data.last_name,
      email: data.email,
      location: data.location,
      department: data.department,
      properties: data.properties,
      pictureUrl_x3: data.picture_x3,
      feed_id
    });
  }
}

export default Contact;
